@import '../../abstracts/variables';

@mixin level-ticket-selector-item-width($pxItemWidth: 80) {
  $rem-item-width: rem-calc($pxItemWidth);

  .level-item {
    width: $rem-item-width;
  }

  .level__container {
    padding-inline-start: calc(50% - #{$rem-item-width} / 2);
  }
}

fv-ticket-amount-selector {
  display: block;

  text-align: center;
}

.level-ticket-selector {
  &--free-selection-map {
    .level.level--session {
      display: none;
    }
  }
}

.add-ons-wrapper {
  padding-block-start: rem-calc(16);
  margin-block-start: rem-calc(16);

  border-block-start: rem-calc(1) dashed $color-border-main;
  @include media-breakpoint-up(lg) {
    margin-block-end: rem-calc(16);
  }
}

.ticket-amount {
  &__selector {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    max-width: rem-calc(320);
    margin: rem-calc(24) auto;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    background-color: transparent;
    border: initial;
    cursor: pointer;

    fv-fa-icon {
      @include transition;

      display: flex;
      align-items: center;
      justify-content: center;

      width: rem-calc(40);
      height: rem-calc(40);

      color: $ticket-selector-amount-button-color-foreground;
      font: $font-body-base-regular;

      background-color: $ticket-selector-amount-button-color-background;

      border: $ticket-selector-amount-button-border;

      border-radius: $border-radius-lg;
    }

    &:hover {
      fv-fa-icon {
        color: $ticket-selector-amount-button-color-foreground-hover;

        background-color: $ticket-selector-amount-button-color-background-hover;
        border: $ticket-selector-amount-button-border-hover;
      }
    }

    &:disabled {
      fv-fa-icon {
        color: $color-action-text-disable;

        background-color: $color-action-background-disable;
      }
    }
  }

  &__number-text {
    width: rem-calc(128);

    color: $ticket-selector-color-foreground;
    font-size: $font-size-h4;
    text-align: center;
  }
}

.level {
  @include level-ticket-selector-item-width(160);
  position: relative;

  &.level--date {
    @include level-ticket-selector-item-width(80);
  }

  &.level--time {
    @include level-ticket-selector-item-width(100);
  }

  &.level--session {
    @include level-ticket-selector-item-width(160);
  }

  &__container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin: 0 auto;
    padding: rem-calc(16 0);
    overflow-x: auto;
    overflow-y: hidden;

    border-bottom: rem-calc(1) solid $color-border-main;
    scroll-snap-type: x mandatory;

    &::after {
      flex: 0 0 rem-calc(12);

      content: '';
    }
  }
}

.level .level__container,
.level.level--date .level__container,
.level.level--time .level__container,
.level.level--session .level__container {
  &--one {
    justify-content: center;
    padding-inline-start: 0;

    &::after {
      content: none;
    }

    .level-item {
      margin: 0;
    }
  }
}

.level-item {
  position: relative;

  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: rem-calc(36);
  margin: rem-calc(0 12 0 0);
  padding: rem-calc(6 12);
  overflow: hidden;

  color: $gray-900;
  font-size: rem-calc(16);
  text-align: center;

  background-color: $ticket-selector-color-background;
  border: solid rem-calc(1) $color-border-main;
  border-radius: $ticket-selector-item-dimensions-border-radius;
  cursor: pointer;

  user-select: none;
  scroll-snap-align: center;

  @include hover-supported() {
    color: $ticket-selector-item-color-foreground-hover;

    border-color: $color-border-primary-strong !important;
  }

  &--selected {
    color: $ticket-selector-item-color-foreground-selected;

    background-color: $ticket-selector-item-color-background-selected;

    border-color: $ticket-selector-item-color-border-selected;

    @include hover-supported() {
      color: $ticket-selector-item-color-foreground-selected;

      border-color: $ticket-selector-item-color-border-selected;
    }

    .level-item__check {
      display: block;

      color: $ticket-selector-item-check-color-foreground-selected;
    }
  }

  &--disabled {
    color: $color-action-text-disable;

    background: $color-action-background-disable;
    border-color: $color-action-border-disable;

    &.level-item--selected {
      .level-item__check {
        display: none;

        background: $color-action-background-disable;
      }
    }

    @include hover-supported() {
      color: $color-action-text-disable;

      background: $color-action-background-disable;
      border-color: $color-action-border-disable;
    }
  }

  &--loader {
    height: auto;
  }

  &__check {
    position: absolute;
    top: rem-calc(-16);
    right: rem-calc(-16);

    display: none;
    align-items: flex-end;
    width: rem-calc(32);
    height: rem-calc(32);

    font-size: rem-calc(10);
    text-align: center;

    background: $ticket-selector-item-check-color-background-selected;
    transform: rotate(-45deg);

    fv-fa-icon {
      position: absolute;
      bottom: rem-calc(6);
      left: rem-calc(1);

      line-height: rem-calc(10);

      transform: rotate(45deg);
      transform-origin: right;
    }
  }

  &__bar {
    position: absolute;
    bottom: rem-calc(3);

    display: flex;
    gap: rem-calc(2);
    width: rem-calc(24);
    height: rem-calc(2);

    &__chunk {
      width: 100%;

      border-radius: rem-calc(4);

      &--best-price {
        background-color: $color-background-accent-base;
      }
    }
  }
}

/*** MULTI SELECTION **/
.session-multi-selection-level {
  &__item {
    display: block;
    margin: $ticket-selector-multi-item-margin;
    padding: $ticket-selector-multi-item-padding;

    border: $ticket-selector-multi-item-border;
  }

  &__alerts {
    margin: rem-calc(16 0);
  }
}

.session-multi-selection-level-item {
  display: flex;
  align-items: center;

  &__discount-banner {
    width: max-content;
    margin-bottom: rem-calc(12);
    padding: rem-calc(1 4 2);

    color: $ticket-selector-item-discount-banner-color-foreground;
    font: $ticket-selector-item-discount-banner-font;
    line-height: rem-calc(21);

    background-color: $ticket-selector-item-discount-banner-color-background;

    border-radius: $ticket-selector-item-discount-banner-border-radius;
  }

  &__label {
    flex: 1;
    padding-inline-end: rem-calc(16);

    color: $ticket-selector-color-foreground;
    line-height: rem-calc(24);
  }

  &__sold-out {
    padding: rem-calc(2) rem-calc(8);

    color: $color-text-main-contrast;
    font: $font-caption-regular;
    text-transform: uppercase;

    background-color: $ticket-selector-item-tag-sold-out-color-background;
    border-radius: rem-calc(4);
  }

  &__surcharge-per-ticket {
    color: $text-neutral;
    font: $font-body-small-regular;
  }

  &__price {
    &__final {
      color: $ticket-selector-item-price-final-color;
      font-weight: $ticket-selector-item-price-final-font-weight;
      font-size: $ticket-selector-item-price-final-font-size;
      margin-inline-end: rem-calc(4);
    }

    &__strikethrough {
      color: $text-neutral;
      font-weight: $ticket-selector-item-price-strikethrough-font-weight;
      font-size: $ticket-selector-item-price-strikethrough-font-size;
      text-decoration: line-through;
    }
  }

  &__rule-error {
    margin-top: rem-calc(8);

    color: $color-text-danger;
    font: $font-caption-regular;
  }

  &--active {
    .info,
    .separator,
    .separator::before,
    .separator::after,
    .amount {
      border-color: $ticket-selector-active-border-color !important;
    }

    .amount {
      background-color: $ticket-selector-active-amount-color-background;
    }
  }
}

.session-multi-selection-amount-selector {
  display: flex;
  align-items: center;
  min-width: rem-calc(104);

  background-color: $ticket-selector-color-background;
  border-radius: rem-calc(100);

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(44);
    height: rem-calc(44);
    padding: 0;

    background-color: transparent;
    border: initial;
    cursor: pointer;

    fv-fa-icon {
      @include transition;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(32);
      height: rem-calc(32);

      color: $ticket-selector-amount-button-color-foreground;
      font: $font-body-base-regular;

      background-color: $ticket-selector-amount-button-color-background;
      border: $ticket-selector-amount-button-border;
      border-radius: $border-radius-lg;
    }

    &:hover {
      fv-fa-icon {
        color: $ticket-selector-amount-button-color-foreground-hover;

        background-color: $ticket-selector-amount-button-color-background-hover;
        border: $ticket-selector-amount-button-border-hover;
      }
    }

    &:disabled {
      fv-fa-icon {
        color: $color-action-text-disable;

        background-color: $color-action-background-disable;
        border: 0;
      }
    }
  }

  &__number-text {
    min-width: rem-calc(32);

    color: $ticket-selector-color-foreground;

    font-size: $font-size-h4;
    text-align: center;
  }
}

/*** SPECIFIC FOR MULTI SELECTION DETAILED **/
.session-multi-selection-level-detailed {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);

  padding: rem-calc(16) 0 0 0;
}

.session-multi-selection-level-item-detailed-wrapper {
  .session-multi-selection-level-item {
    position: relative;

    display: grid;
    grid-template-columns: 1fr auto rem-calc(136) !important;
    align-items: unset;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    overflow: hidden;

    border-radius: rem-calc(4);

    .info {
      display: flex;
      flex-direction: column;
      grid-area: 1 / 1 / 1 / span 2;
      min-height: 6rem;
      padding: rem-calc(12);

      border: rem-calc(1) solid $color-border-main;
      border-start-start-radius: rem-calc(4);
      border-end-start-radius: rem-calc(4);
      border-inline-end-width: 0;
    }

    .amount {
      position: relative;

      display: grid;
      grid-area: 1 / 3 / 1 / 4;
      justify-content: center;
      padding: 0 rem-calc(8);

      color: $ticket-selector-color-foreground;
      text-align: center;

      border: rem-calc(1) solid $color-border-main;
      place-items: center;
      border-start-end-radius: rem-calc(4);
      border-end-end-radius: rem-calc(4);
      border-inline-start: none;
    }

    &__price {
      &__final {
        color: $ticket-selector-multi-detailed-item-price-final-color;

        font-weight: $ticket-selector-multi-detailed-item-price-final-font-weight;
        font-size: $ticket-selector-multi-detailed-item-price-final-font-size;
      }
    }

    .name {
      color: $ticket-selector-color-foreground;

      font-weight: $ticket-selector-multi-detailed-session-name-font-weight;
      line-height: rem-calc(24);
      padding-inline-end: rem-calc(16);
    }

    .separator {
      position: relative;

      grid-area: 1 / 2 / 1 / 3;

      border-right: 1px dashed $color-border-main;

      &::before {
        position: absolute;
        top: 0;
        left: rem-calc(-8);
        z-index: 1;

        box-sizing: border-box;
        width: rem-calc(16);
        height: rem-calc(8);

        background-color: $ticket-selector-color-background;
        border: rem-calc(1) solid $color-border-main;
        border-top: 0;

        content: ' ';
        border-end-start-radius: rem-calc(100);
        border-end-end-radius: rem-calc(100);
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: rem-calc(-8);
        z-index: 1;

        box-sizing: border-box;
        width: rem-calc(16);
        height: rem-calc(8);

        background-color: $ticket-selector-color-background;
        border: rem-calc(1) solid $color-border-main;
        border-bottom: 0;
        border-top-left-radius: rem-calc(100);
        border-top-right-radius: rem-calc(100);

        content: ' ';
      }
    }

    .more-container {
      .more {
        color: $ticket-selector-multi-detailed-more-info-color;
        font-weight: $ticket-selector-multi-detailed-more-info-font-weight;
        font-size: $ticket-selector-multi-detailed-more-info-font-size;
        text-decoration: $ticket-selector-multi-detailed-more-info-text-decoration;
        margin-inline-end: rem-calc(4);

        &:hover {
          cursor: pointer;
        }
      }

      .description {
        margin-block-start: rem-calc(4);

        color: $ticket-selector-multi-detailed-description-color;

        font-size: $ticket-selector-multi-detailed-description-font-size;

        ul {
          padding-left: rem-calc(16);
        }

        > * {
          margin-block-end: rem-calc(8);
        }

        :last-child {
          margin-block-end: 0;
        }
      }
    }
  }
}

.session-single-selection-level {
  &__surcharge-per-ticket {
    margin: rem-calc(16) 0 rem-calc(8) 0;

    color: $ticket-selector-color-foreground;
    font-size: rem-calc(14);
    text-align: center;
  }

  &__surcharge-per-transaction {
    margin: rem-calc(8) 0;

    color: $ticket-selector-color-foreground;
    font-size: rem-calc(14);
    text-align: center;
  }
}

.session-multi-selection-level,
.session-multi-selection-level-detailed,
.session-single-selection-level,
.session-single-selection-vertical-level {
  scroll-margin-top: var(--header-height-small, 48px);

  @include media-breakpoint-up(md) {
    scroll-margin-top: calc(var(--header-height, 48px) + #{rem-calc(16)});
  }
}

.session-single-selection-level,
.session-single-selection-vertical-level {
  &__alerts {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
    margin: rem-calc(16);

    fv-alert .alert {
      margin-bottom: 0 !important;
    }
  }
}

/*  CUSTOM SCROLLBAR  */
.ticket-selector--custom-scrollbar {
  // Firefox
  scrollbar-color: $ticket-selector-scrollbar-thumb-color $ticket-selector-scrollbar-track-color;
  scrollbar-width: thin;

  // Webkit
  ::-webkit-scrollbar {
    width: rem-calc(16);
    height: rem-calc(16);
  }

  ::-webkit-scrollbar-track {
    background: $ticket-selector-scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    background: $ticket-selector-scrollbar-thumb-color;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $ticket-selector-scrollbar-thumb-color-hover;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 10px;
  }
}

fv-plan-banner {
  .plan-banner {
    grid-template-columns: auto auto;
    gap: rem-calc(8);

    justify-content: center;

    min-height: rem-calc(52);
    margin: rem-calc(16) 0;
    padding: rem-calc(8 16);

    background-color: $plan-banner-background-color;
    border-radius: $border-radius-xs;

    &__icon-container {
      width: rem-calc(16);

      color: $plan-banner-icon-color;
    }

    &__text {
      color: $plan-banner-text-color;
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      line-height: rem-calc(20);
    }

    &--urgency {
      --plan-banner-background-color: #{$color-background-warning-weak};
      --plan-banner-icon-color: #{$color-text-warning};
    }
  }
}

fv-default-scroll-controls {
  width: calc(100% + #{rem-calc(32)});
  height: calc(100% - 1px);
  margin-inline-start: rem-calc(-16);

  .arrow {
    $background-opacity-0: color-mix(in srgb, $ticket-selector-color-background, transparent 100%);
    $background-opacity-1: $ticket-selector-color-background;
    width: rem-calc(46);

    color: $ticket-selector-color-foreground;

    border: 0;
    /* stylelint-disable */
    &:first-child {
      background: linear-gradient(
        90deg,
        $background-opacity-0 0%,
        $background-opacity-0 #{rem-calc(16)},
        $background-opacity-1 #{rem-calc(16)},
        $background-opacity-1 63.04%,
        $background-opacity-0 100%
      );
    }

    &:last-child {
      background: linear-gradient(
        -90deg,
        $background-opacity-0 0%,
        $background-opacity-0 #{rem-calc(16)},
        $background-opacity-1 #{rem-calc(16)},
        $background-opacity-1 63.04%,
        $background-opacity-0 100%
      );
    }

    /* stylelint-enable */
  }
}

.level__container.level-scroll--with-controls {
  padding-inline-start: 0 !important;
  scroll-padding: rem-calc(0 24);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &::after {
    content: none;
  }

  > *:has(.level-item):last-child .level-item {
    margin-inline-end: 0;
  }

  .level-item {
    scroll-snap-align: start;

    &.level-item--user-interacted {
      scroll-snap-align: center;
    }
  }
}

// When there are less that 4 time OR date slots (each item has a non-visible extra item), we center the items
.level:is(.level--time, .level--date) .level-scroll--with-controls:not(.level-scroll--overflows):not(:has(> *:nth-child(8))) {
  justify-content: center;
}

// When there are less that 3 items (other than time OR date), we center the items
.level:not(.level--time, .level--date) .level-scroll--with-controls:not(.level-scroll--overflows):not(:has(> *:nth-child(6))) {
  justify-content: center;
}

fv-session-single-selection-vertical-level {
  margin-right: rem-calc(-16);
  margin-left: rem-calc(-16);
}

fv-session-single-selection-vertical-level-item {
  color: $ticket-selector-color-foreground;

  .item {
    min-height: rem-calc(72);

    padding: rem-calc(16);

    column-gap: rem-calc(12);

    &--active {
      background-color: $ticket-selector-active-color-background;
    }

    &__label {
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }

    &__price-section {
      max-width: rem-calc(132);
    }

    &__price {
      gap: rem-calc(8);

      &__base {
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
      }

      &__strikethrough {
        color: $color-text-subtle;

        font-size: $font-size-sm;
        line-height: $line-height-sm;
        text-decoration: line-through;
      }

      &__surcharge {
        margin-top: rem-calc(4);

        font: $font-caption-regular;
        text-align: end;
      }
    }

    &__more-info {
      &__button {
        @include no-style-button;

        color: $ticket-selector-multi-detailed-more-info-color;
        font: $font-caption-bold;
        text-decoration: underline;
      }
    }

    &__details {
      margin-top: rem-calc(12);

      color: $ticket-selector-multi-detailed-description-color;

      font: $font-caption-regular;
    }

    fv-tag {
      margin-top: rem-calc(4);
    }

    &::after {
      bottom: -1px;

      border-bottom: 1px dashed $color-border-main;
    }

    fv-fake-radio-button {
      --fake-radio-outer-radius: #{rem-calc(20)};
      --fake-radio-outer-thickness: #{rem-calc(2)};
      --fake-radio-inner-radius: #{rem-calc(10)};
      --fake-radio-color: var(--theme-action-color, #{$color-action-background-primary});

      .disabled {
        --fake-radio-color: #{$color-action-text-disable};
      }
    }
  }
}

fv-session-single-selection-vertical-level-item .item--disabled {
  color: $color-action-text-disable;

  .item {
    &__more-info__button {
      color: $color-action-text-disable;
    }

    &__price__strikethrough {
      color: $color-action-text-disable;
    }
  }
}

.auto-selected-date {
  padding-block-start: rem-calc(16);
  border-block-end: rem-calc(1) solid $color-border-main;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__edit {
    color: $color-action-text-primary;

    font-weight: $font-weight-bold;
    text-decoration: underline;

    cursor: pointer;

    &:hover {
      color: $color-action-text-primary-hover;
      text-decoration: underline;
    }
  }

  &__notice {
    color: $color-background-accent-base;
    font-size: $font-size-sm;
  }
}

.ticket-selector__footer {
  margin-top: rem-calc(12);

  text-align: center;

  @include media-breakpoint-down(lg) {
    &--border-top {
      padding-top: rem-calc(12);

      border-top: 1px solid $gray-400;
    }
  }
}

.plan-affiliate-code__button {
  display: inline;

  padding: 0;

  font-weight: $font-weight-bold;

  font-size: rem-calc(16);

  @include media-breakpoint-up(lg) {
    margin-top: rem-calc(12);
  }
}

.bundle-helper-message {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;

    margin-top: rem-calc(16);

    text-align: center;
  }
}
