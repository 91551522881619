.cookies-advice {
  position: fixed;
  bottom: 0;
  z-index: $z-index-top-app;

  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-block-end: max(#{rem-calc(16)}, env(safe-area-inset-bottom));
  padding-block-start: rem-calc(16);
  padding-inline: rem-calc(16);

  background: $white;
  box-shadow: $box-shadow-medium;

  &__info {
    max-width: rem-calc(640);
    margin: rem-calc(8) auto rem-calc(12);

    color: $black;
    font-size: rem-calc(14);
    text-align: center;
  }
}
