.plan-affiliate-code-sheet {
  &__header {
    --fv-sheet-header-border-bottom: 0;
  }

  &__title {
    width: 100%;

    font: $font-h5;
    text-align: center;
  }

  &__container {
    --fv-sheet-content-padding: 0 24px 24px 24px;
    margin-top: 0;
  }

  &__content {
    padding-top: 12px;

    border-top: var(--fv-sheet-header-border-bottom);
  }

  &__submit {
    @include media-breakpoint-up(sm) {
      display: flex;
      margin: 4px auto auto;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__form {
    margin: rem-calc(16) 0;
  }
}
