// Do not edit directly
// Generated on Mon, 24 Jul 2023 13:17:24 GMT

@import 'color-palette';

$color-text-main: var(--color-text-main, $color-palette-neutral-800) !default;
$color-text-main-contrast: var(--color-text-main-contrast, $color-palette-neutral-white) !default;
$color-text-subtle: var(--color-text-subtle, $color-palette-neutral-500) !default;
$color-text-subtle-contrast: var(--color-text-subtle-contrast, $color-palette-neutral-300) !default;
$color-text-accent: var(--color-text-accent, $color-palette-accent-500) !default;
$color-text-accent-contrast: var(--color-text-accent-contrast, $color-palette-accent-300) !default;
$color-text-warning: var(--color-text-warning, $color-palette-warning-800) !default;
$color-text-warning-contrast: var(--color-text-warning-contrast, $color-palette-warning-400) !default;
$color-text-danger: var(--color-text-danger, $color-palette-danger-500) !default;
$color-text-danger-contrast: var(--color-text-danger-contrast, $color-palette-danger-100) !default;
$color-text-positive: var(--color-text-positive, $color-palette-positive-700) !default;
$color-text-positive-contrast: var(--color-text-positive-contrast, $color-palette-positive-100) !default;
$color-background-main: var(--color-background-main, $color-palette-neutral-white) !default;
$color-background-main-contrast: var(--color-background-main-contrast, $color-palette-neutral-700) !default;
$body-background-color: var(--body-background-color, $color-background-main);
$color-background-primary-weak: var(--color-background-primary-weak, $color-palette-primary-100) !default;
$color-background-primary-weak-contrast: var(--color-background-primary-weak-contrast, $color-palette-primary-900) !default;
$color-background-primary-medium: var(--color-background-primary-medium, $color-palette-primary-300) !default;
$color-background-primary-medium-contrast: var(--color-background-primary-medium-contrast, $color-palette-primary-700) !default;
$color-background-primary-base: var(--color-background-primary-base, $color-palette-primary-500) !default;
$color-background-primary-base-contrast: var(--color-background-primary-base-contrast, $color-palette-primary-500) !default;
$color-background-primary-strong: var(--color-background-primary-strong, $color-palette-primary-700) !default;
$color-background-primary-strong-contrast: var(--color-background-primary-strong-contrast, $color-palette-primary-400) !default;
$color-background-subtle-weak: var(--color-background-subtle-weak, $color-palette-neutral-100) !default;
$color-background-subtle-weak-contrast: var(--color-background-subtle-weak-contrast, $color-palette-neutral-600) !default;
$color-background-subtle-medium: var(--color-background-subtle-medium, $color-palette-neutral-300) !default;
$color-background-subtle-medium-contrast: var(--color-background-subtle-medium-contrast, $color-palette-neutral-500) !default;
$color-background-subtle-base: var(--color-background-subtle-base, $color-palette-neutral-500) !default;
$color-background-subtle-base-contrast: var(--color-background-subtle-base-contrast, $color-palette-neutral-400) !default;
$color-background-subtle-strong: var(--color-background-subtle-strong, $color-palette-neutral-700) !default;
$color-background-subtle-strong-contrast: var(--color-background-subtle-strong-contrast, $color-palette-neutral-300) !default;
$color-background-accent-weak: var(--color-background-accent-weak, $color-palette-accent-100) !default;
$color-background-accent-weak-contrast: var(--color-background-accent-weak-contrast, $color-palette-accent-900) !default;
$color-background-accent-medium: var(--color-background-accent-medium, $color-palette-accent-300) !default;
$color-background-accent-medium-contrast: var(--color-background-accent-medium-contrast, $color-palette-accent-700) !default;
$color-background-accent-base: var(--color-background-accent-base, $color-palette-accent-500) !default;
$color-background-accent-base-contrast: var(--color-background-accent-base-contrast, $color-palette-accent-500) !default;
$color-background-accent-strong: var(--color-background-accent-strong, $color-palette-accent-700) !default;
$color-background-accent-strong-contrast: var(--color-background-accent-strong-contrast, $color-palette-accent-400) !default;
$color-background-warning-weak: var(--color-background-warning-weak, $color-palette-warning-100) !default;
$color-background-warning-weak-contrast: var(--color-background-warning-weak-contrast, $color-palette-warning-900) !default;
$color-background-warning-medium: var(--color-background-warning-medium, $color-palette-warning-300) !default;
$color-background-warning-medium-contrast: var(--color-background-warning-medium-contrast, $color-palette-warning-700) !default;
$color-background-warning-base: var(--color-background-warning-base, $color-palette-warning-600) !default;
$color-background-warning-base-contrast: var(--color-background-warning-base-contrast, $color-palette-warning-600) !default;
$color-background-warning-strong: var(--color-background-warning-strong, $color-palette-warning-700) !default;
$color-background-warning-strong-contrast: var(--color-background-warning-strong-contrast, $color-palette-warning-400) !default;
$color-background-danger-weak: var(--color-background-danger-weak, $color-palette-danger-100) !default;
$color-background-danger-weak-contrast: var(--color-background-danger-weak-contrast, $color-palette-danger-900) !default;
$color-background-danger-medium: var(--color-background-danger-medium, $color-palette-danger-300) !default;
$color-background-danger-medium-contrast: var(--color-background-danger-medium-contrast, $color-palette-danger-700) !default;
$color-background-danger-base: var(--color-background-danger-base, $color-palette-danger-500) !default;
$color-background-danger-base-contrast: var(--color-background-danger-base-contrast, $color-palette-danger-500) !default;
$color-background-danger-strong: var(--color-background-danger-strong, $color-palette-danger-700) !default;
$color-background-danger-strong-contrast: var(--color-background-danger-strong-contrast, $color-palette-danger-400) !default;
$color-background-positive-weak: var(--color-background-positive-weak, $color-palette-positive-100) !default;
$color-background-positive-weak-contrast: var(--color-background-positive-weak-contrast, $color-palette-positive-900) !default;
$color-background-positive-medium: var(--color-background-positive-medium, $color-palette-positive-300) !default;
$color-background-positive-medium-contrast: var(--color-background-positive-medium-contrast, $color-palette-positive-700) !default;
$color-background-positive-base: var(--color-background-positive-base, $color-palette-positive-500) !default;
$color-background-positive-base-contrast: var(--color-background-positive-base-contrast, $color-palette-positive-500) !default;
$color-background-positive-strong: var(--color-background-positive-strong, $color-palette-positive-700) !default;
$color-background-positive-strong-contrast: var(--color-background-positive-strong-contrast, $color-palette-positive-400) !default;
$color-border-weak: var(--color-border-weak, #ebedf0) !default;
$color-border-main: var(--color-border-main, #ccd2d8) !default;
$color-border-main-contrast: var(--color-border-main-contrast, $color-palette-neutral-600) !default;
$color-border-primary-weak: var(--color-border-primary-weak, $color-palette-primary-100) !default;
$color-border-primary-weak-contrast: var(--color-border-primary-weak-contrast, $color-palette-primary-900) !default;
$color-border-primary-medium: var(--color-border-primary-medium, $color-palette-primary-300) !default;
$color-border-primary-medium-contrast: var(--color-border-primary-medium-contrast, $color-palette-primary-700) !default;
$color-border-primary-base: var(--color-border-primary-base, $color-palette-primary-500) !default;
$color-border-primary-base-contrast: var(--color-border-primary-base-contrast, $color-palette-primary-500) !default;
$color-border-primary-strong: var(--color-border-primary-strong, $color-palette-primary-700) !default;
$color-border-primary-strong-contrast: var(--color-border-primary-strong-contrast, $color-palette-primary-400) !default;
$color-border-accent-weak: var(--color-border-accent-weak, $color-palette-accent-100) !default;
$color-border-accent-weak-contrast: var(--color-border-accent-weak-contrast, $color-palette-accent-900) !default;
$color-border-accent-medium: var(--color-border-accent-medium, $color-palette-accent-300) !default;
$color-border-accent-medium-contrast: var(--color-border-accent-medium-contrast, $color-palette-accent-700) !default;
$color-border-accent-base: var(--color-border-accent-base, $color-palette-accent-500) !default;
$color-border-accent-base-contrast: var(--color-border-accent-base-contrast, $color-palette-accent-500) !default;
$color-border-accent-strong: var(--color-border-accent-strong, $color-palette-accent-700) !default;
$color-border-accent-strong-contrast: var(--color-border-accent-strong-contrast, $color-palette-accent-400) !default;
$color-border-warning-weak: var(--color-border-warning-weak, $color-palette-warning-100) !default;
$color-border-warning-weak-contrast: var(--color-border-warning-weak-contrast, $color-palette-warning-900) !default;
$color-border-warning-medium: var(--color-border-warning-medium, $color-palette-warning-300) !default;
$color-border-warning-medium-contrast: var(--color-border-warning-medium-contrast, $color-palette-warning-700) !default;
$color-border-warning-base: var(--color-border-warning-base, $color-palette-warning-600) !default;
$color-border-warning-base-contrast: var(--color-border-warning-base-contrast, $color-palette-warning-600) !default;
$color-border-warning-strong: var(--color-border-warning-strong, $color-palette-warning-700) !default;
$color-border-warning-strong-contrast: var(--color-border-warning-strong-contrast, $color-palette-warning-400) !default;
$color-border-danger-weak: var(--color-border-danger-weak, $color-palette-danger-100) !default;
$color-border-danger-weak-contrast: var(--color-border-danger-weak-contrast, $color-palette-danger-900) !default;
$color-border-danger-medium: var(--color-border-danger-medium, $color-palette-danger-300) !default;
$color-border-danger-medium-contrast: var(--color-border-danger-medium-contrast, $color-palette-danger-700) !default;
$color-border-danger-base: var(--color-border-danger-base, $color-palette-danger-500) !default;
$color-border-danger-base-contrast: var(--color-border-danger-base-contrast, $color-palette-danger-500) !default;
$color-border-danger-strong: var(--color-border-danger-strong, $color-palette-danger-700) !default;
$color-border-danger-strong-contrast: var(--color-border-danger-strong-contrast, $color-palette-danger-400) !default;
$color-border-positive-weak: var(--color-border-positive-weak, $color-palette-positive-100) !default;
$color-border-positive-weak-contrast: var(--color-border-positive-weak-contrast, $color-palette-positive-900) !default;
$color-border-positive-medium: var(--color-border-positive-medium, $color-palette-positive-300) !default;
$color-border-positive-medium-contrast: var(--color-border-positive-medium-contrast, $color-palette-positive-700) !default;
$color-border-positive-base: var(--color-border-positive-base, $color-palette-positive-500) !default;
$color-border-positive-base-contrast: var(--color-border-positive-base-contrast, $color-palette-positive-500) !default;
$color-border-positive-strong: var(--color-border-positive-strong, $color-palette-positive-700) !default;
$color-border-positive-strong-contrast: var(--color-border-positive-strong-contrast, $color-palette-positive-400) !default;
$color-action-text-main: var(--color-action-text-main, $color-palette-neutral-800) !default;
$color-action-text-main-contrast: var(--color-action-text-main-contrast, $color-palette-neutral-white) !default;
$color-action-text-main-hover: var(--color-action-text-main-hover, $color-palette-neutral-900) !default;
$color-action-text-main-hover-contrast: var(--color-action-text-main-hover-contrast, $color-palette-neutral-white) !default;
$color-action-text-disable: var(--color-action-text-disable, $color-palette-neutral-300) !default;
$color-action-text-disable-contrast: var(--color-action-text-disable-contrast, $color-palette-neutral-500) !default;
$color-action-text-primary: var(--color-action-text-primary, $color-palette-primary-600) !default;
$color-action-text-primary-contrast: var(--color-action-text-primary-contrast, $color-palette-primary-400) !default;
$color-action-text-primary-hover: var(--color-action-text-primary-hover, $color-palette-primary-800) !default;
$color-action-text-primary-hover-contrast: var(--color-action-text-primary-hover-contrast, $color-palette-primary-300) !default;
$color-action-text-accent: var(--color-action-text-accent, $color-palette-accent-500) !default;
$color-action-text-accent-contrast: var(--color-action-text-accent-contrast, $color-palette-accent-300) !default;
$color-action-text-accent-hover: var(--color-action-text-accent-hover, $color-palette-accent-700) !default;
$color-action-text-accent-hover-contrast: var(--color-action-text-accent-hover-contrast, $color-palette-accent-200) !default;
$color-action-text-danger: var(--color-action-text-danger, $color-palette-danger-500) !default;
$color-action-text-danger-contrast: var(--color-action-text-danger-contrast, $color-palette-danger-400) !default;
$color-action-text-danger-hover: var(--color-action-text-danger-hover, $color-palette-danger-700) !default;
$color-action-text-danger-hover-contrast: var(--color-action-text-danger-hover-contrast, $color-palette-danger-300) !default;
$color-action-background-main: var(--color-action-background-main, $color-palette-neutral-700) !default;
$color-action-background-main-contrast: var(--color-action-background-main-contrast, $color-palette-neutral-100) !default;
$color-action-background-main-hover: var(--color-action-background-main-hover, $color-palette-neutral-900) !default;
$color-action-background-main-hover-contrast: var(--color-action-background-main-hover-contrast, $color-palette-neutral-white) !default;
$color-action-background-primary: var(--color-action-background-primary, $color-palette-primary-500) !default;
$color-action-background-primary-contrast: var(--color-action-background-primary-contrast, $color-palette-neutral-white) !default;
$color-action-background-primary-hover: var(--color-action-background-primary-hover, $color-palette-primary-700) !default;
$color-action-background-primary-hover-contrast: var(--color-action-background-primary-hover-contrast, $color-palette-primary-400) !default;
$color-action-background-disable: var(--color-action-background-disable, $color-palette-neutral-100) !default;
$color-action-background-disable-contrast: var(--color-action-background-disable-contrast, $color-palette-neutral-600) !default;
$color-action-background-danger: var(--color-action-background-danger, $color-palette-danger-500) !default;
$color-action-background-danger-contrast: var(--color-action-background-danger-contrast, $color-palette-danger-400) !default;
$color-action-background-danger-hover: var(--color-action-background-danger-hover, $color-palette-danger-600) !default;
$color-action-background-danger-hover-contrast: var(--color-action-background-danger-hover-contrast, $color-palette-danger-300) !default;
$color-action-border-main: var(--color-action-border-main, $color-palette-primary-500) !default;
$color-action-border-main-contrast: var(--color-action-border-main-contrast, $color-palette-neutral-100) !default;
$color-action-border-main-hover: var(--color-action-border-main-hover, $color-palette-neutral-900) !default;
$color-action-border-main-hover-contrast: var(--color-action-border-main-hover-contrast, $color-palette-neutral-white) !default;
$color-action-border-disable: var(--color-action-border-disable, $color-palette-neutral-200) !default;
$color-action-border-disable-contrast: var(--color-action-border-disable-contrast, $color-palette-neutral-600) !default;
$color-action-border-primary: var(--color-action-border-primary, $color-palette-primary-600) !default;
$color-action-border-primary-contrast: var(--color-action-border-primary-contrast, $color-palette-primary-400) !default;
$color-action-border-primary-hover: var(--color-action-border-primary-hover, $color-palette-primary-700) !default;
$color-action-border-primary-hover-contrast: var(--color-action-border-primary-hover-contrast, $color-palette-primary-300) !default;
$color-action-border-danger: var(--color-action-border-danger, $color-palette-danger-500) !default;
$color-action-border-danger-contrast: var(--color-action-border-danger-contrast, $color-palette-danger-400) !default;
$color-action-border-danger-hover: var(--color-action-border-danger-hover, $color-palette-danger-600) !default;
$color-action-border-danger-hover-contrast: var(--color-action-border-danger-hover-contrast, $color-palette-danger-300) !default;
$color-shadow-main: var(--color-shadow-main, $color-palette-neutral-300);
