.fv-box-review {
  &__rating {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: center;
    max-width: map-get($container-max-widths, sm);
    margin: 0 auto rem-calc(18);
    padding: rem-calc(0 16);

    font-size: rem-calc(40);

    input {
      display: none;
    }

    label {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 rem-calc(8);

      color: $ticket-review-likert-color-label;

      cursor: pointer;

      transition: color 0.4s;
    }

    > input:checked ~ label,
    :not(:checked) > label:hover,
    :not(:checked) > label:hover ~ label {
      color: $rating;
    }

    > input:checked + label:hover,
    > input:checked ~ label:hover,
    > label:hover ~ input:checked ~ label,
    > input:checked ~ label:hover ~ label {
      color: $ticket-review-likert-color-input-warning;
    }
  }

  &__rating-value {
    padding-top: rem-calc(12);

    color: $ticket-review-likert-color-rating;
    font-size: rem-calc(16);
  }
}
