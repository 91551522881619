.payment-methods-sheet {
  &__content {
    padding: rem-calc(24);

    &.sheet-content {
      padding: rem-calc(16) rem-calc(24);
    }

    .payment-methods {
      padding: 0;

      list-style-type: none;

      &__item {
        padding: rem-calc(10) 0;

        border-bottom: rem-calc(1) solid $color-border-main;
        cursor: pointer;
      }
    }
  }
}
