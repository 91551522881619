.cart-basket {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-med;

  padding: rem-calc(16) 0;

  background-color: $white;
  border-top: solid rem-calc(1) var(--color-border-main);

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }
  }

  &__price-and-expand {
    @include media-breakpoint-up(lg) {
      text-align: end;
      margin-inline-end: rem-calc(24);
    }
  }

  &__price {
    font-weight: $font-weight-semi-bold;
    margin-block-end: rem-calc(4);
  }

  &__expand {
    color: var(--primary-color);

    cursor: pointer;

    fv-fa-icon {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__padding {
    padding-top: rem-calc(85);
  }
}
