.help {
  &__title-icon {
    margin-inline-end: rem-calc(4);
  }

  &__info {
    &:last-of-type {
      margin-block-end: 0;
    }
  }
}
