fieldset .ng-select.ng-select-single .ng-select-container {
  height: rem-calc(48);

  .ng-value-container .ng-input {
    top: rem-calc(15);
  }
}

.ng-select-focused {
  &:not(.ng-select-opened) > .ng-select-container {
    border-color: $input-focus-visible-border-color !important;
    box-shadow: inset 0 0 0 1px $input-focus-visible-border-color !important;
  }
}

.fv-booking-question-tel-country {
  margin-block-end: rem-calc(16);

  + .form__input {
    display: flex;
    gap: rem-calc(16);
    width: 100%;
    padding: 0;

    border: 0;

    input {
      width: 100%;
      margin: 0;
      padding: rem-calc(0 16);

      font: $font-body-base-regular;

      border: rem-calc(1) solid $color-border-main;
      border-radius: $input-border-radius;

      &:focus-visible {
        outline-color: $input-focus-visible-border-color;
      }

      &:first-of-type {
        flex: 0 0 auto;
        max-width: rem-calc(104);
      }

      &:last-of-type {
        flex: 1 1 0;
      }
    }
  }
}
