:root {
  // Ticket modal
  // Plan
  --fv-transfer-ticket-modal__plan-border-color: #{$color-border-main};

  // Info
  --fv-transfer-ticket-modal__info-background-color: #{$color-background-warning-weak};
  --fv-transfer-ticket-modal__info-icon-color: #{$color-background-warning-base};

  // Error
  --fv-transfer-ticket-modal__error-color: #{$color-background-danger-base};

  // Ticket selector
  // Enabled
  --fv-transfer-ticket-selector__tickets-selected-border-color: #{$color-action-border-primary};
  --fv-transfer-ticket-selector__tickets-border-color: #{$color-border-main};
  --fv-transfer-ticket-selector__tickets-background-color: #f2f3f3;
  // Select Ticket
  --fv-transfer-ticket-selector__tickets-checked-background-color: #{$color-palette-neutral-white};
  --fv-transfer-ticket-selector__tickets-checked-border-color: #{$color-action-border-primary};
  // Disabled
  --fv-transfer-ticket-selector__tickets-disabled-border-color: #{$color-action-border-disable};
  --fv-transfer-ticket-selector__tickets-disabled-color: #{$color-action-text-disable};
  // Amount selector
  --fv-transfer-ticket-selector__tickets-amount-number: #{$ticket-selector-amount-button-color-background};
  --fv-transfer-ticket-selector__tickets-amount-number-disabled: #{$color-background-main};
  // Error
  --fv-transfer-ticket-selector__tickets-error-color: #{$color-background-danger-base};
}

/**
 * Temporary solution to ensure the transfer tickets button displays correctly
 * in white-labels where the background is not a solid color but a gradient.
 */
.fvButton--primary:not([disabled], .fvButton--disabled) {
  background: var(--fv-button-primary-background);
}
