.form {
  &__row {
    display: flex;
    gap: rem-calc(16);
    width: 100%;

    &:not(:last-child) {
      margin-block-end: rem-calc(24);
    }

    &--related-fields {
      align-items: flex-end;
    }

    &--custom-margin {
      margin-block-end: rem-calc(-24);
    }
  }

  &__cell {
    flex: 1 1 0;

    &--hug {
      flex: 0 0 auto;
    }
  }

  &__fieldset {
    padding: rem-calc(16);

    @include media-breakpoint-up(md) {
      padding: rem-calc(32);
    }

    background-color: $color-background-main;

    &:not(last-of-tye) {
      margin-bottom: rem-calc(24);
    }
  }

  &__legend {
    font: $font-h2;
    margin-block-end: rem-calc(32);
  }

  &__label {
    margin: 0 0 rem-calc(16);

    font: $font-body-base-bold;
  }

  &__label-button {
    display: grid;
    width: max-content;
    height: rem-calc(40);

    color: $button-color-foreground-primary;
    font: $font-body-small-bold;
    font-family: $button-font-family;

    background-color: $button-color-background-primary;
    border-radius: $button-dimension-radius;
    cursor: pointer;
    place-items: center;
    padding-inline: $button-dimension-padding-inline-small;
  }

  &__label-check {
    display: flex;
    gap: rem-calc(8);
    align-items: flex-start;
    width: 100%;
    margin: 0;

    font: $font-body-base-regular;

    input {
      margin-block-start: rem-calc(6);
    }

    input,
    label[for] {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__description {
    margin: rem-calc(4 0 0);

    color: $color-text-subtle;
    font: $font-body-small-regular;
  }

  &__input,
  &__select {
    width: 100%;
    height: rem-calc(48);
    margin: 0;
    padding: rem-calc(0 16);

    font: $font-body-base-regular;

    border: rem-calc(1) solid $color-border-main;
    border-radius: $input-border-radius;

    &:focus-visible {
      outline-color: $input-focus-visible-border-color;
    }

    &--error {
      border-color: $color-action-border-danger;
      border-width: rem-calc(2);
    }

    &--sucess {
      border-color: $color-border-positive-base;
      border-width: rem-calc(2);
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__select {
    color: $color-text-main;

    background-color: $color-background-main;
    box-shadow: rgba(31, 28, 29, 0.08) 0 2px 4px 0 inset;

    &--error {
      .ng-select {
        .ng-select-container {
          border-color: $color-action-border-danger;
          border-width: rem-calc(2);
        }
      }
    }
  }

  &__checkbox,
  &__radio {
    accent-color: $color-action-text-primary;
  }

  &__validation {
    font: $font-caption-regular;

    span {
      padding-block-start: rem-calc(8);
    }

    &--error {
      margin-top: rem-calc(4);

      color: $color-text-danger;
      font-size: $font-size-body-small;
      padding-block-start: rem-calc(4);
    }
  }

  &__hint {
    font: $font-body-small-bold;
  }

  &__toggle {
    display: block !important;
    margin-top: rem-calc(8);

    .toggle__label {
      justify-content: space-between;

      font: $font-body-small-bold;
    }
  }
}
