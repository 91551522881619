.entry-pass-comparation-table {
  &__header {
    position: sticky;
    top: $header-height-small;
    left: 0;
    z-index: 9;

    display: flex;
    flex-direction: column;
    padding: rem-calc(8 0 24);

    background-color: $white;
    border-bottom: solid rem-calc(1) $gray-400;
    margin-block-end: rem-calc(24);

    @include media-breakpoint-up(lg) {
      top: $header-height-large;

      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    @include expressive-heading-05;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    fv-fa-icon {
      margin-inline-end: rem-calc(4);
    }
  }

  &__pills {
    display: flex;
    gap: rem-calc(8);
    justify-content: space-between;
    min-height: rem-calc(52);

    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__pill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: rem-calc(8);

    color: $white;

    border-radius: rem-calc(4);

    &--name {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-sm;
      margin-block-end: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &--price {
      font-size: rem-calc(12);
      margin-block-end: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-sm;
      }
    }
  }

  &__section {
    margin-block-end: rem-calc(24);

    border-bottom: solid rem-calc(1) $gray-400;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: $font-weight-semi-bold;
      font-size: $font-size-base;

      cursor: pointer;
      margin-block-end: rem-calc(24);

      @include media-breakpoint-up(lg) {
        font-size: $font-size-h4;
      }
    }

    &-compare {
      display: none;
      flex-direction: column;
      gap: rem-calc(16);
      padding-block-end: rem-calc(24);

      font-size: $font-size-sm;

      opacity: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }

      &--visible {
        display: flex;

        animation: opacity-transition 0.3s linear forwards;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: rem-calc(16);

        @include media-breakpoint-up(lg) {
          flex-direction: column;
          align-items: center;
          padding: rem-calc(24 0);
          margin-block-end: rem-calc(8);

          background-color: $color-background-header-table;
          border-radius: rem-calc(8);
        }

        &--without-footer {
          display: flex;
          flex-direction: column;
          gap: rem-calc(16);

          @include media-breakpoint-up(lg) {
            flex-direction: row;
            gap: 0;
            align-items: center;
            width: 100%;
          }
        }
      }

      &__title {
        padding: rem-calc(8 16);

        background-color: $color-background-header-table;

        @include media-breakpoint-up(lg) {
          width: 50%;

          background-color: transparent;
        }
      }

      &__levels {
        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &__levels-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;

        list-style: none;

        &--item {
          width: 25%;
          padding: 0 rem-calc(4);

          text-align: center;

          fv-fa-icon {
            color: $color-success;
            font-size: $font-size-base;

            @include media-breakpoint-up(lg) {
              font-size: $font-size-h4;
            }
          }
        }
      }

      &__footer {
        color: var(--color-palette-neutral-600);
        text-align: center;

        @include media-breakpoint-up(lg) {
          padding-inline-start: 50%;

          width: 100%;
        }
      }
    }
  }
}
