.booking-wrapper {
  .box {
    padding: rem-calc(16);

    color: $color-palette-neutral-black;

    background-color: $color-palette-neutral-white;

    border: $border-style;
    border-radius: $wl-border-radius;
  }

  @include media-breakpoint-up(lg) {
    &--submit {
      margin: rem-calc(24) auto;
      padding: 0 rem-calc(64);
    }
  }

  .form {
    &__fieldset {
      background-color: $booking-questions-form-background-main;
    }

    &__group-label {
      display: block;

      margin-bottom: rem-calc(12);

      color: $color-text-subtle;

      font: $font-h4;
    }

    &__label {
      margin-bottom: rem-calc(4);
    }

    &__helper {
      display: $booking-questions-form-helper-display;
      margin-bottom: rem-calc(8);

      color: $color-text-subtle;
      font: $font-body-small-regular;
    }

    &__validation {
      span {
        display: inline-block;
      }

      &--error {
        margin: 0;
        padding: 0;
      }
    }
  }

  hr {
    border-top: $border-style;
    opacity: 1;
  }
}

.booking-title {
  color: $subtitle-text-color;
  font: $font-body-small-regular;
}

.booking-form {
  .form {
    &__ticket-counter {
      margin-bottom: rem-calc(8);

      color: $color-action-text-primary;
      font: $font-body-small-bold;
    }

    &__fieldset {
      padding: 0;
    }
  }

  &__error {
    margin-top: rem-calc(24);

    color: $color-text-danger;
    font: $font-body-base-regular;
  }

  .fv-booking-question-image {
    gap: rem-calc(16);

    label {
      margin-bottom: rem-calc(8);
    }
  }
}

.fv-booking-question-multiple-choice {
  .form__validation--error {
    margin-top: rem-calc(-8);

    + .form__validation--error {
      height: 1.25rem;
      margin: rem-calc(-8 0 0 0);
    }
  }
}

.fv-booking-question-multiple-choice-control {
  display: flex;
  flex-direction: column;
  padding-bottom: rem-calc(8);

  .form__validation--error {
    margin-top: rem-calc(-8);
  }

  ul {
    margin: 0;
    padding: 0;

    list-style-type: none;
  }

  li {
    display: flex;
    gap: rem-calc(8);
  }
}

.fv-booking-question-tel {
  &-wrap {
    position: relative;

    .iti--container {
      top: 3rem !important;
      left: 0 !important;

      width: 100%;

      .iti__country-list {
        width: 100%;
      }
    }
  }
}

.fv-booking-questions-form-group {
  a {
    text-decoration: underline;
  }

  .form__group {
    &-header {
      display: flex;
      gap: rem-calc(12);
      align-items: center;
      margin-block-end: rem-calc(24);

      .form__label {
        margin: 0;
      }
    }

    &-image {
      width: rem-calc(48);
      height: rem-calc(48);

      border-radius: rem-calc(4);
    }
  }
}

.booking-questions-sheet__footer {
  display: flex;
  flex-direction: column;
}
