.fv-box-review {
  &__boolean {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    max-width: map-get($container-max-widths, sm);
    margin: 0 auto rem-calc(32);
    padding: rem-calc(24 0 0 0);

    input {
      display: none;
    }

    label {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 rem-calc(8);

      color: $ticket-review-boolean-color-label;

      cursor: pointer;

      transition: color 0.4s;

      span {
        padding-top: rem-calc(32);

        font-size: rem-calc(24);
      }
    }

    input:hover ~ label {
      color: $ticket-review-boolean-color-input-warning;
    }

    input:checked ~ label {
      color: $ticket-review-boolean-color-input-warning;
    }
  }
}
