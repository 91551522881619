.plan-filters {
  padding-block-start: rem-calc(12);

  .page-header__title {
    font-family: $font-stack;
  }

  &__separator {
    display: $plan-filters-separator-display;
    margin: rem-calc(24) 0 0;

    color: $color-border-main;

    opacity: 1;
    @include media-breakpoint-up(lg) {
      margin: rem-calc(32) 0 0;
    }
  }
}

.form__cell {
  &--taxonomies,
  &--cities {
    @include media-breakpoint-up(lg) {
      max-width: rem-calc(192);
    }
  }

  &--chips {
    display: flex;
    gap: rem-calc(16);
  }

  button {
    &.form {
      &__input {
        min-width: rem-calc(192);

        text-align: start;

        background-color: $color-background-main;
        box-shadow: rgba(31, 28, 29, 0.08) 0 2px 4px 0 inset;

        &.dropdown-toggle {
          height: rem-calc(48);
          overflow: hidden;

          white-space: nowrap;
          text-overflow: ellipsis;
          padding-inline-end: rem-calc(40);

          &::after {
            position: absolute;
            top: rem-calc(26);
            right: rem-calc(16);
          }
        }
      }
    }
  }
}
