.cdk-overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-top;

  width: 100%;
  height: 100%;

  background-color: $sheet-color-backdrop;
}

.sidebar {
  &__main-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $z-index-top;

    display: grid;
    flex-direction: column;
    grid-template-rows: auto 1fr;
    width: 100%;
    max-width: rem-calc(375);
    height: 100%;
    padding: rem-calc(24);

    background-color: $sheet-color-background;
    border-top-left-radius: rem-calc(16);
  }
}
