.orders-section {
  padding: rem-calc(32 16);

  @include media-breakpoint-up(lg) {
    padding: rem-calc(80 16);
  }

  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__row {
    @include make-row();
  }

  &__content {
    @include make-col-ready-custom();
    @include make-col(12);
  }
}

.accordion-button {
  margin-block-end: rem-calc(56);

  &:hover {
    cursor: pointer;
  }

  &__icon {
    padding-inline-start: rem-calc(4);

    color: $order-accordion-icon-color;
  }
}

fv-ticket-actions {
  fv-banner-action + fv-banner-action {
    border-top: var(--fv-ticket-actions-separator, 1px solid $color-border-main);
  }
}
