.plans-container {
  padding-block-start: $plans-container-dimensions-block-start-sm;
  padding-block-end: $plans-container-dimensions-block-end-sm;
  @include media-breakpoint-up(md) {
    padding-block-start: $plans-container-dimensions-block-start-lg;
    padding-block-end: $plans-container-dimensions-block-end-lg;
  }

  .faqs,
  .entry-pass-comparation-table {
    margin-block-start: rem-calc(40);
  }

  .entry-pass-comparation-table {
    margin-block-start: rem-calc(32);

    @include media-breakpoint-up(lg) {
      margin-block-start: rem-calc(40);
    }
  }
}

.plans-list-container {
  padding-block-start: $plans-list-dimensions-block-start-sm;
  @include media-breakpoint-up(md) {
    padding-block-start: $plans-list-dimensions-block-start-lg;
  }
}

.plans-list {
  display: grid;
  grid-gap: $plan-list-grid-dimensions-gap;
  grid-template-columns: repeat($plans-list-items-per-row, 1fr);
  margin: 0;
  padding: 0;

  list-style: none;

  @include media-breakpoint-up(sm) {
    grid-gap: $plan-list-grid-dimensions-gap-small;
    grid-template-columns: repeat($plans-list-items-per-row-sm, 1fr);
  }
  @include media-breakpoint-up(md) {
    grid-gap: $plan-list-grid-dimensions-gap-medium;
    grid-template-columns: repeat($plans-list-items-per-row-md, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-gap: $plan-list-grid-dimensions-gap-large;
    grid-template-columns: repeat($plans-list-items-per-row-lg, 1fr);
  }
}

.plans-empty {
  background-color: $plans-empty-color-background;
  padding-block-end: rem-calc(40);
}

.no-plan-available-title,
.no-plan-available {
  font-weight: $font-weight-bold;
  font-size: $font-size-h4;
  font-family: $font-stack;
  line-height: $font-line-height-h4;
}

.plans-header {
  &__title {
    display: flex;
    flex-flow: column-reverse;
    padding: 0;

    font: $font-h2;
    margin-block-end: $plans-header-dimensions-margin-bottom-small;

    @include media-breakpoint-up(lg) {
      font: $font-display-medium;
      margin-block-end: $plans-header-dimensions-margin-bottom-large;
    }

    &--secondary {
      color: $plans-header-secondary-color;
      font: $font-body-small-bold;

      @include media-breakpoint-up(lg) {
        font: $font-body-base-bold;
      }
    }
  }

  &__subtitle {
    margin: 0;

    color: $color-text-subtle;
    font: $font-body-small-regular;
    text-wrap: pretty;

    @include media-breakpoint-up(lg) {
      font: normal normal $font-weight-normal $font-size-h4 $font-line-height-h4;
    }
  }
}

.plans-affiliate-code {
  &__divider {
    margin-top: rem-calc(48);
    margin-bottom: rem-calc(35);

    border-top: rem-calc(1) solid $color-border-main;
  }

  .button--tertiary {
    margin-top: 0;
  }

  .button--tertiary:hover {
    text-decoration: underline;

    background-color: transparent;
  }
}

.highlighted-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    max-width: $page-dimensions-max-width;
    margin-inline: auto;

    padding-block-start: $plans-container-dimensions-block-start-lg;
    padding-block-end: $plans-container-dimensions-block-end-lg;
  }

  &__text-container {
    padding: rem-calc(24 16 0 16);
    @include media-breakpoint-up(md) {
      flex: 1 0 55%;
    }
  }

  &__content {
    flex-grow: 1;
    @include media-breakpoint-up(md) {
      padding-inline: 1rem;
    }
  }
}
