.fv-review-ticket-detail {
  @include make-row();
  padding: rem-calc(0 0 16);

  &__img {
    @include make-col-ready;
    @include shrink;

    img {
      border-radius: $ticket-review-ticket-detail-image-border-radius;
      box-shadow: $ticket-review-ticket-detail-image-box-shadow;
    }
  }

  &__data {
    @include make-col-ready;
    @include auto;
    padding-right: rem-calc(16);
    padding-left: 0;
  }

  &__name {
    margin-bottom: rem-calc(8);

    font-size: $font-size-h3;
    font-family: var(--font-stack) !important;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-h2;
    }
  }

  &__attribute {
    display: inline;
    @include media-breakpoint-up(sm) {
      margin-right: rem-calc(8);
    }
  }
}
