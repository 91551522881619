.claim-ticket-error-alerts {
  margin-bottom: rem-calc(8);
  padding: rem-calc(12);
}

.claim-ticket-error {
  display: flex;
  flex-direction: column;
  gap: rem-calc(34);
  margin-top: rem-calc(34);
}
