:root {
  // Ticket details
  --fv-claim-ticket-transfer-detail__background-color: #{$color-background-main};
  // Sender details
  --fv-claim-ticket-transfer-detail__background-color-before: #{$white};
  --fv-claim-ticket-transfer-detail__sender-color: #{$color-text-main};
  // Transfer info
  --fv-claim-ticket-transfer-detail__info-border-color: #{$color-border-main};

  --fv-claim-ticket-transfer-detail-before_claiming-title-text-align: left;
  --fv-claim-ticket-transfer-detail-before_claiming-separator-display: none;
  --fv-claim-ticket-transfer-detail-before_claiming-title-margin-bottom: 1.5rem;

  // Login
  --fv-claim-ticket-transfer-detail-before_claiming-padding-sm: #{$login-dimensions-padding-sm};
  --fv-claim-ticket-transfer-detail-before_claiming-background-color: #{$login-background-color};
  --fv-claim-ticket-transfer-detail-before_claiming-border: #{$login-border};
  --fv-claim-ticket-transfer-detail-before_claiming-border-radius: #{$login-border-radius};
  --fv-claim-ticket-transfer-detail-before_claiming-box-shadow: #{$login-box-shadow};
  --fv-claim-ticket-transfer-detail-before_claiming-padding-lg: #{$login-dimensions-padding-lg};

  // Plan details
  --fv-claim-ticket-transfer-plan__color: #{$color-text-main};
  --fv-claim-ticket-transfer-plan__background: #{$color-background-main};
  --fv-claim-ticket-transfer-plan__box-shadow: none;

  --fv-claim-ticket-transfer-plan__header-container-border: var(--orders-item-border,
  0.0625rem solid var(--color-border-main, var(--color-palette-neutral-200, #ccd2d8)));
  --fv-claim-ticket-transfer-plan__header-container-border-radius: #{$border-radius-sm};
  --fv-claim-ticket-transfer-plan__header-container-box-shadow: var(--orders-item-effect-box-shadow,
  var(--box-shadow-small, 0 0 0.375rem 0 var(--color-shadow-main, var(--color-palette-neutral-300, #a7b2ba))));
  --fv-claim-ticket-transfer-plan__header-container-padding: 1rem;
  // Header
  --fv-claim-ticket-transfer-plan__header-color: #{$color-text-main};
  --fv-claim-ticket-transfer-plan__header-title-color: #{$color-text-main};
  --fv-claim-ticket-transfer-plan__header-margin-bottom: 2rem;
}

.wrapper {
  @include make-container();
  @include make-container-max-widths();
}

.card-wrapper {
  position: relative;

  padding: rem-calc(12);

  color: $color-text-main-contrast;
}
