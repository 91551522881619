.plan-description-tabs {
  // stylelint-disable selector-max-id
  #tab1:checked ~ .tab.content1,
  #tab2:checked ~ .tab.content2,
  #tab3:checked ~ .tab.content3,
  #tab4:checked ~ .tab.content4,
  #tab5:checked ~ .tab.content5 {
    display: block;
  }
  // stylelint-enable selector-max-id

  input + label {
    display: inline-block !important;
    padding: rem-calc(4 8);

    color: $tabs-color-text;
    font-size: rem-calc(14);

    background-color: $tabs-color-background;

    border-bottom: $tabs-border-bottom;
    border-radius: rem-calc(4 4) 0 0;

    cursor: pointer;
    margin-block-end: rem-calc(12);

    @include media-breakpoint-up(lg) {
      padding: rem-calc(4 12);

      color: $color-text-subtle;
      font-size: rem-calc(16);

      background-color: transparent;
      border-bottom: $tabs-border-bottom-lg;
      margin-block-end: rem-calc(16);
    }
  }

  input:checked + label {
    color: $color-text-main;
    font-weight: $font-weight-semi-bold;

    background-color: $tabs-color-background-selected;

    border-bottom: $tabs-border-bottom-selected;
  }

  input ~ .tab {
    display: none;
  }

  .tab {
    width: calc(100vw - 2rem);
    max-width: 100%;
    padding: $tab-content-padding;

    background-color: $tab-content-color-background;
    border-radius: rem-calc(8);

    @include media-breakpoint-up(lg) {
      width: auto;
      padding: $tab-content-padding-lg;
    }

    h2 {
      font: $plan-description-tab-title-font;
      text-transform: $plan-description-tab-title-text-transform;
      margin-block-end: rem-calc(16);
      text-wrap: pretty;
    }

    h3 {
      font-weight: $font-weight-semi-bold;
      font-size: rem-calc(18);
      line-height: 1.3;
      text-transform: $plan-description-tab-h3-text-transform;
      text-wrap: pretty;
      padding-block-start: rem-calc(16);
    }

    h4 {
      font: $font-body-base-bold;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(2);
    justify-content: center;

    @include media-breakpoint-up(md) {
      gap: 0;
      justify-content: flex-start;
      width: auto;
      margin: 0;
    }
  }
}
