:root {
  --page-aside-margin-top: -140px;
}

.page-plan-view {
  @include page-section-dimensions-padding-block-end;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;

    .page__main {
      max-width: calc(67% - #{$page-wrapper-dimensions-gap-lg});
    }
  }

  hr {
    margin-block: rem-calc(24);
    border-block-start: rem-calc(1) solid $color-border-main;

    opacity: 1;

    @include media-breakpoint-up(lg) {
      margin-block: rem-calc(32);
    }

    &.transparent {
      margin-block: rem-calc(16);
      border-block-start-color: transparent;
    }
  }

  .page__wrapper {
    width: 100%;
    max-width: $page-dimensions-max-width;
    padding-inline: 1rem;
    margin-inline: auto;

    .page__aside {
      z-index: 1;
      @include media-breakpoint-up(lg) {
        margin-top: var(--page-aside-margin-top);
      }

      .powered-by {
        display: block;
        width: rem-calc(112);
        height: rem-calc(16);
        margin: rem-calc(16) auto 0 auto;
      }
    }
  }

  &--one-photo {
    .page__wrapper {
      .page__aside {
        @include media-breakpoint-up(lg) {
          margin-top: var(--page-aside-margin-top-one-photo, -140px);
        }
      }
    }
  }

  .entry-pass-comparation-table {
    padding-block-start: rem-calc(16);
    margin-block-start: rem-calc(24);

    border-top: solid rem-calc(1) $gray-400;

    @include media-breakpoint-up(lg) {
      padding-block-start: rem-calc(24);
      margin-block-start: rem-calc(32);
    }

    &__section {
      &:last-child {
        margin-block-end: 0;

        border-bottom: 0;
      }
    }
  }

  .entry-pass-comparation-table .page__container,
  .faqs .page__container {
    padding: 0;
  }

  .faqs .faqs__title {
    font: $font-h3;

    @include media-breakpoint-up(lg) {
      font: $font-h2;
    }
  }

  .faqs .faqs__button {
    text-decoration: none;

    &:hover {
      color: var(--color-text-main);
    }
  }
}
