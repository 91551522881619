.language-modal {
  @include flex-center;
  flex-flow: column;

  &__title {
    @include expressive-heading-05;
    margin-bottom: rem-calc(32);

    color: $language-modal-title-text-color;
  }

  &__dropdown-container {
    width: 100%;
    max-width: rem-calc(320);
  }

  &__dropdown-button {
    position: relative;

    width: 100%;
    height: rem-calc(56);
    padding: 0 rem-calc(16);

    font-size: rem-calc(16);
    text-align: left;

    background-color: var(--white);
    border: rem-calc(1) solid var(--gray-500);
    border-radius: rem-calc(6);

    &::after {
      display: none;
    }
  }

  &__form {
    width: 100%;
    max-width: rem-calc(320);
  }

  &__button {
    margin-top: rem-calc(16);
  }

  select {
    display: none;
  }
}
