.quick-info {
  padding: rem-calc(12);

  border: rem-calc(1) solid $quick-info-border-color;
  border-radius: $box-wrapper-dimensions-border-radius;
  margin-block-end: rem-calc(24);

  &__icon {
    display: grid;
    width: rem-calc(28);
    height: rem-calc(28);

    color: $quick-info-icon-color-foreground;
    font-size: rem-calc(16);

    background: $quick-info-icon-color-background;
    border-radius: rem-calc(4);
    place-items: center;
  }

  &__title {
    font: $font-body-small-bold;
  }

  &__list-item {
    display: flex;
    gap: rem-calc(16);
    align-items: center;
  }

  hr {
    margin-block: rem-calc(12);
    border-block-start: rem-calc(1) solid $quick-info-border-color;
  }
}

@include media-breakpoint-up(md) {
  .quick-info {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: rem-calc(24);

      column-gap: rem-calc(32);
    }

    hr {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .quick-info {
    margin-block-start: rem-calc(8);
  }
}
