.chip-filter {
  display: flex;
  flex-flow: row nowrap;
  gap: rem-calc(16);
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;

  list-style: none;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &--scroll {
    padding: 0 calc((50% - #{rem-calc(96)}) / 2);
  }

  &__item {
    @include shrink;
    width: $chip-filter-dimensions-width;
    padding: rem-calc(8) rem-calc(16);

    color: $chip-filter-color;
    font: $chip-filter-font;
    white-space: nowrap;

    border: $chip-filter-border;
    border-radius: $border-radius-pill;

    cursor: pointer;
    scroll-snap-align: center;

    &--selected {
      color: $chip-filter-color-foreground-selected;

      background-color: $chip-filter-color-background-selected;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
