.nav-link {
  display: block;
  padding: rem-calc(4 16);

  color: $tabs-color-text;

  background-color: $tabs-color-background !important;

  border-bottom: $tabs-border-bottom;

  border-bottom: unset;
  border-radius: rem-calc(4 4) 0 0;

  @include media-breakpoint-up(lg) {
    padding: rem-calc(4 12);

    color: $color-text-subtle;

    background-color: transparent !important;
    border-bottom: 0;
    margin-block-end: rem-calc(16);
  }

  &:hover {
    color: var(--text-default);

    border: unset;
  }

  &.active {
    color: $color-text-main !important;
    font-weight: 600;

    background-color: $tabs-color-background-selected !important;

    border-bottom: rem-calc(4) solid $color-action-border-primary !important;
  }
}

.groups-info-tabs {
  &__container {
    padding: rem-calc(20);

    background-color: $groups-info-tabs-container-color-background;
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: rem-calc(12);
  }

  &__header-title {
    @include large-title();
    flex: 1;

    color: var(--color-text-main);
  }
}

.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc(2);
  justify-content: flex-start;

  border-bottom: 0;

  @include media-breakpoint-up(md) {
    gap: 0;
    width: auto;
    margin: 0;
  }

  .nav-item {
    margin-block-end: rem-calc(12);

    a,
    button {
      border: 0;

      &:hover {
        color: var(--text-neutral);
      }
    }
  }
}
