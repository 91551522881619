.faqs {
  &__title {
    @include expressive-heading-05;

    &-container {
      display: flex;
      align-items: center;
      margin-bottom: rem-calc(24);

      fv-fa-icon {
        margin-inline-end: rem-calc(4);

        font-size: rem-calc(20);
      }
    }
  }

  &__subtitle {
    margin-bottom: rem-calc(0);
  }

  &__button {
    @include button(40);
    align-self: start;
    width: 100%;
    max-width: rem-calc(150);
    height: rem-calc(44);
    margin: rem-calc(8) 0;

    font-size: rem-calc(14);

    @include media-breakpoint-up(md) {
      max-width: rem-calc(150);
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column;

    @include media-breakpoint-up(lg) {
      flex-flow: row;
    }
  }

  &__column {
    padding-top: rem-calc(40);

    .collapsible__title {
      margin: rem-calc(8 0);

      color: $black;
      font-weight: normal;
      line-height: 1.5;
      text-transform: none;
    }

    .collapsible__content {
      padding-left: rem-calc(36);

      color: $gray-700;
    }

    .collapsible__show-button {
      padding: 0 rem-calc(8);
    }
  }

  &__list {
    padding: 0;

    list-style: none;
  }

  &__item {
    padding-block-end: rem-calc(16);
    margin-block-end: rem-calc(16);

    border-bottom: rem-calc(1) solid var(--color-border-main);
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding-block-end: rem-calc(20);
    }
  }

  &__question {
    display: flex;
    justify-content: space-between;
    margin-block-end: 0;

    font-size: rem-calc(16);
  }

  &__answer {
    display: none;

    color: var(--color-palette-neutral-600);
    font-size: rem-calc(14);

    cursor: default;

    opacity: 0;
    margin-block-start: rem-calc(16);

    &--open {
      display: block;

      animation: opacity-transition 0.3s linear forwards;
    }

    p {
      margin-block-end: rem-calc(8);
    }

    a {
      color: $color-action-text-primary;
      text-decoration: underline;
    }
  }
}

.faqs h3.faqs__title {
  margin-block-end: 0;
}
