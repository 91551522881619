:root {
  --fv-banner-action__gap: #{rem-calc(16)};
  --fv-banner-action__background-color: transparent;
  --fv-banner-action__color: #{$text-default};
  --fv-banner-action__border-radius: #{$wl-border-radius};
  --fv-banner-action__label-padding: 0;
  --fv-banner-action__label-font_weight: #{$font-weight-regular};
  --fv-banner-action__padding: 1rem 0;
  --fv-banner-action__icon-color: #{$black};
}
