:root {
  --fv-voucher-card-box-shadow: #{$box-shadow-small};
  --fv-voucher-card-border-radius: #{$border-radius};
  --fv-voucher-card-title-font-weight: #{$font-weight-bold};
  --fv-voucher-card-label-color: #{$color-action-text-primary};
  --fv-voucher-card-label-background-color: #{$gray-100};
  --fv-voucher-card-label-border-radius: #{$border-radius-xs};
  --fv-voucher-card-font-weight-bold: #{$font-weight-regular};
  --fv-voucher-card-panel-box-shadow: #{rem-calc(0 4 16 0) rgba(0, 0, 0, 0.2)};
  --fv-voucher-card-panel-padding-inline: #{rem-calc(16)};
  --fv-voucher-card-netflix-border-radius: #{$border-radius};
  --fv-voucher-card-netflix-box-shadow: #{$box-shadow-small};
  --fv-voucher-card-netflix-title-font-weight: #{$font-weight-bold};
  --fv-voucher-card-netflix-label-color: #{$color-action-text-primary};
  --fv-voucher-card-netflix-background-color: #{$color-action-background-primary};
  --fv-voucher-card-border-inline-start: #{$color-border-main};
  --fv-voucher-form-link-font-weight: #{$font-weight-bold};
  --fv-voucher-form-link-color: #{$color-action-text-primary};
  --fv-voucher-sheet-divider-border-top-color: #{$color-border-main};
  --fv-voucher-sheet-form-title-color: #{$color-action-text-primary};
  --fv-voucher-sheet-form-title-font-weight: #{$font-weight-regular};
  --fv-voucher-sheet-form-title-font-size: #{$font-size-sm};
  --fv-voucher-sheet-form-list-title-color: #{$color-action-text-primary};
  --fv-voucher-sheet-form-list-title-font-weight: #{$font-weight-regular};
  --fv-voucher-sheet-form-list-title-font-size: #{$font-size-sm};
}

.voucher-form__button-container {
  .voucher-form__button.fvButton.fvButton--dark-alt.fvButton--small {
    height: rem-calc(48);

    color: $button-color-foreground-primary;
    font: $font-body-base-bold;
    font-family: $button-font-family;

    background-color: $button-color-background-primary;
    border: $button-border-primary;
    margin-block: rem-calc(4);

    &:hover {
      color: $button-color-foreground-primary-hover;

      background-color: $button-color-background-primary-hover;
      border: $button-border-primary-hover;
    }
  }
}
