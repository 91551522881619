.cart-navbar-button {
  position: relative;

  margin: 0;
  padding: 0;

  color: $color-palette-neutral-white;

  background-color: transparent;
  border: 0;

  outline: none;

  cursor: pointer;

  @include media-breakpoint-up(lg) {
    margin-inline-start: rem-calc(16);
  }

  fv-fa-icon {
    z-index: 1;

    font-size: rem-calc(24);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24);
    }
  }

  span {
    position: absolute;
    top: rem-calc(-8);
    right: rem-calc(-8);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: rem-calc(18);
    height: rem-calc(18);

    color: var(--secondary-color);
    font-weight: var($font-weight-semi-bold, $font-weight-bold);
    font-size: rem-calc(12);
    line-height: rem-calc(12);

    background-color: var(--background-color-num-items-cart);
    border: solid rem-calc(1) var(--background-color-num-items-cart);
    border-radius: 50%;
    padding-block-start: rem-calc(1);
  }
}
