.bundle-selector {
  position: relative;

  max-width: rem-calc(550);
  margin: 0 auto;

  background-color: $color-background-main;

  &__discount-banner {
    &--top {
      padding: rem-calc(16) rem-calc(16) rem-calc(4);
    }

    &--bottom {
      padding: rem-calc(0) rem-calc(16) rem-calc(4);
    }
  }

  &__buy-button {
    padding: rem-calc(24) rem-calc(16);
  }

  &__alert {
    position: absolute;
    bottom: rem-calc(12);
    z-index: $z-index-top;

    width: 100%;
    padding: 0 rem-calc(12);
  }
}
