:root {
  --sheet-padding: 1rem;
}

.purchase-banner-container {
  background-color: $secondary-color--dark;
}

.purchase-banner {
  display: flex;
  align-items: center;
  padding-inline: rem-calc(16);
  padding-block: rem-calc(14);

  color: $navbar-action-color-text-primary;
  font-size: rem-calc(14);

  @include media-breakpoint-up(lg) {
    padding-inline: 0;
  }

  &__icon {
    margin-inline-end: rem-calc(8);

    font-size: rem-calc(20);
  }

  &__text {
    margin-bottom: 0;
  }

  &__link {
    color: $navbar-action-color-text-primary;
    text-decoration: underline;

    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: $navbar-action-color-text-primary;
      text-decoration: underline;
    }
  }
}

.purchase-banner-sheet {
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    max-width: 50vw;
  }

  &__header {
    .sheet-header {
      justify-content: center;

      &__close {
        top: rem-calc(12);
      }
    }
  }

  &__content {
    font-size: rem-calc(14);

    &__text {
      max-height: 100%;
      overflow-y: auto;

      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }
  }
}
