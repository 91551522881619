//variable
$curve-bite: rem-calc(128);

.fv-review {
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;

  background: $color-background-main;
}

.fv-review-header {
  display: none;

  visibility: hidden;

  @include media-breakpoint-up(sm) {
    @include flex-center;
    height: auto;

    color: $ticket-review-ticket-review-header-color;

    visibility: inherit;
  }

  &__wrapper {
    max-width: map-get($container-max-widths, sm);
    margin: 0 auto rem-calc(48);
  }

  &__logo {
    display: block;
    height: rem-calc(40);
    margin: rem-calc(24) auto;

    @include media-breakpoint-up(sm) {
      height: rem-calc(48);
      margin: rem-calc(40) auto;
    }
  }

  &__title {
    font: $font-h2;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font: $font-display;
    }
  }
}

.fv-box-review {
  max-width: map-get($container-max-widths, sm);
  margin: 0 auto;
  padding: rem-calc(0 16);

  &__wrapper {
    margin-bottom: rem-calc(16);
    padding: rem-calc(16);

    background: $ticket-review-ticket-review-wrapper-color-background;
    border: $ticket-review-ticket-review-wrapper-border;
    border-radius: $ticket-review-ticket-review-wrapper-border-radius;
    box-shadow: $ticket-review-ticket-review-wrapper-box-shadow;
  }

  &__item {
    color: $rating;
  }
}

.wl-hide-in-mobile {
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
  }
}

.fv-review-questions-error {
  display: flex;
  align-items: center;
  padding: rem-calc(16);

  fv-fa-icon {
    @include shrink;
    margin-right: rem-calc(16);

    font-size: rem-calc(48);
    line-height: 1em;

    &.icon {
      &-success {
        color: $ticket-review-ticket-review-questions-error-color-icon-success;
      }

      &-error {
        color: $ticket-review-ticket-review-questions-error-color-icon-error;
      }
    }
  }

  &__title {
    margin-bottom: 0;

    font-size: $h3-font-size;
  }

  &--info {
    color: $ticket-review-ticket-review-info-color;

    background-color: $ticket-review-ticket-review-info-color-background;
  }

  &--danger {
    color: $ticket-review-ticket-review-danger-color;

    background-color: $ticket-review-ticket-review-danger-color-background;
  }

  &--warning {
    background-color: $ticket-review-ticket-review-warning-color-background;
  }

  &--success {
    color: $ticket-review-ticket-review-success-color;

    background-color: $ticket-review-ticket-review-success-color-background;
  }
}
