.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem-calc(128);
  max-width: 100%;

  overflow: hidden;

  white-space: nowrap;
  text-transform: $button-text-transform;
  text-overflow: ellipsis;

  background-color: transparent;

  border-radius: $button-dimension-radius;
  outline: none;
  cursor: pointer;

  transition: all 0.3s ease 0s;
  padding-inline: $button-dimension-padding-inline-small;

  @include media-breakpoint-up(sm) {
    letter-spacing: $button-text-letter-spacing;
  }

  &--fill {
    width: 100%;
  }

  &--small {
    height: $button-dimension-height-small;

    font: $font-body-small-bold;
    font-family: $button-font-family;
  }

  &--large {
    height: $button-dimension-height-large;

    font: $font-body-base-bold;
    font-family: $button-font-family;
  }

  &--primary {
    color: $button-color-foreground-primary;

    background: $button-color-background-primary;
    border: $button-border-primary;

    &:hover {
      color: $button-color-foreground-primary-hover;

      background-color: $button-color-background-primary-hover;
      border: $button-border-primary-hover;
    }
  }

  &--secondary {
    color: $button-color-foreground-secondary;

    background-color: $button-color-background-secondary;
    border: $button-border-secondary;

    &:hover {
      color: $button-color-foreground-secondary-hover;

      background-color: $button-color-background-secondary-hover;
      border: $button-border-secondary-hover;
    }
  }

  &--tertiary {
    color: $button-color-foreground-tertiary;

    background-color: $button-color-background-tertiary;
    border: $button-border-tertiary;

    &:hover {
      color: $button-color-foreground-tertiary-hover;

      background-color: $button-color-background-tertiary-hover;
      border: $button-border-tertiary-hover;
    }
  }

  &--contrast {
    color: $button-color-foreground-secondary-contrast;

    background-color: $button-color-background-secondary-contrast;
    border: $button-border-secondary-contrast;

    &:hover {
      color: $button-color-foreground-secondary-contrast-hover;

      background-color: $button-color-background-secondary-contrast-hover;
      border: $button-border-secondary-contrast-hover;
    }
  }

  &:hover {
    transform: $button-effect-transform-hover;
  }

  &:disabled,
  &:disabled &:hover {
    color: $color-action-text-disable;

    background: $color-action-background-disable;
    border-color: $color-action-border-disable;
    box-shadow: none;
    transform: none;
    cursor: not-allowed;
  }
}

/*Google pay button*/
/* stylelint-disable */
ngx-stripe-payment-request .__PrivateStripeElement {
  border-radius: $button-dimension-radius;
  overflow: hidden;
}
/* stylelint-enable */
