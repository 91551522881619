:host {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.free-seating-selector-sidebar {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: rem-calc(60);
  max-height: 80%;
  overflow: auto;

  background-color: var(--white);
  border-radius: $free-seating-selector-sidebar-dimension-radius;
  box-shadow: $box-shadow-small;

  @include media-breakpoint-down(md) {
    padding-bottom: rem-calc(80);
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    top: 0;

    align-self: flex-start;

    width: 33%;

    max-height: 100%;
    padding: 0;

    border: var(--border-style);
    box-shadow: none;
  }

  &__purchase {
    position: fixed;
    bottom: 0;
    left: 0;

    z-index: $z-index-med;

    width: 100%;
    padding: rem-calc(16);

    background-color: var(--white);
    box-shadow: 0 rem-calc(-0) rem-calc(4) var(--gray-500);

    @include media-breakpoint-up(md) {
      position: sticky;
      bottom: 0;

      width: 100%;
      margin-top: auto;
      padding: 0 rem-calc(16 16);

      background: linear-gradient(0deg, var(--white) 60%, transparent);
      box-shadow: none;
    }
  }

  .session-selector {
    &__apply {
      margin-top: rem-calc(16);
    }

    &__toggle {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: rem-calc(8);
      align-items: center;
      justify-items: start;
      width: 100%;
      padding: rem-calc(16 24);

      color: var(--black);
      font-size: rem-calc(16);

      background: var(--white);
      border: unset;

      &__calendar-icon {
        font-size: rem-calc(16);
      }

      &__arrow-icon {
        font-size: rem-calc(16);
      }
    }

    &__collapsible {
      padding: rem-calc(0 16);

      background: var(--white);

      transition: $transition-collapse;

      @include media-breakpoint-up(lg) {
        padding: rem-calc(16);
      }

      .calendar {
        padding-top: 0;

        @include media-breakpoint-up(lg) {
          padding-top: rem-calc(16);
        }
      }

      .date-time-selector-loader {
        fv-fa-icon {
          display: grid;
          height: rem-calc(68);

          color: var(--primary-color);
          font-size: rem-calc(32);
          place-items: center;
        }
      }

      &__apply {
        margin-top: rem-calc(16);
      }
    }
  }
}
