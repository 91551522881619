.button-login {
  position: relative;

  color: $color-text-main;
  font-size: $button-login-font-size;
  font-family: $button-login-font-family;
  text-transform: $button-login-text-transform;

  background-color: $button-login-background-color;

  border: $button-login-border;
  border-radius: $button-login-dimension-radius;

  &:hover {
    transform: translateY(rem-calc(-1));
  }

  &--facebook {
    color: $button-login-facebook-color;

    background: $button-login-facebook-background;
    border: $button-login-facebook-border;

    .button-login__icon {
      color: $button-login-facebook-icon-color;
    }
  }

  &--apple {
    .button-login__icon {
      color: $color-text-main;
    }
  }

  &--email {
    .button-login__icon {
      transform: translateY(-58%);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;

    display: inline-block;
    flex: 0 0 auto;
    width: rem-calc(20);
    height: rem-calc(20);
    object-fit: contain;

    font-size: rem-calc(18);

    transform: translateY(-50%);
    inset-inline-start: rem-calc(16);
  }

  &__label {
    flex: 1;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
