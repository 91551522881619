.section-header {
  position: fixed;
  top: 0;
  z-index: $z-index-top;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: $header-height-small;

  background-color: $header-color-background;
  box-shadow: $header-shadow;

  @include media-breakpoint-up(md) {
    height: $header-height-large;
  }

  &__referrer {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__referrer-link {
    @include main-link();
  }

  &__top-banner {
    display: none;
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: rem-calc(16);
    align-items: center;
    justify-content: center;
    width: 100%;

    border-bottom: $header-border-bottom;
  }

  &__col {
    &--left {
      width: 25%;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--center {
      width: 75%;
      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }

    &--center-left {
      width: 50%;

      text-align: center;
      @include media-breakpoint-up(lg) {
        width: 25%;

        text-align: left;
      }
    }

    &--right {
      width: 25%;
      @include media-breakpoint-up(lg) {
        width: 75%;
      }
    }
  }

  &--banner {
    @include media-breakpoint-up(lg) {
      height: calc($header-height-large + $top-banner-height);
    }
  }

  &--extra-menu {
    @include media-breakpoint-up(lg) {
      height: $header-height-with-subheader;
    }
  }

  &--hide-menu {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.main-logo {
  &__image {
    max-width: $header-logo-max-width-small;
    height: $header-logo-height-small;
    margin-bottom: rem-calc(0);

    @include media-breakpoint-up(md) {
      max-width: $header-logo-max-width-large;
      height: $header-logo-height-large;
    }
  }

  &__image-mobile {
    max-width: $header-logo-max-width-small;
    height: $header-logo-height-small;
    margin-bottom: rem-calc(0);
  }
}
