@import './order-summary';
@import './order-confirmation-sheet';

.reschedule {
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;

  &__container {
    background-color: #fff;
  }

  &__back {
    @include main-link();

    display: inline-block;

    margin-bottom: rem-calc(16);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(8);
    }
  }

  &__tip-message {
    margin-bottom: rem-calc(16);
  }

  &-ticket-selector {
    &__title {
      display: flex;

      padding: rem-calc(16);

      color: $ticket-selector-header-color-foreground;

      font: $font-h3;

      background-color: $ticket-selector-header-color-background;
      margin-block-end: 0;

      fv-fa-icon {
        margin-inline-end: rem-calc(8);
      }
    }
  }

  .page-header {
    &__title {
      margin-block-end: rem-calc(8);
    }

    &__subtitle {
      display: block;
      margin-block-end: rem-calc(24);
    }
  }
}
