.adaptive-plan-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: rem-calc(16);

  margin: 0;
  padding: 0;

  list-style: none;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--vertical {
    > :first-child {
      height: 100%;
    }
  }

  &--horizontal {
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr;
    }
  }
}

.plan-card {
  --fv-slideshow-image-height: 100%;
  --fv-slideshow-image-border-radius: 0;
  --fv-slideshow-arrow-background-left: none;
  --fv-slideshow-arrow-background-right: none;

  display: flex;
  flex-direction: column;

  height: 100%;

  overflow: hidden;

  color: $color-text-main;

  border: $plan-list-item-border;
  border-radius: $plan-list-item-dimensions-border-radius;

  &__gallery {
    position: relative;

    width: 100%;
    aspect-ratio: $plan-list-item-image-dimensions-aspect-ratio;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .slideshow {
      aspect-ratio: $plan-list-item-image-dimensions-aspect-ratio;
    }

    wl-plan-custom-label {
      position: absolute;
      top: rem-calc(12);
      left: rem-calc(12);
      z-index: 1;
    }
  }

  &__content {
    display: flex;

    flex: 1;

    flex-direction: column;
    gap: rem-calc(12);

    padding: rem-calc(16) 0;

    &__info {
      display: flex;

      flex: 1;

      flex-direction: column;
      gap: rem-calc(4);

      padding: 0 rem-calc(16);
    }

    &__buy {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      align-items: center;
      justify-content: space-between;

      padding: 0 rem-calc(16);

      button {
        width: 100%;
      }

      .plan-card__price {
        display: none;
      }
    }
  }

  &__venue {
    font: $font-body-small-regular;

    fv-fa-icon {
      color: $color-text-subtle;
      margin-inline-end: rem-calc(4);
    }
  }

  &__title {
    margin: 0;

    color: $color-text-main;
    font: $font-h3;
    text-wrap: pretty;
  }

  &__subtitle {
    margin-block-end: 0;

    color: $color-text-subtle;
    font: $font-body-base-regular;
  }

  &__rating {
    .plan-rating .average {
      font-weight: $font-weight-regular;
    }
  }

  &__date {
    color: $color-text-subtle;
    font: $font-body-small-regular;
  }

  &__static-info {
    display: none;
    width: 100%;

    font: $font-body-small-regular;

    fv-fa-icon {
      color: $color-text-positive;
      margin-inline-end: rem-calc(4);
    }
  }

  &__price {
    font: $font-body-base-bold;
  }

  &__price-from {
    font: $font-body-small-regular;
    margin-block-end: rem-calc(4);
  }

  &__price-strikethrough {
    text-decoration: line-through;
  }
}

.plan-card--vertical {
  @include media-breakpoint-up(sm) {
    height: 100%;
  }
}

.plan-card--horizontal {
  @include media-breakpoint-up(md) {
    flex-direction: row;

    height: rem-calc(256);

    .plan-card__gallery {
      width: rem-calc(256);
      aspect-ratio: $plan-list-item-image-dimensions-aspect-ratio;
    }

    .plan-card__content {
      flex-direction: row;
      gap: 0;
      padding: rem-calc(24) 0;

      &__info {
        justify-content: flex-start;

        padding: 0 rem-calc(24);

        border-right: $plan-list-item-border;

        .plan-card__price {
          display: none;
        }
      }

      &__buy {
        flex-direction: column;
        gap: rem-calc(10);

        align-items: flex-start;
        justify-content: flex-start;

        padding: 0 rem-calc(24);

        button {
          width: auto;
        }

        .plan-card__price {
          display: block;
        }
      }
    }

    .plan-card__title-container {
      margin-block-end: rem-calc(16);
    }

    .plan-card__title {
      font: $font-h3;
    }

    .plan-card__date {
      color: $color-text-main;
      font: $font-body-base-regular;
    }
  }

  @include media-breakpoint-up(lg) {
    .plan-card__content {
      &__info {
        margin-block-end: 0;
      }

      &__buy {
        button {
          width: rem-calc(300);
        }
      }
    }

    .plan-card__title {
      font: $font-h2;
    }

    .plan-card__static-info {
      display: flex;
      align-items: center;
    }
  }

  @include media-breakpoint-up(xl) {
    .plan-card__title {
      font: $font-h1;
    }
  }
}
