:root {
  --fv-bundle-upsell__plan-detail-sheet__cta-to-bundle-selection__padding: #{rem-calc(8)} 0 0;
}

.plan-detail-sheet__header {
  &__back {
    background-color: transparent;
    border: 0;
  }
}
