:root {
  --fv-slideshow-image-border-radius: 0;
  --fv-slideshow-border-radius: #{$border-radius-sm};
  --fv-slideshow-arrow-color: #{$white};

  --fv-slideshow-counter-color: #{$color-palette-neutral-white};
  --fv-slideshow-counter-background-color: #{$color-palette-neutral-700};

  --fv-slideshow-dot-indicator-position: absolute;
  --fv-slideshow-dot-indicator-position-bottom: #{rem-calc(8)};
  --fv-slideshow-dot-indicator-background-color: #{$white};
  --fv-slideshow-dot-indicator-active-width: #{rem-calc(16)};
  --fv-slideshow-dot-indicator-active-background-color: #{$white};
}

.slideshow {
  --fv-video-width: 100%;
  --fv-video-height: 100%;
  --fv-video-object-fit: cover;

  img {
    object-fit: cover;
  }
}
