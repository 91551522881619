.header-top-banner {
  display: flex;
  height: $top-banner-height;

  background-color: $top-banner-background-color;

  @include media-breakpoint-up(lg) {
    background-color: $top-banner-background-color-contrast;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: $top-banner-dimensions-max-width;
      padding-inline: rem-calc(16);
    }
  }

  &__text {
    flex: 1;
  }

  &__icon {
    margin-inline-end: rem-calc(8);
  }
}
