.orders-list {
  display: flex;
  flex-direction: column;
  gap: rem-calc(24);
  align-items: center;
  padding-inline: 0;
  margin-block-end: 0;

  list-style: none;

  &__wrapper {
    @include page-section-dimensions-padding-block-end;
    padding-block-start: $orders-list-dimensions-padding-block-start-sm;

    align-items: baseline;
    max-width: rem-calc(736);
    margin: auto;

    @include media-breakpoint-up(md) {
      padding-block-start: $orders-list-dimensions-padding-block-start-lg;
    }
  }

  &__tabs {
    margin-block-start: rem-calc(24);
    @include media-breakpoint-up(md) {
      margin-block-start: rem-calc(40);
    }

    .nav-tabs {
      flex-wrap: nowrap;

      border-bottom: $tabs-border-bottom;

      .nav-item {
        margin: 0;
      }

      .nav-link {
        color: $chip-filter-color;
        padding-inline: rem-calc(8);

        font-weight: normal;

        @include media-breakpoint-up(md) {
          font-size: rem-calc(16);
        }

        &.focus {
          color: $chip-filter-color;
          font-weight: normal;
        }

        &.active {
          font-weight: $font-weight-semi-bold;

          border-width: rem-calc(3);
        }
      }
    }
  }

  &__page-header {
    margin-block-end: rem-calc(16);
    @include media-breakpoint-up(md) {
      margin-block-end: rem-calc(40);
    }
  }

  &__aside {
    @include order-detail-help;
  }

  &__loading {
    margin: rem-calc(24) 0;

    text-align: center;
  }

  &__orders {
    display: flex;
    flex-flow: column;
    gap: rem-calc(16);
    padding: 0;
    margin-block-start: rem-calc(24);

    @include media-breakpoint-up(md) {
      gap: rem-calc(24);
      padding: 0;
    }
  }

  &__item {
    width: 100%;
  }

  &-empty {
    padding: rem-calc(16);

    text-align: center;

    border: solid rem-calc(1) $gray-400;
    border-radius: rem-calc(8);

    @include media-breakpoint-up(md) {
      padding: rem-calc(24);
    }

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: rem-calc(16);
      line-height: rem-calc(22);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
        line-height: rem-calc(24);
      }
    }

    &__subtitle {
      color: $color-palette-neutral-600;
      font-size: rem-calc(14);
      line-height: rem-calc(20);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
      }
    }

    &__cta {
      width: 100%;

      font-size: rem-calc(16);
      margin-inline: auto;
      margin-block-start: rem-calc(24);
    }
  }
}

.order-section {
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;

  @include media-breakpoint-up(md) {
    width: $order-card-width;
    margin: 0 auto;
  }

  .page-header {
    display: flex;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }
}

.horizontal-scroll {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  * {
    @include shrink;
    white-space: nowrap;
    scroll-snap-align: center;
  }
}
