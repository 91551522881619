.filter-sheet {
  &__item {
    padding-block-end: rem-calc(8);

    cursor: pointer;
  }

  &__separator {
    margin: rem-calc(6) 0;

    border-top: rem-calc(1) solid $color-border-main;
  }
}
