.plan-locked-modal {
  .modal__close {
    position: absolute;
    inset-block-start: rem-calc(16);
    inset-inline-end: rem-calc(24);
  }

  .fv-modal__header-title {
    margin: 0;

    font: $font-h2;
    padding-inline-end: rem-calc(32);
  }

  .fv-modal__content {
    padding-block-start: rem-calc(16);

    .form__input {
      margin-block-start: rem-calc(8);
    }
  }

  .fv-modal__footer {
    display: flex;
    justify-content: center;
  }
}
