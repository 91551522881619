:root {
  --fv-bundle-upsell__plan-list-item__title-font: #{$font-body-small-bold};
  --fv-bundle-upsell__plan-list-item__title-decoration: underline;
  --fv-bundle-upsell__plan-list-item__title-line-clamp: 2;

  --fv-bundle-upsell__plan-list-item__rating-font: #{$font-body-small-regular};
  --fv-bundle-upsell__plan-list-item__rating-color: #{$color-background-warning-base};

  --fv-bundle-upsell__plan-list-item__price-font: #{$font-body-small-regular};
}

.bundle-upsell-plan-list-item {
  background-color: transparent;
  border: 0;
}
