.plans-list-item {
  --fv-slideshow-image-height: 100%;

  @include transition;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  border: $plan-list-item-border;

  border-radius: $plan-list-item-dimensions-border-radius;
  box-shadow: $plan-list-item-effect-box-shadow;

  &:hover {
    box-shadow: $plan-list-item-effect-box-shadow-hover;
  }

  &__link {
    display: flex;
    flex-flow: column;
    flex-grow: 1;

    cursor: pointer;
  }

  &__figure {
    position: relative;

    overflow: hidden;

    transition: transform 0.5s ease-in-out;
    aspect-ratio: $plan-list-item-image-dimensions-aspect-ratio;

    &:hover {
      img {
        transform: scale(1.08);
      }
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    object-position: center;

    transition: all 0.9s ease-out;
    aspect-ratio: $plan-list-item-image-dimensions-aspect-ratio;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: $plan-list-item-info-dimensions-gap;
    padding: $plan-list-item-info-dimensions-padding-small;

    @include media-breakpoint-up(lg) {
      padding: $plan-list-item-info-dimensions-padding-large;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    width: 100%;
  }

  &__place {
    color: $plan-list-item-info-place-color;
    font: $font-body-small-regular;
  }

  &__title {
    margin: 0;
    overflow: hidden;

    color: $color-text-main;
    font: $plan-list-item-title-font-small;
    text-wrap: pretty;

    @include media-breakpoint-up(lg) {
      font: $plan-list-item-title-font-large;
    }
  }

  &__subtitle {
    margin: 0;

    color: $color-text-subtle;
    font: $font-body-base-regular;
  }

  &__date {
    color: $color-text-subtle;
    font: $font-body-base-regular;
  }

  &__date-icon {
    margin-inline-end: rem-calc(4);
  }

  &__price,
  &__price-link {
    width: 100%;

    color: $color-text-main;
    font: $font-body-base-bold;
    text-align: end;
    margin-block-start: auto;
  }

  &__price-link {
    font: $font-body-small-bold;
    text-decoration: underline;
  }

  &__price-from {
    color: $color-text-subtle;
    font: $font-body-small-regular;
    margin-block-end: rem-calc(4);
  }

  &__price-strikethrough {
    color: $color-text-subtle;

    font-size: $font-size-sm;
    text-decoration: line-through;
  }
}
