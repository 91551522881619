.order-detail-section {
  @include page-section-dimensions-padding-block-start;

  &__back {
    display: inline-block;

    color: $color-action-text-primary;

    cursor: pointer;
  }
}

.order-detail-qr-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  column-gap: $order-tickets-qr-list-gap-x;
  row-gap: $order-tickets-qr-list-gap-y;
  margin-block-start: rem-calc(48);
}

.qr-card {
  position: relative;

  width: $ticket-qr-card-width;

  color: $ticket-qr-card-color-text;

  background-color: $ticket-qr-card-color-background;
  border: rem-calc(1) solid $color-border-main;
  border-radius: $ticket-qr-card-border-radius;

  &__header {
    position: relative;

    padding: $ticket-qr-card-header-padding;

    text-align: center;
    border-block-end: rem-calc(1) dashed $color-border-main;

    &::before,
    &::after {
      position: absolute;
      bottom: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(0.5)});

      width: calc(#{$ticket-qr-card-insets-radius} * 2);
      height: calc(#{$ticket-qr-card-insets-radius} * 2);

      background-color: $color-background-main;
      border: rem-calc(1) solid $color-border-main;
      border-bottom-color: $color-background-main;
      border-left-color: $color-background-main;
      border-radius: rem-calc(64);

      content: '';
    }

    &::before {
      left: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(1)});

      transform: rotate(45deg);
    }

    &::after {
      right: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(1)});

      transform: rotate(-135deg);
    }
  }

  &__title,
  &__subtitle {
    font: $font-h4;
    line-height: rem-calc(22);
    margin-block-end: 0;
  }

  &__subtitle {
    font-weight: $font-weight-regular;
  }

  &__figure {
    margin: $ticket-qr-card-figure-margin;

    text-align: center;
  }

  &__img {
    max-width: $ticket-qr-card-figure-maxwidth;
  }

  &__figcaption {
    font: $font-body-small-regular;
  }

  &__print-button {
    position: absolute;
    top: rem-calc(4);
    right: rem-calc(4);

    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(44);
    height: rem-calc(44);

    color: $color-action-text-primary;

    cursor: pointer;

    fv-fa-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(32);
      height: rem-calc(32);

      border: rem-calc(1) solid $color-action-border-primary;
      border-radius: rem-calc(64);
    }

    &:hover {
      color: $color-action-text-primary-hover;

      fv-fa-icon {
        border-color: $color-action-border-primary-hover;
      }
    }
  }
}

.ticket-avatar {
  --size: #{rem-calc(72)};
  position: absolute;
  top: calc(var(--size) / -2);
  left: calc(var(--size) / -1.5);

  display: grid;
  width: var(--size);
  height: var(--size);

  background-color: $color-action-background-primary;
  border-radius: 100%;
  place-items: center;

  &:not(&--disabled) {
    cursor: pointer;
  }

  &--has-image {
    --size: #{rem-calc(96)};
  }

  input {
    display: none;
  }

  svg {
    width: rem-calc(40);
    height: rem-calc(40);

    color: $color-action-background-primary-contrast;

    transition: transform 0.1s ease-out;
  }

  &:hover svg {
    transform: scale(1.065);
  }

  img {
    position: absolute;

    width: rem-calc(88);
    height: rem-calc(88);
    object-fit: cover;

    border-radius: 100%;

    transition: opacity 0.2s ease-out;
  }

  &:not(&--disabled):hover img {
    opacity: 0;
  }
}
