fv-add-ons-list {
  gap: rem-calc(16);
}

fv-add-on-control-stepper,
wl-plan-addons-step {
  .add-on-control-stepper {
    gap: rem-calc(8);
  }
}

fv-add-on,
wl-plan-addons-step {
  .add-on {
    padding: rem-calc(12 12);

    background-color: $add-on-color-background;
    border: $add-on-border;
    border-radius: $box-wrapper-dimensions-border-radius;

    &__icon-container:not(:empty) {
      color: $add-on-icon-color;
    }

    &__label {
      margin-bottom: rem-calc(8);

      color: $add-on-label-text-color;

      font-weight: $add-on-label-font-weight;
      font-size: $font-size-base;
      line-height: rem-calc(24);
    }

    &__description {
      margin-bottom: rem-calc(8);

      color: $add-on-description-text-color;
      font-size: $font-size-sm;
      line-height: rem-calc(20);
    }

    &__sold-out {
      gap: rem-calc(8);

      &__badge {
        padding: rem-calc(2 8);

        color: $color-palette-neutral-white;
        font-weight: 700;
        font-size: rem-calc(10);
        line-height: rem-calc(16);
        text-transform: uppercase;

        background-color: $color-background-danger-base;
        border-radius: $border-radius-xs;
      }
    }

    &__rule-error {
      margin-top: rem-calc(8);
      margin-bottom: 0;

      color: $color-action-text-danger;
      font: $font-body-small-regular;
      text-align: start;
    }
  }

  .add-on-control-toggle {
    color: $add-on-control-toggle-text-color;
  }

  .add-on-control-stepper {
    color: $add-on-control-stepper-text-color;
  }
}

fv-sheet-content {
  .add-on {
    display: flex;
    gap: rem-calc(16);

    background-color: $sheet-color-background;
    border: $border-style;
    border-radius: $wl-border-radius;

    &__icon-container:not(:empty) {
      display: none;
    }

    &__rule-error {
      max-width: rem-calc(500);
    }

    &__label {
      margin-bottom: rem-calc(4);
    }

    &__description {
      margin-bottom: rem-calc(16);
      @include media-breakpoint-up(md) {
        max-width: rem-calc(500);
        padding-right: rem-calc(6);
      }
    }
  }

  .add-on--info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  fv-add-on-control {
    margin-top: auto;
  }

  .toggle {
    display: inline;
  }

  .add-on-control-stepper,
  .toggle__label {
    display: flex;
    gap: rem-calc(16);
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    font-size: rem-calc(14);

    strong {
      font-weight: $font-weight-regular;
    }
  }

  .session-multi-selection-amount-selector__number {
    height: auto;
  }
}

.plan-add-ons-sheet {
  &__title {
    font-weight: $font-weight-semi-bold;
  }

  &__heading {
    margin-block-end: rem-calc(16);

    &-title {
      font-weight: $font-weight-semi-bold;
      font-size: rem-calc(18);
      margin-block-end: rem-calc(8);
    }

    &-subtitle {
      margin: 0;

      font-size: rem-calc(14);
    }
  }
}

.add-on--image {
  img {
    @include media-breakpoint-up(md) {
      width: rem-calc(128);
    }
    width: rem-calc(80);

    border-radius: $border-radius-xs;
  }
}

.cart-edit-sheet__container .add-on {
  background-color: var(--add-on-color-background);
  border: var(--add-on-border, none);
  border-radius: var(--box-wrapper-dimensions-border-radius, var(--wl-border-radius, rem-calc(8)));
}
