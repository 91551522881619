.phone-number-update-success {
  display: flex;
  flex-direction: column;
  gap: rem-calc(12);
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem-calc(2);
    align-items: center;

    fv-fa-icon {
      color: #24a865;
    }

    &--title {
      margin-top: rem-calc(6);

      font-weight: #{$font-weight-bold};
      font-size: #{$font-size-h3};
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: rem-calc(24 0);
  }
}
