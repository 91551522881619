.plan-addons-step {
  &__list {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
    margin-bottom: rem-calc(16);
  }

  &__selector {
    padding: 0 rem-calc(4);

    color: var(--primary-color);
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14);

    text-decoration: underline;

    background-color: transparent;
    border: 0;

    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }

  &__success {
    display: inline !important;

    color: var(--secondary-color);
    font-weight: $font-weight-semi-bold;
  }
}
