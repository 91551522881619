.section-404 {
  background: $section-404-color-background;

  &__subtitle {
    margin: rem-calc(48) auto;

    color: $section-404-color-foreground;
    font: $font-h3;

    text-align: center;
  }
}
