.checkout__links-container {
  width: 100%;

  padding: rem-calc(16) 0;

  border-top: $border-style;

  @include media-breakpoint-up(lg) {
    border-top: 0;
  }

  .checkout__link {
    display: block;

    padding: rem-calc(4) 0;

    color: $color-action-text-primary;

    font-size: rem-calc(14);
    line-height: 1.6;

    &:hover {
      color: $color-action-text-primary-hover;
    }
  }
}
