:root {
  --fv-otp-input-border: #{rem-calc(2) solid $color-palette-neutral-200};
  --fv-otp-input-border-radius: #{rem-calc(8)};
  --fv-otp-input-focus-border-color: #{$color-border-primary-base};
  --fv-otp-input-focus-box-shadow: #{$box-shadow-medium};
}

.otp-input:focus {
  outline: none;
}
