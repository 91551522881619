.reschedule-order-summary {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);

  width: 100%;

  padding: rem-calc(16);

  color: $color-palette-neutral-black;

  background-color: $color-palette-neutral-white;
  border: $border-style;
  border-radius: $wl-border-radius;

  .collapsible {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;

      &--opened {
        margin-bottom: rem-calc(8);
      }
    }

    &__content {
      margin-block-start: rem-calc(16);
    }

    &__title {
      margin: 0;

      font: $font-body-small-bold;
    }

    &__show-button {
      background: none;
      border: 0;

      appearance: none;

      &--opened {
        transform: rotate(-180deg);
      }
    }
  }

  .checkout-summary {
    &__plan-info {
      align-items: center;

      .plan-image {
        width: rem-calc(86);
        height: rem-calc(86);
      }

      h1 {
        margin-block-end: rem-calc(6);
      }

      h2 {
        margin-block-end: rem-calc(3);
      }
    }
  }
}
