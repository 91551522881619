.subheader {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__city {
    margin: 0;

    font-size: rem-calc(30);
    font-family: $font-stack-tertiary;
    font-style: italic;

    a {
      color: $navbar-action-menu-link;
      text-decoration: none;
    }
  }

  &__list {
    display: flex;
    gap: rem-calc(20);
    align-items: center;
    margin: 0;
    padding: 0;

    list-style: none;

    &--item {
      color: $black;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-body-small;
      letter-spacing: 0;
      text-transform: uppercase;
      text-decoration: none;

      cursor: pointer;

      &:hover {
        font-weight: $font-weight-bold;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  &--navbar {
    display: flex;
    flex-direction: column;

    .subheader__list {
      flex-direction: column;
      gap: rem-calc(8);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
