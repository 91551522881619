.plan-custom-label {
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: rem-calc(2 6);

  font: $font-body-small-regular;
  font-weight: $font-weight-bold;
  font-size: rem-calc(10);
  white-space: nowrap;

  border-radius: rem-calc(4);

  &.urgency {
    color: $plan-detail-urgency-custom-label-text;

    background-color: $plan-detail-urgency-custom-label-bg;
  }
}
