.cart-container-sheet {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;

  wl-cart-summary-sheet,
  wl-cart-empty-sheet,
  wl-cart-remove-sheet,
  wl-cart-edit-sheet,
  wl-cart-banner-sheet {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.cart-summary-sheet {
  &__expired {
    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-base;
      margin-block-end: 0;
    }

    &__message {
      color: $color-text-danger;

      font-size: rem-calc(14);
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    gap: rem-calc(12);
    padding-block-end: rem-calc(16);

    border-bottom: solid rem-calc(1) var(--color-palette-neutral-200, $gray-200);
  }

  &__picture {
    img {
      width: rem-calc(64);
      height: rem-calc(64);

      border-radius: rem-calc(4);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    gap: rem-calc(12);
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-base;
      margin-block-end: rem-calc(4);
    }

    &__date {
      margin-block-end: rem-calc(8);

      color: var(--color-palette-neutral-600, $gray-600);
      font-size: rem-calc(14);
    }
  }

  &__actions {
    display: flex;
    gap: rem-calc(12);
    align-items: flex-start;
    justify-content: space-between;
  }

  &__action {
    width: rem-calc(24);
    padding: 0;

    color: var(--primary-color);

    background-color: transparent;
    border: 0;
    outline: none;
  }

  &__session-and-price {
    display: flex;
    gap: rem-calc(12);
    justify-content: space-between;
  }

  &__expired__message + &__session-and-price {
    margin-top: rem-calc(16);
  }

  &__session {
    margin-block-start: 0;
    margin-block-end: 0;

    font-size: rem-calc(14);

    &--with-taxes {
      margin-block-end: rem-calc(28);
    }
  }

  &__price-and-taxes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__price {
    margin-block-start: 0;
    margin-block-end: 0;

    font-size: rem-calc(14);
  }

  &__taxes {
    color: var(--color-palette-neutral-600, $gray-600);
    font-size: rem-calc(12);
    text-align: end;
  }

  &__basket {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }

    &__price-and-expand {
      @include media-breakpoint-up(lg) {
        text-align: end;
        margin-inline-end: 0;
      }
    }

    &__price {
      font-weight: $font-weight-semi-bold;
      margin-block-end: rem-calc(4);

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        margin-block-end: rem-calc(16);
      }

      span {
        display: none;

        font-weight: normal;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }

    &__expand {
      color: var(--primary-color);

      cursor: pointer;

      @include media-breakpoint-up(md) {
        display: none;
      }

      fv-fa-icon {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    .button {
      border: solid rem-calc(2) var(--color-border-main);
      border-radius: rem-calc(8);
    }
  }

  &__banner {
    display: flex;
    gap: rem-calc(12);
    align-items: center;
    margin-top: rem-calc(16);
    padding: rem-calc(12) rem-calc(16);

    font-size: rem-calc(14);

    background-color: #f8f5f1;
    border-radius: rem-calc(8);

    &__icon {
      color: #bea074;
      font-size: rem-calc(16);
    }

    &__link {
      text-decoration: underline;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cart-empty-sheet {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem-calc(0 16);
  }

  &__icon {
    margin-block-end: rem-calc(16);

    color: var(--secondary-color);
    font-size: rem-calc(38);
  }

  &__text {
    margin-block-end: rem-calc(16);
  }
}

.cart-remove-sheet {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem-calc(16);
  }

  &__icon {
    color: var(--color-palette-danger-500, $color-text-danger);
    font-size: rem-calc(48);
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(20);
    text-align: center;
  }

  &__text {
    margin-bottom: rem-calc(16);
  }
}

.cart-edit-sheet {
  &__title {
    padding: 0 var(--fv-sheet-content-padding);
  }

  &--no-addons {
    fv-add-ons-list {
      display: none;
    }
  }

  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: rem-calc(385);

    fv-fa-icon {
      font-size: rem-calc(48);
    }

    p {
      margin: rem-calc(48 0 0 0);

      font-weight: $font-weight-semi-bold;
      font-size: rem-calc(20);
    }
  }

  &__container {
    .session-multi-selection-amount-selector__number {
      height: rem-calc(44);
    }

    .auto-selected-date,
    .calendar {
      padding-top: 0;
    }
  }
}

.cart-banner-sheet {
  font-size: rem-calc(14);

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
