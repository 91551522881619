.gdpr {
  &__title {
    font: $font-h4;
    font-family: $font-stack;
    line-height: 1.4;
    margin-block-end: rem-calc(8);
  }

  &__link {
    @include regular-link($color-text-subtle);
  }

  &__cta {
    @include button(100%, 100%);

    margin: rem-calc(32 0 0);
  }

  &__term {
    width: 100%;

    font-size: rem-calc(14);
    line-height: 1.4;
  }

  &__term-description {
    margin-block-end: rem-calc(8);
  }

  .form__label {
    font-size: rem-calc(14);
    margin-block-end: rem-calc(4);
  }

  .form__input {
    height: rem-calc(40);

    border-radius: rem-calc(4);
  }

  .form__row:not(:last-child) {
    margin-block-end: rem-calc(12);
  }

  &__accept-consequences {
    color: $color-text-subtle;
    font: $font-caption-regular;
    padding-block-start: rem-calc(16);

    a {
      color: $color-text-subtle;
    }
  }

  @include media-breakpoint-up(md) {
    .form__label-check input {
      margin-block-start: 0;
    }
  }
}
