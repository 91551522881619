.phone-number-register-component {
  form {
    width: 100%;
  }

  &__input {
    &-text {
      margin-right: rem-calc(10);
      overflow: hidden;

      font-size: rem-calc(16);
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-input {
      position: relative;

      display: inline-block;
      width: 100%;

      input {
        display: flex;
        width: 100%;
        height: rem-calc(48);

        font-size: rem-calc(16);

        background-color: #fff;
        border: rem-calc(1) solid $color-palette-neutral-200;
        border-radius: rem-calc(4);
      }

      .iti--container {
        top: 3rem !important;
        left: 0 !important;

        width: 100%;

        .iti__country-list {
          width: 100%;
        }
      }
    }

    &-error {
      color: $color-text-danger;
      font-weight: $font-weight-bold;
      font-size: rem-calc(10);
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    margin: rem-calc(24 0);
  }
}
