.plan-description {
  a {
    color: $color-action-text-primary;
  }

  &--collapsed {
    /* stylelint-disable */
    display: -webkit-box;
    /* stylelint-enable */
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: $plan-description-line-clamp-mobile;
    line-clamp: $plan-description-line-clamp-mobile;
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    /* stylelint-enable */

    @include media-breakpoint-up(lg) {
      -webkit-line-clamp: $plan-description-line-clamp-desktop;
      line-clamp: $plan-description-line-clamp-desktop;
    }

    /*  iOS fix for intermixed lines */
    &.plan-description--safari {
      p,
      span,
      div,
      label,
      ul,
      ol,
      li,
      a {
        display: inline;

        &::after {
          white-space: pre;

          content: ' \A\A';
        }
      }
    }

    /*  iOS fix to not display links at the end of the content */
    &::after {
      content: '';
    }
  }

  &__toggle {
    padding: 0;

    color: $color-text-main;

    font: $font-body-base-bold;

    text-decoration: underline;

    background: none;

    border: transparent;

    cursor: pointer;

    margin-block-start: rem-calc(8);
  }
}
