.tree-taxonomies-filter {
  &__list {
    display: flex;
    gap: rem-calc(10);
    margin: 0;
    padding: 0;

    list-style: none;

    &--chips {
      margin: auto;
      padding: 0 rem-calc(16);
    }
  }

  &__divider {
    border-bottom: rem-calc(1) solid $color-border-main;

    + * {
      // if divider has any sibling next item
      margin-top: rem-calc(24);
    }
  }

  .tab {
    padding: 0 rem-calc(8);

    background-color: transparent;
    border: 0;

    a {
      color: $chip-filter-color;
    }

    &.tab--active {
      font-weight: $font-weight-semi-bold;

      border-bottom: rem-calc(2) solid $color-action-border-primary;

      a {
        color: $primary-color;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .chip {
    display: block;
    width: $chip-filter-dimensions-width;
    padding: rem-calc(8) rem-calc(16);

    font: $chip-filter-font;
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14);

    background-color: transparent;

    border: $chip-filter-border;
    border-radius: $border-radius-pill;

    a {
      color: $chip-filter-color;
    }

    &.chip--active {
      background-color: var(--primary-color-light);
      border-color: var(--primary-color-light);

      a {
        color: $primary-color;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .horizontal-scroll {
    padding-inline-start: 1rem;

    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    scroll-snap-type: x mandatory;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    * {
      @include shrink;
      white-space: nowrap;
      scroll-snap-align: center;
    }
  }
}
