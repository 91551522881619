.categories-selector {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__toggle {
    position: relative;

    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: rem-calc(8);
    align-items: center;
    justify-items: start;
    width: 100%;
    margin: auto;
    padding: rem-calc(16 24);

    color: var(--black);
    font-size: rem-calc(16);

    background: var(--white);
    border: unset;

    &::before {
      position: absolute;
      top: 0;
      left: rem-calc(24);

      width: 90%;
      height: rem-calc(1);

      border-top: rem-calc(1) solid var(--border);

      content: '';
    }

    &__list-icon {
      font-size: rem-calc(16);
    }

    &__arrow-icon {
      font-size: rem-calc(16);
    }
  }

  &__collapsible {
    position: relative;
    z-index: 1;

    padding-bottom: rem-calc(16);

    background: var(--white);

    transition: $transition-collapse;
  }

  &__list {
    padding: rem-calc(0 16);
    overflow: auto;
  }
}

.categories {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    display: contents;

    &:last-child {
      .category {
        margin-block-end: 0;
      }
    }
  }
}

.category {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: rem-calc(8);
  padding: rem-calc(16);

  background: var(--white);
  border: var(--border-style);
  border-radius: $category-filter-dimension-radius;

  &-selected {
    border: rem-calc(1) solid var(--primary-color);

    .category__icon {
      color: var(--primary-color);
    }
  }

  &__icon {
    width: rem-calc(28);

    color: var(--gray-500);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    margin-left: rem-calc(8);

    font-size: rem-calc(16);
    line-height: rem-calc(20);

    &__label {
      color: var(--gray-800);
      font-weight: bold;
    }

    &__price {
      color: var(--gray-600);
    }
  }
}

.spinner__container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: rem-calc(100);
}
