.purchase-progress {
  display: flex;
  align-items: center;
  height: $header-height-small;

  color: $button-color-foreground-primary;
  font-weight: $header-text-font-weight;

  background-color: $header-color-background;
  border-bottom: $header-border-bottom;
  box-shadow: $header-shadow;
  padding-inline: 1rem;

  @include media-breakpoint-up(lg) {
    height: auto;

    background-color: unset;
    border-bottom: unset;
    box-shadow: none;
    padding-inline: 0;
    padding-block-start: $purchase-progress-dimensions-padding-block-start;
  }

  &__title {
    color: $navbar-action-color-text-primary;
    font-weight: $font-weight-semi-bold;
    font-size: $navbar-font-size;

    @include media-breakpoint-down(lg) {
      flex-grow: 1;
      margin-left: rem-calc(-26); /* compensate button size */

      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      color: $color-text-main;
      font: $font-h2;
    }
  }

  &__back {
    z-index: $z-index-med;

    padding-left: 0;

    color: $navbar-action-color-text-primary;
    font-size: $navbar-font-size;

    background-color: transparent;
    border: 0;
    @include media-breakpoint-up(lg) {
      color: $color-text-main;
      font-size: rem-calc(20);
    }
  }

  &--post-bq {
    justify-content: center;
    margin-bottom: rem-calc(8);
  }

  &__success-icon {
    color: $color-palette-positive-500;
  }
}
