.banner {
  position: relative;

  display: flex;
  flex-direction: column;

  align-items: center;
  padding: rem-calc(16);

  color: $banner-color-text;

  background-color: $banner-color-background;
  border: $banner-border;
  border-radius: $banner-border-radius;

  margin-block-end: rem-calc(24);

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    gap: rem-calc(16);
  }

  &__message {
    flex: 1 1 0;
  }

  &__icon {
    flex: 0 0 auto;

    color: $banner-icon-color;
    font-size: $banner-icon-size;
  }

  &__button {
    width: 100%;

    margin-top: rem-calc(16);

    color: $banner-button-color;

    background-color: $banner-button-background;
    border: $banner-button-border;

    @include media-breakpoint-up(sm) {
      width: inherit;
      margin-top: rem-calc(0);
    }

    &:hover {
      color: $banner-button-color-hover;

      background-color: $banner-button-background-hover;
    }
  }
}
