:root {
  --fv-user-photo-gallery-padding-inline-start: #{$plan-user-photo-gallery-padding-inline-start};
  --fv-user-photo-gallery-padding-block-end: #{$plan-user-photo-gallery-padding-block-end};
  --fv-user-photo-gallery-gap: #{$plan-user-photo-gallery-gap};
  --fv-user-photo-gallery-width-mobile: #{$plan-user-photo-gallery-width-mobile};
  --fv-user-photo-gallery-aspect-ratio: #{$plan-user-photo-gallery-aspect-ratio};
  --fv-user-photo-gallery-border-radius: #{$plan-user-photo-gallery-border-radius};
  --fv-user-photo-gallery-width-desktop: #{$plan-user-photo-gallery-width-desktop};

  @include media-breakpoint-up(md) {
    --plan-user-photo-gallery-gap: #{rem-calc(16)};
  }
}
