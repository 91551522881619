.cart-section {
  padding-block-end: rem-calc(72);

  @include media-breakpoint-up(lg) {
    padding-block-end: $page-section-dimensions-padding-block-end-lg;
  }

  &__progress-and-banner {
    display: flex;
    flex-direction: column;
    margin-block-end: rem-calc(16);

    @include media-breakpoint-up(lg) {
      flex-direction: column-reverse;
    }
  }

  &__wrapper {
    .page__aside {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        width: 55%;
      }
    }

    .reschedule-price {
      display: flex;
      justify-content: space-between;
      padding: rem-calc(16);

      color: $color-palette-neutral-black;
      font: $font-h4;

      background-color: $color-palette-neutral-white;
      border: $border-style;
      border-radius: $wl-border-radius;

      &__amount {
        color: $color-action-text-primary;
      }
    }
  }

  &__detail-list {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
  }

  &__subtitle {
    color: $subtitle-text-color;
    font: $font-body-small-regular;
  }

  &__booking-questions {
    &--post {
      @include media-breakpoint-up(lg) {
        flex-direction: column;
        max-width: $order-card-width;
        margin: auto;

        .page__aside {
          width: 100%;
        }

        .cart-section__subtitle {
          display: none;
        }
      }
    }
  }
}
