.fv-box-review {
  &__textarea {
    width: 100%;
    margin-bottom: rem-calc(16);
    padding: rem-calc(16);

    color: $ticket-review-review-question-textarea-color;

    background-color: $ticket-review-review-question-textarea-color-background;
    border: $ticket-review-review-question-textarea-border;
    border-radius: $ticket-review-review-question-textarea-border-radius;
    box-shadow: $ticket-review-review-question-textarea-box-shadow;

    &::placeholder {
      color: $ticket-review-review-question-textarea-color-placeholder;
    }

    &:focus-visible {
      outline-color: $ticket-review-review-question-textarea-color-focus;
    }
  }
}
