.checkout-summary {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);

  .box {
    padding: rem-calc(16);

    color: $color-palette-neutral-black;

    background-color: $color-palette-neutral-white;

    border: $border-style;
    border-radius: $wl-border-radius;
  }

  &__plan {
    &-info {
      display: flex;
      gap: rem-calc(8);
      align-items: flex-start;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        &--opened {
          margin-bottom: rem-calc(8);
        }
      }
    }

    &-title {
      margin-block-end: rem-calc(4);

      font: $font-h5;
      @include media-breakpoint-up(lg) {
        margin-block-end: rem-calc(8);

        font: $font-h3;
      }
    }

    &-image {
      width: rem-calc(64);
      height: rem-calc(64);

      object-fit: cover;

      border-radius: $wl-border-radius;

      @include media-breakpoint-up(lg) {
        width: rem-calc(80);
        height: rem-calc(80);
      }
    }
  }

  &__extra-info {
    margin-block-end: rem-calc(2);

    font: $font-caption-regular;

    @include media-breakpoint-up(lg) {
      margin-block-end: rem-calc(6);

      font: $font-body-small-regular;
    }

    &:last-of-type {
      margin-block-end: 0;
    }
  }

  &__info {
    @include flex-column;
  }

  &__breakdown {
    &-item {
      font: $font-body-small-regular;
      @include media-breakpoint-up(lg) {
        font: $font-body-base-regular;
      }

      .price {
        display: flex;
        flex: 1;

        gap: rem-calc(8);
        justify-content: space-between;

        &__amount {
          white-space: nowrap;
          text-align: right;
        }
      }

      .subtle {
        margin: 0;

        color: $color-palette-neutral-500;

        font-weight: $font-weight-regular;

        font-size: rem-calc(14);
      }

      .strikethrough {
        text-decoration: line-through;
        margin-inline-end: rem-calc(4);
      }

      .icon {
        cursor: pointer;
      }
    }

    &-separator {
      margin: rem-calc(12) 0;

      color: $color-border-main;

      opacity: 0.5;

      @include media-breakpoint-up(lg) {
        margin: rem-calc(16) 0;
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: rem-calc(8);
    }

    &-total {
      .badges {
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(8);
        align-items: center;
        margin-top: rem-calc(12);

        .plan-banner {
          width: fit-content;
          min-height: auto;
          margin: 0;
          padding: rem-calc(4 12);

          border-radius: rem-calc(8);
        }

        &__reservation-discount-applied {
          align-content: center;
          height: rem-calc(32);

          margin: rem-calc(8 8 0 0);
          padding: rem-calc(4 12);

          color: $plan-banner-text-color;

          font-size: rem-calc(14);
          line-height: rem-calc(20);
          word-wrap: break-word;

          background-color: $color-palette-positive-100;
          border-radius: $border-radius-sm;

          &-icon {
            margin-right: rem-calc(4);
          }
        }
      }

      .total {
        display: flex;

        flex: 1 0 100%;
        align-items: center;
        justify-content: space-between;

        font: $font-h5;
        @include media-breakpoint-up(lg) {
          font: $font-h3;
        }
      }

      .booking-questions {
        margin-block-start: rem-calc(16);
      }
    }
  }
}
