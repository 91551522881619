.order-card {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  width: 100%;

  background-color: $order-card-color-background;
  border: $order-card-border;

  &__header {
    display: flex;
    flex-flow: row;
    gap: rem-calc(16);
    @include media-breakpoint-up(lg) {
      gap: rem-calc(24);
    }
  }

  &__figure {
    margin: 0;

    text-align: center;
  }

  &__image {
    width: 100%;
    max-width: rem-calc(72);
    object-fit: cover;

    border-radius: $order-card-image-dimensions-radius;
    aspect-ratio: 1/1;

    @include media-breakpoint-up(lg) {
      max-width: rem-calc(116);
    }
  }

  &__header-info {
    display: flex;
    flex-flow: column;

    &-badge {
      width: fit-content;
      padding: rem-calc(2 4);

      color: $color-palette-neutral-white;

      font-size: $font-size-caption;

      background-color: $color-background-positive-base;
      border-radius: $border-radius-xs;
    }
  }

  &__info {
    flex: 1 1 auto;
  }

  &__title {
    margin-block-end: rem-calc(4);

    color: $color-text-main;
    font: $font-h4;

    cursor: pointer;

    @include media-breakpoint-up(lg) {
      font: $font-h2;
    }
  }

  &__id {
    display: block;

    color: $orders-item-id-text-color;
    font: $font-body-base-regular;
  }

  &__info-status {
    &-buttons {
      gap: rem-calc(1rem);

      .button {
        &--disabled {
          background-color: var(--color-border-main);
          opacity: 0.3;

          pointer-events: none;
        }

        .order-card__validate--mobile,
        .order-card__validate--desktop {
          display: none;
        }

        .order-card__validate--mobile {
          @include media-breakpoint-down(lg) {
            display: block;
          }
        }

        .order-card__validate--desktop {
          @include media-breakpoint-up(lg) {
            display: block;
          }
        }
      }
    }

    &-message {
      display: flex;
      gap: rem-calc(4);
      justify-content: center;
      margin-top: rem-calc(8);

      color: $color-success;
      font-size: $font-size-body-small;
    }
  }
}

.oder-card-details-list {
  padding-inline: 0;
  padding-block-start: rem-calc(16);

  list-style: none;

  &__item {
    display: flex;
    flex-wrap: nowrap;
    gap: rem-calc(4);
    align-items: baseline;
    border-block-end: rem-calc(1) dashed $color-border-main;
    padding-block-end: rem-calc(12);
    margin-block-end: rem-calc(12);

    @include media-breakpoint-up(lg) {
      padding-block-end: rem-calc(16);
      margin-block-end: rem-calc(16);
    }

    &--price {
      justify-content: space-between;
    }

    &--subtotal {
      display: grid;
      grid-auto-flow: column;
      grid-gap: rem-calc(8);
      grid-template-columns: $order-card-item-price-columns;
      justify-content: flex-start;

      &-price {
        display: flex;
        gap: rem-calc(8);
      }
    }

    &--seats {
      display: block;
    }

    &--indetation {
      padding-inline-start: rem-calc(16);
    }

    &--no-border {
      margin: 0;

      border: 0;
    }

    strong {
      color: $order-card-item-price-final-color;
      font-weight: $order-card-item-price-final-font-weight;
      font-size: $order-card-item-price-final-font-size;
    }
  }

  &__icon {
    flex: 0 0 auto;
  }
}

.oder-card-details-total-price {
  display: flex;
  align-items: baseline;
  justify-content: $order-card-total-price-justify;
  margin-block-end: rem-calc(16);

  border-radius: rem-calc(4);

  &__label {
    grid-area: label;

    font-weight: bold;

    font-size: $order-card-total-price-label-font-size;
    font-family: $font-stack;
  }

  &__amount {
    display: flex;
    gap: rem-calc(8);
    align-items: baseline;
  }

  &__taxes {
    grid-area: taxes;

    color: $color-action-text-disable;
    font: $font-body-small-regular;
  }

  &__discount {
    grid-area: strikethrough;

    color: $order-card-total-price-strikethrough-color;
    font-size: $order-card-total-price-strikethrough-font-size;
    text-decoration: line-through;
  }

  &__final {
    grid-area: final;

    color: $order-card-total-price-final-color;
    font: $font-h3;
    font-size: $order-card-total-price-final-font-size;
    font-family: $font-stack;
  }
}

.oder-card-details-help {
  @include order-detail-help;
}

.summary-toolbar-container {
  display: flex;
  justify-content: center;
}

.summary-toolbar-options {
  position: sticky;

  display: flex;
  padding-inline: 0;
  flex-direction: column;
  gap: rem-calc(24);
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-block-start: rem-calc(16);

  .button {
    width: 100%;
  }

  .link--standalone {
    text-decoration: inherit;
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 $order-card-width;
    flex-direction: $summary-toolbar-option-flex-direction-lg;
    margin-block-start: rem-calc(24);

    .button {
      width: auto;
    }
  }
}
