// Colors
$white: #fff;
$black: #1c1c1c;
$gray-100: #fcfafb;
$gray-200: #f7f0f3;
$gray-300: #ebe4e6;
$gray-400: #d6d0d2;
$gray-500: #bab3b6;
$gray-600: #8a8486;
$gray-700: #736d6f;
$gray-800: #332f31;
$gray-900: #1f1c1d;
$dark-gray: #1f1c1d;
$text-default: #1f1c1d;
$text-neutral: var(--text-neutral, $gray-600);
$subtitle-text-color: var(--subtitle-color-text, $text-neutral);
$primary-color: var(--primary-color, #8a1343);
$primary-color--light: var(--primary-color--light, #f5e9ed);
$primary-color--dark: var(--primary-color--dark, #660e32);
$secondary-color: var(--secondary-color, #137f8a);
$secondary-color--light: var(--secondary-color--light, #dff5f7);
$secondary-color--dark: var(--secondary-color--dark, #0b464d);
$color-success: var(--color-success, #098945);
$warning: #ffb509;
$rating: var(--rating, $color-palette-warning-400);
$rating-hover: #d59500;
$color-background-header-table: #f5f5f5;

$close: #adb5bd;
$highlight: $color-background-primary-weak;
$confirmation: #00c89f;
$neutral-background: #f7f7f7;
$disabled: #f2f3f3;
$border: #e1e1e1;
$banner-info-background-color: #f0ebfd;
$banner-info-icon-color: #6f41d7;

$focus: #3470df;
$link-decoration: none;
$link-hover-decoration: none;

$link-text-decoration: var(--link-text-decoration, none);

$tab-item-color-border-active: $primary-color;

$border-radius: rem-calc(16);
$border-radius-xs: rem-calc(4);
$border-radius-sm: rem-calc(8);
$border-radius-lg: rem-calc(24);
$border-radius-xl: rem-calc(50);

// Z-index
$z-index-med: 100;
$z-index-top: 1000;
$z-index-top-app: 1040;
$z-index-button: 2;

// WL variables //
$wl-border-radius: var(--wl-border-radius, rem-calc(8));
$wl-shadow: var(--wl-shadow, none);
$wl-shadow-hover: var(--wl-shadow-hover, none);

// DS Variables //

$border-style: var(--border-style, rem-calc(1) solid $color-border-main);

$checkbox-color-background: var(--checkbox-color-background, default);

// Semantics effects
$box-shadow-small: var(--box-shadow-small, rem-calc(0 0 6 0) $color-shadow-main);
$box-shadow-medium: var(--box-shadow-medium, rem-calc(0 0 10 0) $color-shadow-main);

// Header Bar
$header-color-background: var(--header-color-background, $color-background-main);
$header-shadow: var(--header-shadow, rem-calc(0 0 6 0) $color-palette-neutral-200);
$header-height: var(--header-height, rem-calc(48));
$header-height-small: var(--header-height-small, rem-calc(48));
$header-height-large: var(--header-height-large, rem-calc(48));
$header-height-with-subheader: var(--header-height-with-subheader, rem-calc(120));
$header-logo-max-width-small: var(--header-logo-max-width-small, rem-calc(192));
$header-logo-max-width-large: var(--header-logo-max-width-large, rem-calc(192));
$header-logo-height-small: var(--header-logo-height-small, rem-calc(20));
$header-logo-height-large: var(--header-logo-height-large, rem-calc(24));
$header-border-bottom: var(--header-border-bottom, transparent);
$header-text-font-weight: var(--header-text-font-weight, normal);

// Header navbar
$navbar-font-family: var(--navbar-font-family, $font-stack);
$navbar-font-weight: var(--navbar-font-weight, $font-weight-regular);
$navbar-action-color-text-primary: var(--navbar-action-color-text-primary, $color-action-text-primary) !default;
$navbar-action-color-text-primary-hover: var(--navbar-action-color-text-primary-hover, $color-action-text-main-hover) !default;
$navbar-font-size: var(--navbar-font-size, $font-size-body-base);
$navbar-dimensions-gap: var(--navbar-dimensions-gap, rem-calc(32));
$navbar-effect-box-shadow: var(--navbar-effect-box-shadow, rem-calc(-2 0 24 2) $color-shadow-main);
$navbar-icon-color-foreground: var(--navbar-icon-color-foreground, var(--gray-700));
$navbar-color-background-main: var(--navbar-color-background-main, $color-background-main);
$navbar-action-menu-link: var(--navbar-action-menu-link, $color-action-text-main);
$navbar-user-name-color: var(--navbar-user-name-color, $black);
$navbar-user-email-color: var(--navbar-user-email-color, $color-text-subtle);
$navbar-dimensions-width: var(--navbar-dimensions-width, rem-calc(290));
$navbar-login-dimensions-width: var(--navbar-login-dimensions-width, 80%);
$navbar-login-border: var(--navbar-login-border, none);
$navbar-login-button-color-background: var(--navbar-login-button-color-background, transparent);
$navbar-login-button-color-border: var(--navbar-login-button-color-border, $navbar-action-color-text-primary);
$navbar-login-icon-color: var(--navbar-login-icon-color, $navbar-action-color-text-primary);

// Header top banner
$top-banner-height: var(--top-banner-height, rem-calc(40));
$top-banner-background-color: var(--top-banner-background-color, $header-color-background);
$top-banner-background-color-contrast: var(--top-banner-background-color-contrast, $header-color-background);
$top-banner-dimensions-max-width: var(--top-banner-dimensions-max-width, rem-calc(1320));

// Language selector
$language-selector-color: var(--language-selector-color, $navbar-action-color-text-primary);
$language-selector-background-color: var(--language-selector-background-color, #fff);
$language-selector-hover-color: var(--language-selector-hover-color, $navbar-action-color-text-primary-hover);
$language-selector-hover-background-color: var(--language-selector-hover-background-color, $color-background-subtle-weak);
$language-selector-border: var(--language-selector-border, none);

// Language Suggestion
$language-suggestion-lightbox-y-position-small: var(--language-suggestion-lightbox-y-position-small, calc($header-height-small + 1rem));
$language-suggestion-lightbox-y-position-large: var(--language-suggestion-lightbox-y-position-large, $header-height-large);
$language-suggestion-lightbox-border: var(--language-suggestion-lightbox-border, rem-calc(1) solid $black);
$language-suggestion-lightbox-border-radius: var(--language-suggestion-lightbox-border-radius, $wl-border-radius);
$language-suggestion-lightbox-color: var(--language-suggestion-lightbox-color, $black);
$language-suggestion-lightbox-dimensions-border-radius: var(--language-suggestion-lightbox-dimensions-border-radius, $wl-border-radius);
$language-selector-box-shadow: var(--language-selector-box-shadow, $wl-shadow);
$language-suggestion-button-font-weight: var(--language-suggestion-button-font-weight, $font-weight-regular);

// Page
$page-dimensions-max-width: var(--page-dimensions-max-width, rem-calc(1320));
$page-dimensions-padding-block-start-sm: var(--page-dimensions-padding-block-start-sm, $header-height-small);
$page-dimensions-padding-block-start-lg: var(--page-dimensions-padding-block-start-lg, $header-height-large);
$page-dimensions-padding-block-end-sm: var(--page-dimensions-padding-block-end-sm, 0);
$page-dimensions-padding-block-end-lg: var(--page-dimensions-padding-block-end-lg, 0);
$page-background-color: var(--page-background-color, $white);
$page-wrapper-dimensions-gap-sm: var(--page-wrapper-dimensions-gap-sm, rem-calc(16));
$page-wrapper-dimensions-gap-lg: var(--page-wrapper-dimensions-gap-lg, rem-calc(24));

// Page titles
$page-title-dimensions-margin-block-sm: var(--page-title-dimensions-margin-block-sm, rem-calc(0 16));
$page-title-dimensions-margin-block-lg: var(--page-title-dimensions-margin-block-lg, rem-calc(0 24));

// Page section
$page-section-dimensions-padding-block-start-sm: var(--page-section-dimensions-padding-block-start-sm, rem-calc(24));
$page-section-dimensions-padding-block-start-lg: var(--page-section-dimensions-padding-block-start-lg, rem-calc(40));
$page-section-dimensions-padding-block-end-sm: var(--page-section-dimensions-padding-block-end-sm, rem-calc(80));
$page-section-dimensions-padding-block-end-lg: var(--page-section-dimensions-padding-block-end-lg, rem-calc(56));

//Page plan view
$page-plan-view-dimensions-padding-block-start-sm: var(--page-plan-view-dimensions-padding-block-start-sm, $page-section-dimensions-padding-block-start-sm);
$page-plan-view-dimensions-padding-block-start-lg: var(--page-plan-view-dimensions-padding-block-start-lg, $page-section-dimensions-padding-block-start-lg);

// Box wrapper
$box-wrapper-dimensions-padding-small: var(--box-wrapper-dimensions--padding-small, rem-calc(16));
$box-wrapper-dimensions--padding-large: var(--box-wrapper-dimensions--padding-large, rem-calc(32));
$box-wrapper-dimensions-border-radius: var(--box-wrapper-dimensions-border-radius, $wl-border-radius);
$box-wrapper-border: var(--box-wrapper-border, $border-style);
$box-wrapper-color-background-primary: var(--box-wrapper-color-background-primary, $color-background-main);

// Common Button
$button-dimension-height-small: var(--button-dimension-height-small, rem-calc(32));
$button-dimension-height-large: var(--button-dimension-height-large, rem-calc(48));
$button-dimension-padding-inline-small: var(--button-dimension-padding-inline-small, rem-calc(16));
$button-dimension-radius: var(--button-dimension-radius, $wl-border-radius);
$button-dimension-max-width: var(--button-dimension-max-width, rem-calc(400));
$button-text-transform: var(--button-text-transform, none);
$button-font-family: var(--button-font-family, $font-stack);
$button-effect-transform-hover: var(--button-effect-transform-hover, translateY(rem-calc(-1)));
$fixed-cta-gradient-background: var(--fixed-cta-gradient-background, linear-gradient(0deg, rgba(255, 255, 255, 0.9) 32%, rgba(255, 255, 255, 0) 100%));

// Primary button tokens
$button-color-background-primary: var(--button-color-background-primary, $color-action-background-primary);
$button-color-background-primary-hover: var(--button-color-background-primary-hover, $color-action-background-primary-hover);
$button-color-foreground-primary: var(--button-color-foreground-primary, $color-text-main-contrast);
$button-color-foreground-primary-hover: var(--button-color-foreground-primary-hover, $button-color-foreground-primary);
$button-border-primary: var(--button-border-primary, none);
$button-border-primary-hover: var(--button-border-primary-hover, $button-border-primary);
$button-text-letter-spacing: var(--button-text-letter-spacing, initial);

// Secondary button tokens
$button-color-background-secondary: var(--button-color-background-secondary, $color-action-background-primary);
$button-color-background-secondary-hover: var(--button-color-background-secondary-hover, $color-action-background-primary-hover);
$button-color-foreground-secondary: var(--button-color-foreground-secondary, $color-text-main-contrast);
$button-color-foreground-secondary-hover: var(--button-color-foreground-secondary-hover, $button-color-foreground-secondary);
$button-border-secondary: var(--button-border-secondary, none);
$button-border-secondary-hover: var(--button-border-secondary-hover, none);

// Tertiary button tokens
$button-color-background-tertiary: var(--button-color-background-tertiary, none);
$button-color-background-tertiary-hover: var(--button-color-background-tertiary-hover, none);
$button-color-foreground-tertiary: var(--button-color-foreground-tertiary, $color-action-text-main);
$button-color-foreground-tertiary-hover: var(--button-color-foreground-tertiary-hover, $color-action-text-main-hover);
$button-border-tertiary: var(--button-border-tertiary, none);
$button-border-tertiary-hover: var(--button-border-tertiary-hover, none);

// Contrast button tokens
$button-color-background-secondary-contrast: var(--button-color-background-secondary-contrast, $color-action-background-primary);
$button-color-background-secondary-contrast-hover: var(--button-color-background-secondary-contrast-hover, $color-action-background-primary-hover);
$button-color-foreground-secondary-contrast: var(--button-color-foreground-secondary-contrast, $color-text-main-contrast);
$button-color-foreground-secondary-contrast-hover: var(--button-color-foreground-secondary-contrast-hover, $button-color-foreground-secondary);
$button-border-secondary-contrast: var(--button-border-secondary-contrast, none);
$button-border-secondary-contrast-hover: var(--button-border-secondary-contrast-hover, $button-border-secondary);

// Button login
$button-login-font-size: var(--button-login-font-size, $font-size-body-base);
$button-login-font-family: var(--button-login-font-family, $font-stack);
$button-login-background-color: var(--button-login-background-color, $color-background-subtle-weak);
$button-login-text-transform: var(--button-login-text-transform, unset);
$button-login-border: var(--button-login-border, 0);
$button-login-dimension-radius: var(--button-login-dimension-radius, $wl-border-radius);
$button-login-facebook-background: var(--button-login-facebook-background, $button-login-background-color);
$button-login-facebook-color: var(--button-login-facebook-color, $color-text-main);
$button-login-facebook-border: var(--button-login-facebook-border, $button-login-border);
$button-login-facebook-icon-color: var(--button-login-facebook-icon-color, #1877f2);

//Chips filter
$chip-filter-dimensions-width: var(--chip-filter-dimensions-width, auto);
$chip-filter-color: var(--chip-filter-color);
$chip-filter-color-foreground-selected: var(--chip-filter-color-foreground-selected, $color-background-main);
$chip-filter-color-background-selected: var(--chip-filter-color-background-selected, $color-background-main-contrast);
$chip-filter-font: var(--chip-filter-font, $font-caption-regular);
$chip-filter-border: var(--chip-filter-button-border, rem-calc(1) solid $color-border-main);
$chip-filter-dimensions-border-radius: var(--chip-filter-dimensions-border-radius, $wl-border-radius);

// Hero
$hero-margin-block-start-sm: calc($header-height - $page-dimensions-padding-block-start-sm);
$hero-margin-block-start-lg: calc($header-height - $page-dimensions-padding-block-start-lg);
$hero-height-large: var(--hero-height-large, rem-calc(280));
$hero-height-small: var(--hero-height-small, rem-calc(184));
$hero-color-background: var(--hero-color-background, none);
$hero-effect-filter: var(--hero-effect-filter, none);

// Footer
$footer-color-background: var(--footer-color-background, $color-palette-neutral-900);
$footer-color-foreground: var(--footer-color-foreground, $color-text-main-contrast);
$footer-link-color: var(--footer-link-color, $color-action-text-main-contrast);
$footer-link-hover-color: var(--footer-link-hover-color, $color-action-text-main-hover-contrast);
$footer-nav-section-color: var(--footer-nav-section-color, $color-action-text-main-contrast);
$footer-logo-dimensions-max-width: var(--footer-logo-dimensions-max-width, rem-calc(200));
$footer-navigation-summary-color: var(--footer-navigation-summary-color, unset);
$footer-navigation-summary-font-size: var(--footer-navigation-summary-font-size, unset);
$footer-copyright-color: var(--footer-copyright-color, $footer-color-foreground);
$footer-link-font-size: var(--footer-link-font-size, $font-size-body-small);
$footer-dimensions-padding-block: var(--footer-dimensions-padding-block, rem-calc(24));
$footer-dimensions-padding-block-small: var(--footer-dimensions-padding-block-small, $footer-dimensions-padding-block);
$footer-border-top: var(--footer-border-top, none);
$footer-copyright-and-social-border-top: var(--footer-copyright-and-social-border-top, 1px solid #fff);
$footer-copyright-and-social-justify-content-sm: var(--footer-copyright-and-social-justify-content-sm, center);
$footer-copyright-and-social-justify-content-lg: var(--footer-copyright-and-social-justify-content-lg, $footer-copyright-and-social-justify-content-sm);
$footer-nav-details-summary-padding-bottom: var(--footer-nav-details-summary-padding-bottom, rem-calc(8));
$footer-rrss-list-gap: var(--footer-rrss-list-gap, rem-calc(8));

// Login
$login-dimensions-max-width: var(--login-dimensions-max-width, rem-calc(420));
$login-border: var(--login-border, 0);
$login-background-color: var(--login-background-color, var(--color-background-primary-strong));
$login-box-shadow: var(--login-box-shadow, $wl-shadow);
$login-border-radius: var(--login-border-radius, var(--wl-border-radius, 0.5rem));
$login-dimensions-padding-sm: var(--login-dimensions-padding-sm, 0);
$login-dimensions-padding-lg: var(--login-dimensions-padding-lg, 0);
$login-page-header-display: var(--login-page-header-display, block);
$login-methods-list-padding: var(--login-methods-list-padding, 1.5rem);

//Plans list header
$plans-header-dimensions-margin-bottom-small: var(--plans-header-dimensions-margin-bottom-small, rem-calc(8));
$plans-header-dimensions-margin-bottom-large: var(--plans-header-dimensions-margin-bottom-large, rem-calc(16));
$plans-header-secondary-color: var(--plans-header-secondary-color, $color-text-subtle);

// Plan list
$plans-container-dimensions-block-start-sm: var(--plans-container-dimensions-block-start-sm, rem-calc(24));
$plans-container-dimensions-block-end-sm: var(--plans-container-dimensions-block-end-sm, 0);
$plans-container-dimensions-block-start-lg: var(--plans-container-dimensions-block-start-lg, rem-calc(56));
$plans-container-dimensions-block-end-lg: var(--plans-container-dimensions-block-end-lg, 0);
$plans-list-dimensions-block-start-sm: var(--plans-list-dimensions-block-start-sm, rem-calc(24));
$plans-list-dimensions-block-start-lg: var(--plans-list-dimensions-block-start-lg, rem-calc(32));
$plans-list-items-per-row: var(--plans-list-items-per-row, 1);
$plans-list-items-per-row-sm: var(--plans-list-items-per-row-sm, 1);
$plans-list-items-per-row-md: var(--plans-list-items-per-row-md, 2);
$plans-list-items-per-row-lg: var(--plans-list-items-per-row-lg, 3);
$plan-list-grid-dimensions-gap: var(--plan-list-grid-dimensions-gap, rem-calc(16));
$plan-list-grid-dimensions-gap-small: var(--plan-list-grid-dimensions-gap-small, rem-calc(16));
$plan-list-grid-dimensions-gap-medium: var(--plan-list-grid-dimensions-gap-medium, rem-calc(24));
$plan-list-grid-dimensions-gap-large: var(--plan-list-grid-dimensions-gap-large, rem-calc(32));
$plans-list-dimensions-block-end-lg: var(--page-dimensions-padding-block-end-lg, 0);

// Plan list / item
$plan-list-item-border: var(--plan-list-item-border, $border-style);
$plan-list-item-dimensions-border-radius: var(--plan-list-item-dimensions-border-radius, $wl-border-radius);
$plan-list-item-effect-box-shadow: var(--plan-list-item-effect-box-shadow, $wl-shadow);
$plan-list-item-effect-box-shadow-hover: var(--plan-list-item-effect-box-shadow-hover, $wl-shadow-hover);
$plan-list-item-info-dimensions-padding-small: var(--plan-list-item-info-dimensions-padding-small, rem-calc(16));
$plan-list-item-info-dimensions-padding-large: var(--plan-list-item-info-dimensions-padding-large, rem-calc(16));
$plan-list-item-info-dimensions-gap: var(--plan-list-item-info-dimensions-gap, rem-calc(16));
$plan-list-item-image-dimensions-aspect-ratio: var(--plan-list-item-image-dimensions-aspect-ratio, 1/1);
$plan-list-item-image-dimensions-border-radius: var(--plan-list-item-image-dimensions-border-radius, $wl-border-radius $wl-border-radius 0 0);
$plan-list-item-title-font-small: var(--plan-list-item-title-font-small, $font-h3);
$plan-list-item-title-font-large: var(--plan-list-item-title-font-large, $plan-list-item-title-font-small);
$plan-list-item-info-place-color: var(--plan-list-item-info-place-color, $color-text-subtle);

// Ticket Selector
$ticket-selector-header-color-foreground: var(--ticket-selector-header-color-foreground, $color-text-main);
$ticket-selector-header-color-background: var(--ticket-selector-header-color-background, $color-background-main);
$ticket-selector-header-font-size-lg: var(--ticket-selector-header-font-size-lg, rem-calc(20));
$ticket-selector-header-line-height-lg: var(--ticket-selector-header-line-height-lg, rem-calc(24));
$ticket-selector-header-icon-display: var(--ticket-selector-header-icon-display, inline);
$ticket-selector-color-background: var(--ticket-selector-wrapper-color-background, $white);
$ticket-selector-color-foreground: var(--ticket-selector-wrapper-color-foreground, $black);
$ticket-selector-active-color-background: var(--ticket-selector-active-color-background, $color-background-primary-weak);
$ticket-selector-active-amount-color-background: var(--ticket-selector-active-amount-color-background, $color-background-primary-weak);
$ticket-selector-active-border-color: var(--ticket-selector-active-border-color, $color-action-border-main);
$ticket-selector-scrollbar-thumb-color: var(--ticket-selector-scrollbar-thumb-color);
$ticket-selector-scrollbar-thumb-color-hover: var(--ticket-selector-scrollbar-thumb-color-hover);
$ticket-selector-scrollbar-track-color: var(--ticket-selector-scrollbar-track-color);
$ticket-selector-item-color-background-available: var(--ticket-selector-item-color-background-available, $color-palette-primary-100);
$ticket-selector-item-color-border-available-hover: var(--ticket-selector-item-color-border-available-hover, $color-background-primary-weak);
$ticket-selector-item-color-foreground-selected: var(--ticket-selector-item-color-foreground-selected, $color-action-text-primary);
$ticket-selector-item-color-foreground-hover: var(--ticket-selector-item-color-foreground-hover, $color-action-text-primary);
$ticket-selector-item-color-border-selected: var(--ticket-selector-item-color-border-selected, $color-action-border-primary);
$ticket-selector-item-color-background-selected: var(--ticket-selector-item-color-background-selected, unset);
$ticket-selector-item-check-color-foreground-selected: var(--ticket-selector-item-ckeck-color-foreground-selected, $color-text-main-contrast);
$ticket-selector-item-check-color-background-selected: var(--ticket-selector-item-check-color-background-selected, $color-action-background-primary);
$ticket-selector-item-dimensions-border-radius: var(--ticket-selector-item-dimensions-border-radius, rem-calc(4));
$ticket-selector-item-discount-banner-color-background: var(--ticket-selector-item-discount-banner-color-background, $color-action-background-primary);
$ticket-selector-item-discount-banner-color-foreground: var(--ticket-selector-item-discount-banner-color-foreground, $color-action-text-main-contrast);
$ticket-selector-item-discount-banner-border-radius: var(--ticket-selector-item-discount-banner-border-radius, $border-radius-xs);
$ticket-selector-item-discount-banner-font: var(--ticket-selector-item-discount-banner-font, $font-caption-bold);
$ticket-selector-item-price-final-font-size: var(--ticket-selector-item-price-final-font-size, $font-size-body-small);
$ticket-selector-item-price-final-font-weight: var(--ticket-selector-item-price-final-font-weight, $font-weight-bold);
$ticket-selector-item-price-final-color: var(--ticket-selector-item-price-final-color, $ticket-selector-color-foreground);
$ticket-selector-item-price-strikethrough-font-size: var(--ticket-selector-item-price-strikethrough-font-size, $font-size-body-small);
$ticket-selector-item-price-strikethrough-font-weight: var(--ticket-selector-item-price-strikethrough-font-weight, $font-size-body-small);
$ticket-selector-item-amount-container-top: var(--ticket-selector-item-amount-container-top, rem-calc(35));
$ticket-selector-amount-button-color-foreground: var(--ticket-selector-amount-button-color-foreground, $color-action-text-main-contrast);
$ticket-selector-amount-button-color-background: var(--ticket-selector-amount-button-color-background, $color-action-background-primary);
$ticket-selector-amount-button-border: var(--ticket-selector-amount-button-border, 0);
$ticket-selector-amount-button-color-foreground-hover: var(--ticket-selector-amount-button-color-foreground-hover, $color-action-text-main-hover-contrast);
$ticket-selector-amount-button-color-background-hover: var(--ticket-selector-amount-button-color-background-hover, $color-action-background-primary-hover);
$ticket-selector-amount-button-border-hover: var(--ticket-selector-amount-button-border-hover, 0);
$ticket-selector-multi-item-border: var(--ticket-selector-multi-item-border, none);
$ticket-selector-multi-item-padding: var(--ticket-selector-multi-item-padding, rem-calc(16 0));
$ticket-selector-multi-item-margin: var(--ticket-selector-multi-item-margin, 0);
$ticket-selector-multi-detailed-border: var(--ticket-selector-multi-detailed-border, rem-calc(1) solid $color-border-main);
$ticket-selector-multi-detailed-session-name-font-weight: var(--ticket-selector-multi-detailed-session-name-font-weight, $font-weight-regular);
$ticket-selector-multi-detailed-more-info-color: var(--ticket-selector-multi-detailed-more-info-color, $color-action-text-primary);
$ticket-selector-multi-detailed-more-info-font-weight: var(--ticket-selector-multi-detailed-more-info-font-weight, $font-weight-regular);
$ticket-selector-multi-detailed-more-info-font-size: var(--ticket-selector-multi-detailed-more-info-font-size, $font-size-body-small);
$ticket-selector-multi-detailed-item-price-final-color: var(--ticket-selector-multi-detailed-item-price-final-color, $color-text-main);
$ticket-selector-multi-detailed-item-price-final-font-weight: var(--ticket-selector-multi-detailed-item-price-final-font-weight, $font-weight-bold);
$ticket-selector-multi-detailed-item-price-final-font-size: var(--ticket-selector-multi-detailed-item-price-final-font-size, $font-size-body-small);
$ticket-selector-multi-detailed-description-color: var(--ticket-selector-multi-detailed-description-color, $ticket-selector-color-foreground);
$ticket-selector-multi-detailed-description-font-size: var(--ticket-selector-multi-detailed-description-font-size, $font-size-body-small);
$ticket-selector-multi-detailed-more-info-text-decoration: var(--ticket-selector-multi-detailed-more-info-text-decoration, underline);
$ticket-selector-header-border-bottom: var(--ticket-selector-header-border-bottom, $border-style);
$ticket-selector-item-tag-sold-out-color-background: var(--ticket-selector-item-tag-sold-out-color-background, $color-background-subtle-base);
$ticket-selector-top-sm: var(--ticket-selector-top-sm, calc($page-dimensions-padding-block-start-sm + 1rem));
$ticket-selector-top-lg: var(--ticket-selector-top-lg, calc($page-dimensions-padding-block-start-lg + 1rem));
$ticket-selector-border-lg: var(--ticket-selector-border-lg, $box-wrapper-border);
$ticket-selector-box-shadow: var(--ticket-selector-box-shadow, $wl-shadow);
$ticket-selector-selected-text-color: var(--ticket-selector-selected-text-color, $primary-color);
$ticket-selector-selected-border-color: var(--ticket-selector-selected-border-color, $primary-color);
$ticket-selector-selected-check-color: var(--ticket-selector-selected-check-color, $primary-color);

// Plan detail - Header
$plan-header-info-rounded-top: var(--plan-header-info-rounded-top, 1rem);
$plan-header-media-aspect-ratio: var(--plan-header-media-aspect-ratio, 4 / 3);
$plan-header-media-aspect-ratio-desktop: var(--plan-header-media-aspect-ratio-desktop, 1 / 1);
$plan-header-hero-curve-background: var(--plan-header-hero-curve-background, var(--black));
$plan-header-hero-curve-color: var(--plan-header-hero-curve-color, var(--white));
$plan-header-info-title-font-family: var(--plan-header-info-title-font-family, $font-stack-secondary);
$plan-header-info-title-font-size-sm: var(--plan-header-info-title-font-size-sm, $font-size-h2);
$plan-header-info-title-font-line-height-sm: var(--plan-header-info-title-font-line-height-sm, $font-line-height-h2);
$plan-header-info-title-font-size-lg: var(--plan-header-info-title-font-size-lg, $font-size-h1);
$plan-header-info-title-font-line-height-lg: var(--plan-header-info-title-font-line-height-lg, $font-line-height-h1);
$plan-header-info-title-text-transform: var(--plan-header-info-title-text-transform, none);

//Plan detail - Read more
$plan-description-line-clamp-mobile: var(--plan-description-line-clamp-mobile, 3);
$plan-description-line-clamp-desktop: var(--plan-description-line-clamp-desktop, 3);

//Plan detail - Content
$plan-detail-address-text-color: var(--plan-detail-address-text-color, $color-text-subtle);
$plan-detail-urgency-custom-label-bg: var(--plan-detail-urgency-custom-label-bg, $color-palette-warning-600);
$plan-detail-urgency-custom-label-text: var(--plan-detail-urgency-custom-label-text, $color-palette-neutral-800);
$plan-detail-link-font-weight: var(--plan-detail-link-font-weight, normal);

//Plan detail - Venue
$plan-venue-images-aspect-ratio: var(--plan-venue-images-aspect-ratio, 512 / 309);

// Plan detail -  User photo gallery
$plan-user-photo-gallery-padding-inline-start: var(--plan-user-photo-gallery-padding-inline-star, 0);
$plan-user-photo-gallery-padding-block-end: var(--plan-user-photo-gallery-padding-block-end, 1.5rem);
$plan-user-photo-gallery-gap: var(--plan-user-photo-gallery-gap, 0.5rem);
$plan-user-photo-gallery-width-mobile: var(--plan-user-photo-gallery-padding-block-end, rem-calc(111));
$plan-user-photo-gallery-aspect-ratio: var(--plan-user-photo-gallery-padding-block-end, 2 / 3);
$plan-user-photo-gallery-border-radius: var(--plan-user-photo-gallery-padding-block-end, $border-radius-sm);
$plan-user-photo-gallery-width-desktop: var(--plan-user-photo-gallery-padding-block-end, rem-calc(195));

// Chips
$chip-dimensions-width: var(--chip-dimensions-width, rem-calc(96));
$chip-dimensions-border-radius: var(--chip-dimensions-border-radius, rem-calc(100));
$chip-text-transform: var(--chip-text-transform, uppercase);
$chip-border: var(--chip-border, $border-style);
$chip-color-foreground-selected: var(--chip-color-foreground-selected, $white);
$chip-color-background-selected: var(--chip-color-background-selected, $black);
$chip-color-border-selected: var(--chip-color-border-selected, none);

//Order
$order-accordion-icon-color: var(--order-accordion-icon-color, $color-palette-neutral-900);

// Orders list item
$orders-item-dimensions-padding-small: var(--orders-item-dimensions--padding-small, rem-calc(16));
$orders-item-dimensions--padding-large: var(--orders-item-dimensions--padding-large, rem-calc(32));
$orders-item-dimensions-border-radius: var(--orders-item-dimensions-border-radius, $wl-border-radius);
$orders-item-dimensions-max-width: var(--orders-item-dimensions-max-width, rem-calc(912));
$orders-item-effect-box-shadow: var(--orders-item-effect-box-shadow, $wl-shadow);
$orders-item-border: var(--orders-item-border, rem-calc(1) solid $color-border-main);
$orders-item-color-background: var(--orders-item-color-background, $color-background-main);
$orders-item-radius: var(--orders-item-dimensions-border-radius, $wl-border-radius);
$orders-item-image-dimensions-border-radius: var(--orders-item-image-radius, rem-calc(4));
$orders-item-separator: var(--orders-item-separator-border, rem-calc(1) solid $color-border-weak);
$orders-item-name-text-color: var(--orders-item-name-text-color, $color-palette-neutral-900);
$orders-item-venue-display: var(--orders-item-venue-display, block);
$orders-item-id-text-color: var(--orders-item-id-text-color, $color-action-text-main);
$orders-item-id-text-transform: var(--orders-item-id-text-transform, none);
$orders-item-venue-text-color: var(--orders-item-venue-text-color, $color-action-text-main);
$orders-item-venue-text-transform: var(--orders-item-venue-text-transform, uppercase);
$orders-item-image-dimensions-aspect-ratio-small: var(--orders-item-image-dimensions-aspect-ratio-small, 1/1);
$orders-item-image-dimensions-aspect-ratio-large: var(--orders-item-image-dimensions-aspect-ratio-large, 4/3);
$orders-item-list-item-padding: var(--orders-item-list-item-padding, rem-calc(16 0));
$orders-item-list-item-border-bottom: var(--orders-item-list-item-border-bottom, $orders-item-separator);
$order-item-font-small: var(--order-item-font-small, $font-h4);
$order-item-font-large: var(--order-item-font-large, $font-h3);
$orders-item-link-color: var(--orders-item-link-color, $color-action-text-main);
$order-item-link-color: var(--order-item-link-color, $color-action-text-primary);
$order-item-link-text-decoration: var(--order-item-link-text-decoration, none);
$order-item-link-font-weight: var(--order-item-link-font-weight, normal);
$order-item-link-color-hover: var(--order-item-link-color-hover, $color-action-text-primary-hover);
$order-item-link-text-decoration-hover: var(--order-item-link-text-decoration-hover, $color-action-text-primary-hover);

//Quick Info
$quick-info-border-color: var(--quick-info-border-color, $color-border-main);
$quick-info-icon-color-background: var(--quick-info-icon-color-background, $color-background-subtle-weak);
$quick-info-icon-color-foreground: var(--quick-info-icon-color-foreground, $color-text-main);

// Links
$main-link-decoration: var(--main-link-decoration, none);

// Info banner
$info-banner-dimensions-border-radius: var(--info-banner-dimensions-border-radius, $wl-border-radius);
$info-banner-color-background: var(--info-banner-color-background, $color-background-subtle-strong);
$info-banner-color-foreground: var(--info-banner-button-color-foreground, $color-text-main-contrast);

$info-banner-section-color-background: var(--info-banner-section-color-background, $color-background-subtle-weak);

// Section 404
$section-404-color-background: var(--section-404-color-background, $color-background-main);
$section-404-color-foreground: var(--section-404-color-foreground, $color-text-main);

//Order-list
$orders-list-dimensions-padding-block-start-sm: var(--orders-list-dimensions-padding-block-start-sm, $page-section-dimensions-padding-block-start-sm);
$orders-list-dimensions-padding-block-start-lg: var(--orders-list-dimensions-padding-block-start-lg, $page-section-dimensions-padding-block-start-lg);

// Order card
$order-card-image-dimensions-radius: var(--order-card-image-dimensions-radius, rem-calc(4));
$order-card-color-background: var(--order-card-color-background, $color-background-subtle-weak);
$order-card-width: var(--order-card-width, rem-calc(624));
$order-card-border: var(--order-card-border, 0);

$order-card-item-price-columns: var(--order-card-item-price-columns);
$order-card-item-price-label-font-size: var(--order-card-item-price-label-font-size);
$order-card-item-price-label-color: var(--order-card-item-price-label-color);
$order-card-item-price-strikethrough-color: var(--order-card-item-price-strikethrough-color, $color-action-text-disable);
$order-card-item-price-strikethrough-font-size: var(--order-card-item-price-strikethrough-font-size);
$order-card-item-price-final-color: var(--order-card-item-price-final-color, $color-action-text-primary);
$order-card-item-price-final-font-size: var(--order-card-item-price-final-font-size);
$order-card-item-price-final-font-weight: var(--order-card-item-price-final-font-weight, bolder);

$order-card-total-price-columns: var(--order-card-total-price-columns);
$order-card-total-price-justify: var(--order-card-total-price-justify, space-between);
$order-card-total-price-areas: var(--order-card-total-price-areas, 'label strikethrough final taxes');
$order-card-total-price-strikethrough-color: var(--order-card-total-price-strikethrough-color, $color-action-text-disable);
$order-card-total-price-strikethrough-font-size: var(--order-card-total-price-strikethrough-font-size);
$order-card-total-price-label-color: var(--order-card-total-price-label-color);
$order-card-total-price-label-font-size: var(--order-card-total-price-label-font-size, $font-size-h4);
$order-card-total-price-final-color: var(--order-card-total-price-final-color, $color-action-text-primary);
$order-card-total-price-final-font-size: var(--order-card-total-price-final-font-size, $font-size-h3);

$oder-card-details-help-color-background: var(--oder-card-details-help-color-background, $color-background-main);
$oder-card-details-total-price: var(--oder-card-details-total-price, $color-text-main);
$oder-card-details-help-dimensions-padding: var(--oder-card-details-help-dimensions-padding, rem-calc(16));

$order-tickets-qr-list-gap-x: var(--order-tickets-qr-list-gap-x, rem-calc(24));
$order-tickets-qr-list-gap-y: var(--order-tickets-qr-list-gap-y, rem-calc(24));
$summary-toolbar-option-flex-direction-lg: var(--summary-toolbar-option-flex-direction-lg, row-reverse);
$purchase-progress-dimensions-padding-block-start: var(--purchase-progress-dimensions-padding-block-start, $page-section-dimensions-padding-block-start-lg);

// Ticket
$ticket-qr-card-color-background: var(--ticket-qr-card-color-background, $white);
$ticket-qr-card-color-text: var(--ticket-qr-card-color-text, $black);
$ticket-qr-card-border-radius: var(--ticket-qr-card-border-radius, $wl-border-radius);
$ticket-qr-card-insets-radius: var(--ticket-qr-card-insets-radius, $wl-border-radius);
$ticket-qr-card-width: var(--ticket-qr-card-width, rem-calc(256));
$ticket-qr-card-header-padding: var(--ticket-qr-card-header-padding, rem-calc(16 0));
$ticket-qr-card-figure-margin: var(--ticket-qr-card-figure-margin, rem-calc(32 0 16));
$ticket-qr-card-figure-maxwidth: var(--ticket-qr-card-figure-width, rem-calc(128));
$ticket-qr-card-title-text-color: var(--ticket-qr-card-title-text-color, $color-text-main);

//Payment methods
$payment-methods-section-color-background: var(--payment-methods-section-color-background, $color-background-main);
$payment-methods-section-cta-dimensions-width: var(--payment-methods-section-cta-dimensions-width, auto);
$payment-methods-section-item-radio-button-color: var(--payment-methods-section-item-radio-button-color, $color-action-background-primary);
$payment-methods-section-item-border: var(--payment-methods-section-item-border, 0);
$payment-methods-section-dimensions-padding-sm: var(--payment-methods-section-dimensions-padding-sm, rem-calc(16));
$payment-methods-section-dimensions-padding-lg: var(--payment-methods-section-dimensions-padding-lg, rem-calc(0 16));
$payment-methods-section-item-dimensions-margin-bottom-sm: var(--payment-methods-section-item-dimensions-margin-bottom-sm, rem-calc(16));
$payment-methods-section-item-dimensions-margin-bottom-lg: var(--payment-methods-section-item-dimensions-margin-bottom-lg, rem-calc(24));
$payment-methods-new-card-border-color: var(--payment-methods-new-card-border-color, $primary-color);
$payment-methods-button-stripe-theme: dark;
$payment-methods-button-klarna-background: var(--payment-methods-klarna-button-background, #ffb3c7);
$payment-methods-button-klarna-border: var(--payment-methods-klarna-button-border, rem-calc(2) solid transparent);
$payment-methods-button-klarna-background-hover: var(--payment-methods-klarna-button-background-hover, #ffb3c7);
$payment-methods-button-klarna-border-hover: var(--payment-methods-klarna-button-border-hover, rem-calc(2) solid #ff5d88);

// Profile
$profile-user-avatar-dimensions-width: var(--profile-user-avatar-dimensions-width, rem-calc(128));
$profile-user-avatar-dimensions-height: var(--profile-user-avatar-dimensions-height, rem-calc(128));
$profile-user-avatar-dimensions-radius: var(--profile-user-avatar-dimensions-radius, rem-calc(100));
$profile-user-avatar-border: var(--profile-user-avatar-border, 0);
$profile-user-name-font: var(--profile-user-name-font, $font-h4);
$profile-menu-link-font-weight: var(--profile-menu-link-font-weight, normal);
$profile-menu-link-font-family: var(--profile-menu-link-font-family, $font-stack);

//Filters and search result
$plans-empty-color-background: var(--plans-empty-color-background, transparent);
$plan-filters-separator-display: var(--plan-filters-separator-display, none);

//Modal
$language-modal-title-text-color: var(--orders-item-id-text-color, $color-text-main);
$language-selector-font-weight: var(--language-selector-font-weight, normal);
$modal-close-button-foreground-color-primary: var(--modal-close-button-foreground-color-primary, $color-text-subtle);
$modal-close-button-effect-transform-hover: var(--modal-close-button-effect-transform-hover, $color-background-main-contrast);
$modal-content-box-shadow: var(--modal-content-box-shadow, $wl-shadow);

//Add-ons
$add-on-label-text-color: var(--add-on-label-text-color, $ticket-selector-color-foreground);
$add-on-label-font-weight: var(--add-on-label-font-weight, $font-weight-bold);
$add-on-control-toggle-text-color: var(--add-on-control-toggle-text-color, $ticket-selector-color-foreground);
$add-on-control-stepper-text-color: var(--add-on-control-stepper-text-color, $ticket-selector-color-foreground);
$add-on-description-text-color: var(--add-on-description-text-color, $color-text-subtle);
$add-on-color-background: var(--add-on-color-background, $color-background-accent-weak);
$add-on-border: var(--add-on-border, none);
$add-on-icon-color: var(--add-on-icon-color, $color-text-accent);

//Tabs and Groups
$groups-info-tabs-container-color-background: var(--groups-info-tabs-container-color-background, $neutral-background);
$groups-color-background: var(--groups-color-background, $color-background-subtle-weak);
$tabs-color-background: var(--tabs-color-background, transparent);
$tabs-color-background-selected: var(--tabs-color-background-selected, none);
$tabs-border-bottom: var(--tabs-border-bottom, rem-calc(1) solid $gray-400);
$tabs-border-bottom-lg: var(--tabs-border-bottom-lg, 0);
$tabs-border-bottom-selected: var(--tabs-border-bottom-selected, rem-calc(4) solid $color-action-border-primary);
$tab-content-color-background: var(--tab-content-color-background, $color-background-subtle-weak);
$tab-content-padding: var(--tab-content-padding, rem-calc(16));
$tab-content-padding-lg: var(--tab-content-padding-lg, rem-calc(32));
$tabs-color-text: var(--tabs-color-text, $color-text-subtle);
$tabs-dimensions-radius: var(--tabs-dimensions-radius, rem-calc(4 4 0 0));
$plan-description-tab-title-font: var(--plan-description-tab-title-font, $font-h3);
$plan-description-tab-title-text-transform: var(--plan-description-tab-title-text-transform, none);
$plan-description-tab-h3-text-transform: var(--plan-description-tab-h3-text-transform, none);
$tabs-border-bottom: var(--tabs-border-bottom, solid rem-calc(1) $gray-400);

//Banner
$banner-color-background: var(--banner-color-background, $color-background-main);
$banner-border: var(--banner-border, none);
$banner-border-radius: var(--banner-border-radius, $wl-border-radius);
$banner-color-text: var(--banner-color-text, $color-action-text-primary);
$banner-icon-size: var(--banner-icon-size, rem-calc(48));
$banner-icon-color: var(--banner-icon-color, $color-action-text-primary);
$banner-button-color: var(--banner-button-color, $button-color-foreground-primary);
$banner-button-background: var(--banner-button-background, $button-color-background-primary);
$banner-button-border: var(--banner-button-border, rem-calc(8) solid $button-border-primary);
$banner-button-color-hover: var(--banner-button-color-hover, $button-color-foreground-primary);
$banner-button-background-hover: var(--banner-button-background-hover, $button-color-background-primary);

//Alert notification
$alert-notification-color-background: var(--alert-notification-color-background, $color-background-main);
$alert-notification-color-background-success: var(--alert-notification-color-background-success, $color-background-positive-weak);
$alert-notification-color-background-danger: var(--alert-notification-color-background-danger, $color-background-danger-weak);
$alert-notification-text-color-success: var(--alert-notification-text-color-success, $color-text-positive);
$alert-notification-text-color-danger: var(--alert-notification-text-color-danger, $color-text-danger);

//Ticket review
$ticket-review-boolean-color-label: var(--ticket-review-boolean-color-label, $color-text-subtle);
$ticket-review-boolean-color-input-warning: var(--ticket-review-boolean-color-input-warning, $color-text-warning);
$ticket-review-likert-color-label: var(--ticket-review-likert-color-label, $color-text-subtle);
$ticket-review-likert-color-input-warning: var(--ticket-review-likert-color-input-warning, $rating-hover);
$ticket-review-likert-color-rating: var(--ticket-review-likert-color-rating, $color-text-main);
$ticket-review-review-question-textarea-color-background: var(--ticket-review-review-question-textarea-color-background, $color-background-main);
$ticket-review-review-question-textarea-color: var(--ticket-review-review-question-textarea-color, $black);
$ticket-review-review-question-textarea-border: var(--ticket-review-review-question-textarea-border, rem-calc(1) solid $color-border-main);
$ticket-review-review-question-textarea-border-radius: var(--ticket-review-review-question-textarea-border-radius, $border-radius-xs);
$ticket-review-review-question-textarea-box-shadow: var(--ticket-review-review-question-textarea-box-shadow,
inset rem-calc(0 1 3) 0 rgba($color-palette-neutral-black, 0.5));
$ticket-review-review-question-textarea-color-placeholder: var(--ticket-review-review-question-textarea-color-placeholder, $color-palette-neutral-400);
$ticket-review-review-question-textarea-color-focus: var(--ticket-review-review-question-textarea-color-focus, $primary-color);
$ticket-review-ticket-detail-image-border-radius: var(--ticket-review-ticket-detail-image-border-radius, $wl-border-radius);
$ticket-review-ticket-detail-image-box-shadow: var(--ticket-review-ticket-detail-image-box-shadow, none);
$ticket-review-ticket-review-color-background: var(--ticket-review-ticket-review-color-background, $color-background-main);
$ticket-review-ticket-review-header-color: var(--ticket-review-ticket-review-header-color, $color-text-main);
$ticket-review-ticket-review-wrapper-color-background: var(--ticket-review-ticket-review-wrapper-color-background, $color-background-main);
$ticket-review-ticket-review-wrapper-border: var(--ticket-review-ticket-review-wrapper-border, solid rem-calc(1) $color-border-main);
$ticket-review-ticket-review-wrapper-border-radius: var(--ticket-review-ticket-review-wrapper-border-radius, $wl-border-radius);
$ticket-review-ticket-review-wrapper-box-shadow: var(--ticket-review-ticket-review-wrapper-box-shadow, none);
$ticket-review-ticket-review-questions-error-color-icon-success: var(--ticket-review-ticket-review-questions-error-color-icon-success, $color-text-positive);
$ticket-review-ticket-review-questions-error-color-icon-error: var(--ticket-review-ticket-review-questions-error-color-icon-error, $color-text-danger);
$ticket-review-ticket-review-info-color: var(--ticket-review-ticket-review-info-color, $color-text-subtle);
$ticket-review-ticket-review-info-color-background: var(--ticket-review-ticket-review-info-color-background, $color-background-primary-weak);
$ticket-review-ticket-review-danger-color: var(--ticket-review-ticket-review-danger-color, $color-text-main-contrast);
$ticket-review-ticket-review-danger-color-background: var(--ticket-review-ticket-review-danger-color-background, $color-background-danger-base);
$ticket-review-ticket-review-warning-color-background: var(--ticket-review-ticket-review-warning-color-background, $color-background-warning-base);
$ticket-review-ticket-review-success-color: var(--ticket-review-ticket-review-success-color, $color-text-main-contrast);
$ticket-review-ticket-review-success-color-background: var(--ticket-review-ticket-review-success-color-background, $color-background-positive-base);

// Sheet
$sheet-color-background: var(--sheet-color-background, $white);
$sheet-color-backdrop: var(--sheet-color-backdrop, #06232c8f);
$sheet-color-header-title: var(--sheet-color-header-title, #0e0e0e);
$sheet-color-bar: var(--sheet-color-bar, #0e0e0e);
$sheet-color-text-subtle: var(--sheet-color-text-subtle, $color-text-subtle);

$sheet-backdrop-color: var(--sheet-backdrop-color, #06232c8f);
$sheet-header-border-color: var(--sheet-header-border-color, $gray-600);
$sheet-container-border-radius: var(--sheet-container-border-radius, rem-calc(16));

//Text selection
$selection-text-color: var(--selection-text-color, $text-default);
$selection-color-background: var(--selection-color-background, $highlight);

// Email Update
$email-update-card-color-background: var(--email-update-card-color-background, $white);
$email-update-card-color-text: var(--email-update-card-color-text, $black);
$email-update-card-border-radius: var(--email-update-card-border-radius, 0);
$email-update-card-insets-radius: var(--email-update-card-insets-radius, rem-calc(12));
$email-update-card-width: var(--email-update-card-width, rem-calc(256));
$email-update-card-header-padding: var(--email-update-card-header-padding, rem-calc(16 0));
$email-update-card-figure-margin: var(--email-update-card-figure-margin, rem-calc(32 0 16));
$email-update-card-figure-maxwidth: var(--email-update-card-figure-width, rem-calc(128));
$email-update-card-title-text-color: var(--ticket-qr-card-title-text-color, $color-text-accent);

//Plan banner
$plan-banner-icon-color: var(--plan-banner-icon-color, #6f41d7);
$plan-banner-text-color: var(--plan-banner-text-color, $color-palette-neutral-800);
$plan-banner-background-color: var(--plan-banner-background-color, #f0ebfd);

//Voucher
$voucher-color-text: var(--voucher-color-text, $button-color-foreground-tertiary);
$voucher-text-transform: var(--voucher-text-transform, none);
$voucher-font-family: var(--voucher-font-family, $button-font-family);

// KakaoTalk button
$kakao-talk-button-color-background: var(--kakao-talk-button-color-background, $color-background-primary-strong);
$kakao-talk-button-color-border: var(--kakao-talk-button-color-border, $color-palette-neutral-white);

//Seating selector
$free-seating-selector-title-color: var(--free-seating-selector-title-color, $color-text-main);
$free-seating-selector-sidebar-dimension-radius: var(--free-seating-selector-sidebar-dimension-radius, 0);
$free-seating-selector-sidebar-countdown-dimension-radius: var(--free-seating-selector-sidebar-countdown-dimension-radius, $border-radius-xs);
$category-filter-dimension-radius: var(--category-filter-dimension-radius, $border-radius-xs);
$free-seating-selector-header-bg-color: var(--free-seating-selector-header-bg-color, $gray-200);
$free-seating-selector-header-fg-color: var(--free-seating-selector-header-fg-color, $color-text-main);

$countdown-dimension-radius: var(--countdown-dimension-radius, $border-radius-xs);

// Redefine bootstrap variables
$enable-smooth-scroll: false;

//Inputs
$input-border-radius: var(--input-border-radius, $border-radius-xs);
$input-focus-visible-border-color: var(--input-focus-visible-border-color, $color-palette-neutral-black);

//BQ
$booking-questions-form-background-main: var(--booking-questions-form-background-main, $color-background-main);
$booking-questions-form-helper-display: var(--booking-questions-form-helper-display, none);

// Bundle
$bundle-header-background-color: var(--plan-header-hero-curve-background);

// Ticket actions
$ticket-actions-img-kakao-talk: url('../../assets/images/icons/ticket-actions/kakao-talk-logo.svg');
$ticket-actions-img-transfer: url('../../assets/images/icons/ticket-actions/icon-transfer-accent.svg');
$ticket-actions-img-reschedule: url('../../assets/images/icons/ticket-actions/calendar-edit.svg');
$ticket-actions-img-filter: brightness(0) saturate(100%) invert(0%) sepia(75%) saturate(2856%) hue-rotate(355deg) brightness(103%) contrast(84%);

:root {
  --white: #{$white};
  --black: #{$black};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --gray-900: #{$gray-900};
  --dark-gray: #{$dark-gray};
  --text-default: #{$text-default};
  --text-neutral: #{$text-neutral};

  --primary-color: #{$primary-color};

  --primary-color-light: #{$primary-color--light};
  --primary-color-dark: #{$primary-color--dark};

  --secondary-color: #{$secondary-color};

  --warning: #{$warning};
  --confirmation: #{$confirmation};
  --border: #d3d3d3;

  --neutral-background: #{$neutral-background};

  --cart-separator-style: dotted;
  --cart-separator-size: rem-calc(2);
  --cart-separator: var(--cart-separator-size) var(--cart-separator-style) var(--border);

  --box-shadow-color: rgba(186, 178, 182, 0.6);

  --tab-item-color-border-active: #{$tab-item-color-border-active};

  --border-style: solid #{rem-calc(1)} var(--border);

  --link-color: #fff;

  --button-dimension-height-large: 3rem;
  --payment-methods-button-stripe-theme: #{$payment-methods-button-stripe-theme};
}
