.section-static-content {
  padding: rem-calc(102 0 32);

  background: $color-background-main;

  h2 {
    font-size: rem-calc(22);
  }

  h4 {
    font-size: rem-calc(20);
  }

  h2,
  h4 {
    margin: rem-calc(16 0 8);

    font-weight: bold;
  }
}
