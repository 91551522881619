.toggle {
  display: inline-block;

  text-align: left;

  &__control {
    position: relative;

    display: inline-flex;
    align-items: center;
    width: rem-calc(56);
    min-width: rem-calc(56);
    height: rem-calc(32);
    padding: rem-calc(0 4);

    background-color: $color-palette-neutral-300;
    border: solid rem-calc(1) $color-border-main;
    border-radius: rem-calc(50);

    transition: background-color ease-in-out 0.3s;

    &::after {
      display: block;
      width: rem-calc(20);
      height: rem-calc(20);

      background-color: $color-palette-neutral-white;
      border-radius: rem-calc(50);
      box-shadow: 0 2px 2px 0 #06232c29;

      transition: all ease-in-out 0.3s;

      content: '';
    }

    &--error {
      background-color: $color-background-danger-strong;
    }

    .input-checked-icon {
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(24);
      height: rem-calc(24);

      transform: translateX(rem-calc(22));
      visibility: hidden;
      @include rtl {
        transform: translateX(rem-calc(-22));
      }
    }
  }

  input {
    display: none;

    &:checked {
      ~ label .toggle__control {
        background-color: $color-background-positive-base;

        &--error {
          background-color: $color-background-danger-strong;
        }

        &::after {
          width: rem-calc(24);
          height: rem-calc(24);

          transform: translateX(rem-calc(22));
          @include rtl {
            transform: translateX(rem-calc(-22));
          }
        }

        .input-checked-icon {
          z-index: 1;

          color: $color-palette-positive-700;

          visibility: visible;

          transition: visibility ease-in-out 0.3s;
          transition-delay: 0.2s;
        }
      }
    }
  }

  &__label {
    display: flex;
    gap: rem-calc(12);
    align-items: center;
    margin-bottom: 0;

    font-size: $font-size-body-small;

    cursor: pointer;

    &--position-start {
      flex-direction: row-reverse;
    }

    &--size {
      &-base {
        font-size: $font-size-body-base;
      }

      &-lg {
        font-size: $font-size-h4;
      }

      &-sm {
        font-size: $font-size-body-small;
      }

      &-xs {
        font-size: $font-size-caption;
      }
    }
  }
}
