.otp-header {
  display: flex;
  flex-direction: column;

  &__title {
    margin-block-end: rem-calc(8);
  }
}

.otp-text {
  color: $color-text-subtle;
}

.request-otp {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-block-end: rem-calc(24);
  }

  &__link {
    @include regular-link;
  }

  &__request-message {
    font-weight: $font-weight-bold;

    &--success {
      color: $color-text-positive;
    }

    &--error {
      color: $color-text-danger;
    }
  }
}

.request-otp-resent {
  display: flex;
  gap: rem-calc(8);
  justify-content: center;
}
