.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(lg) {
    border-inline-start: rem-calc(1) solid $color-border-main;
  }

  &__button {
    @include main-link();
    @include no-style-button;
    position: relative;

    display: block;

    color: $navbar-action-color-text-primary;
    font-size: $navbar-font-size;
    font-family: $navbar-font-family;
    text-transform: uppercase;

    &:hover,
    &.active {
      color: $navbar-action-color-text-primary-hover;
    }

    @include media-breakpoint-up(lg) {
      margin-inline-start: $navbar-dimensions-gap;
    }
  }
}

.language-selector-dropdown {
  position: relative;

  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: start;
  }

  &__button {
    @include main-link($language-selector-color, $language-selector-hover-color);
    display: inline-flex;
    gap: rem-calc(4);
    padding: 0;

    font-weight: $language-selector-font-weight;
    font-size: $navbar-font-size;
    font-family: $navbar-font-family;

    background: none;
    border: 0;
  }

  &__menu {
    position: absolute;
    left: rem-calc(-16);

    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    width: auto;
    padding: rem-calc(16);

    list-style: none;
    background-color: $language-selector-background-color;
    border: $language-selector-border;
    box-shadow: $language-selector-box-shadow;

    &--inverted {
      right: rem-calc(-16);
      left: initial;
    }

    @include media-breakpoint-down(lg) {
      position: relative;
      left: auto;

      padding: 0;

      border: 0;
      padding-block-start: rem-calc(8);
    }
  }

  &__menu-link {
    display: block;
    padding: rem-calc(4 8);

    color: $navbar-action-menu-link;
    font: $font-body-base-regular;

    border-radius: $border-radius-xs;

    &:hover {
      color: $language-selector-hover-color;

      background-color: $language-selector-hover-background-color;
    }
  }
}
