:root {
  --sheet-padding: 1rem;
}

.checkout__terms-and-conditions {
  padding: rem-calc(16) 0 rem-calc(24) 0;

  color: $color-text-subtle;

  &-wrapper {
    display: flex;
    gap: rem-calc(8);
    align-items: flex-start;

    .gdpr__term-group {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: rem-calc(4);

    font: $font-body-small-bold;
  }

  &__text {
    font: $font-body-small-regular;
  }

  a,
  &__link {
    color: $color-text-subtle;

    cursor: pointer;

    &:hover {
      color: $color-text-subtle;
    }
  }

  &__link {
    padding: 0;

    font: $font-body-small-regular;

    background-color: transparent;

    border: 0;
  }

  &__sheet {
    &__text {
      max-height: rem-calc(600);

      font-size: rem-calc(14);
      line-height: rem-calc(20);

      a {
        color: $color-text-subtle;

        cursor: pointer;

        &:hover {
          color: $color-text-subtle;
        }
      }
    }

    &__close-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  hr {
    margin: rem-calc(8) 0;

    border: 0;

    @include media-breakpoint-up(md) {
      margin: rem-calc(16) 0;

      border-top: 1px solid;
    }
  }
}
