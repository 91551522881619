.calendar {
  max-width: rem-calc(400);
  margin: 0 auto;
  padding-block: rem-calc(16 12);

  border-block-end: rem-calc(1) solid $color-border-main;

  .ngb-dp-header {
    display: none;
  }

  &__month {
    overflow: hidden;
  }

  .ngb-dp-week {
    margin: 0 rem-calc(-4);
  }

  .ngb-dp-weekdays {
    background: $ticket-selector-color-background;
    border-block-end: rem-calc(1) solid $color-border-main;
  }

  .ngb-dp-weekday {
    flex: 1 1 0;
    min-width: 0;
    height: auto;
    margin: rem-calc(0 4);
    padding: rem-calc(8 0);

    color: $color-palette-neutral-500;
    font: $font-body-small-bold;
    text-transform: uppercase;
  }

  .ngb-dp-day {
    position: relative;

    flex: 1 1 0;
    min-width: 0;

    height: auto;
    min-height: rem-calc(48);

    margin: rem-calc(4);
    padding-bottom: calc(100% / 7 - #{rem-calc(8)});

    font: $font-body-small-regular;

    outline: none;
    aspect-ratio: 1;

    &.hidden {
      display: block;

      visibility: hidden;
    }

    &.ngb-dp-today,
    &.ngb-dp-today.disabled {
      .calendar__date {
        color: $color-text-accent;
        font-weight: $font-weight-bold;
      }
    }

    .calendar__date {
      color: $color-text-main;

      border: rem-calc(1) solid transparent;
      border-radius: $ticket-selector-item-dimensions-border-radius;

      &::after {
        position: absolute;
        bottom: rem-calc(8);
        left: 50%;

        display: block;
        width: rem-calc(4);
        height: rem-calc(4);

        text-align: center;

        background-color: $color-background-primary-base;
        border-radius: rem-calc(20);
        transform: translateX(-50%);

        content: '';
      }

      &:not(.calendar__date--selected):hover {
        border-color: $ticket-selector-item-color-border-available-hover;
      }

      .calendar__date-check {
        display: none;
      }

      &--soldout {
        color: $color-action-text-disable;

        background-color: $color-action-background-disable;
        border-radius: rem-calc(4);
        cursor: not-allowed;

        &:not(.calendar__date--selected):hover {
          border-color: transparent;
        }

        &::after {
          content: none;
        }

        .calendar__bar {
          &__chunk {
            &--best-price {
              opacity: 0;
            }

            &--available-low {
              opacity: 0;
            }
          }
        }
      }

      &--selected {
        color: $ticket-selector-item-color-foreground-selected;

        border: rem-calc(1) solid $ticket-selector-item-color-border-selected;

        .calendar__date-check {
          position: absolute;
          top: rem-calc(-16);
          right: rem-calc(-16);

          display: inherit;
          align-items: flex-end;
          width: rem-calc(32);
          height: rem-calc(32);

          font-size: rem-calc(10);
          text-align: center;

          background: $ticket-selector-item-check-color-background-selected;
          transform: rotate(-45deg);
        }

        &.calendar__date--soldout {
          color: $color-action-text-disable;

          border-color: $color-action-border-disable-contrast;

          .calendar__date-check {
            background: $color-action-background-disable-contrast;
          }
        }
      }
    }

    &.disabled {
      .calendar__date {
        color: $color-action-text-disable;

        border: unset;

        &::after {
          content: none;
        }
      }
    }
  }

  .ngb-dp-months {
    display: block;
  }

  &__title {
    @include auto;
    color: $color-text-main;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
  }

  &__picker {
    display: block;

    border: unset;
  }

  &__date {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    span {
      position: relative;
    }
  }

  &--availability {
    .ngb-dp-day {
      .calendar__date {
        color: $ticket-selector-color-foreground;
        font-weight: $font-weight-bold;
        font-size: rem-calc(16);

        background-color: $ticket-selector-item-color-background-available;

        &::after {
          bottom: rem-calc(2);

          width: rem-calc(20);
          height: rem-calc(3);

          background-color: transparent;
        }

        &--selected {
          background-color: $ticket-selector-item-color-background-selected;

          .calendar__date-check {
            top: rem-calc(-8);
            right: rem-calc(-8);

            width: rem-calc(16);
            height: rem-calc(16);

            fv-fa-icon {
              display: none;
            }
          }
        }

        &--soldout {
          color: $color-action-text-disable;

          background-color: $color-action-background-disable;
        }

        &--available-medium {
          &::after {
            background-color: $color-background-warning-base;
          }
        }

        &--available-low {
          &::after {
            background-color: $color-background-danger-base;
          }
        }
      }

      &.disabled {
        .calendar__date {
          font-weight: $font-weight-base;

          background-color: unset;
        }
      }
    }
  }
}

.calendar-price-legend {
  margin: rem-calc(12 0 -8);

  color: $color-text-subtle;
  font-size: $font-size-body-small;
  line-height: rem-calc(16);
}

.calendar-legend {
  display: flex;
  flex-wrap: wrap;
  margin: rem-calc(16 0 0 0);

  color: $color-text-main;
  font: $font-body-small-regular;
  column-gap: rem-calc(16);

  &__item {
    color: var(--color);
    font-weight: $font-weight-bold;

    &--availability-medium {
      --color: #{$color-text-warning};
    }

    &--availability-low {
      --color: #{$color-text-danger};
    }

    &--best-price {
      --color: #{$color-background-accent-base};
    }

    &::before {
      display: inline-block;
      width: 1.25rem;
      height: 0.125rem;
      margin-top: -0.0625rem;
      margin-right: 0.25rem;

      vertical-align: middle;

      background-color: var(--color);
      border-radius: 0.25rem;

      content: '';
    }
  }
}

.calendar--best-prices .calendar {
  &__date {
    line-height: rem-calc(24);
  }

  &__price {
    height: rem-calc(16);

    color: $color-palette-neutral-500;
    font-weight: $font-weight-regular;
    font-size: rem-calc(10);
    line-height: rem-calc(16);
  }

  &__bar {
    display: flex;
    gap: rem-calc(2);
    width: rem-calc(24);
    height: rem-calc(2);

    &__chunk {
      width: 100%;

      border-radius: rem-calc(4);

      &--available-low {
        background-color: $color-background-danger-base;
      }

      &--best-price {
        background-color: $color-background-accent-base;
      }
    }
  }
}
