.plan-user-reviews {
  fv-rating {
    color: $rating;
  }

  &__info {
    display: flex;
    flex-flow: column;
    gap: rem-calc(8);
    margin-bottom: rem-calc(16);

    &__item {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: rem-calc(8);
      align-items: center;

      &__icon-wrapper {
        display: grid;

        width: rem-calc(34);
        height: rem-calc(34);

        font-size: $font-size-body-small;

        border: rem-calc(1) solid $color-border-main;
        border-radius: $border-radius-xl;

        place-items: center;
      }

      &__label {
        color: $color-text-subtle;

        font: $font-body-small-bold;
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(12);

    .average {
      font-weight: $font-weight-bold;
      font-size: rem-calc(36);
      line-height: rem-calc(48);

      &__over {
        font-weight: $font-weight-normal;
        font-size: rem-calc(16);
      }
    }

    .average-wrapper {
      flex: 1;

      font-size: rem-calc(14);
      margin-inline-start: rem-calc(16);
    }
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;

    height: 100%;

    margin: 0;

    padding: 0 0 rem-calc(12);

    overflow-x: auto;
    overflow-y: hidden;

    list-style: none;

    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  &__item {
    @include shrink;
    width: rem-calc(232);
    padding: rem-calc(12);
    margin-inline-end: rem-calc(16);

    font-size: rem-calc(14);

    background-color: $white;

    border: solid rem-calc(1) $color-border-main;
    border-radius: $box-wrapper-dimensions-border-radius;

    &:last-of-type {
      margin-inline-end: rem-calc(0);
    }

    .item-wrapper {
      display: flex;
      gap: rem-calc(12);
      margin-bottom: rem-calc(12);

      .user-info {
        flex: 1;
        min-width: 0;

        color: $text-default;

        .name {
          overflow: hidden;

          font: $font-body-base-bold;

          white-space: nowrap;

          text-overflow: ellipsis;
        }

        .date {
          color: $gray-700;

          font: $font-caption-bold;
        }
      }

      /* stylelint-disable */
      .rating_item {
        flex: 0 0 auto;

        padding-top: rem-calc(4);

        font-size: rem-calc(12);

        text-align: right;
      }
      /* stylelint-enable */
    }

    .reason {
      color: $text-neutral;

      font: $font-body-base-regular;

      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      /* stylelint-enable */
      overflow: hidden;
    }
  }
}
