.image-viewer-backdrop {
  --bs-backdrop-bg: #{$color-palette-neutral-black};
  --bs-backdrop-opacity: 1;
}

.image-viewer-modal-wrapper {
  .modal-content {
    position: relative;

    display: block;
    max-width: 100%;
    max-height: 100%;

    padding: 0;

    background-color: transparent;
  }
}

.image-viewer {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: rem-calc(16);
  height: calc(var(--vh, 1vh) * 100);

  &__close {
    text-align: right;

    button {
      padding: rem-calc(16 16 8 8);

      color: $color-palette-neutral-white;

      &:focus-visible {
        outline: none;
      }
    }
  }

  &__tabs {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: rem-calc(16);
      justify-content: center;
      margin: 0;
      padding: 0 rem-calc(16);
    }

    li {
      display: flex;
      flex: 0 0 auto;
      gap: rem-calc(4);
      align-items: center;
      height: rem-calc(40);

      color: $color-palette-neutral-white;
      font: $font-body-small-bold;

      border: 1px solid $color-border-main;
      border-radius: $button-dimension-radius;

      transition: all 0.3s ease-in-out;
      padding-inline: rem-calc(12);
      scroll-snap-align: start;

      &:hover {
        cursor: pointer;
      }

      &:active,
      &.active {
        color: $color-palette-neutral-black;

        background-color: $color-palette-neutral-white;
        border-color: transparent;
      }
    }
  }

  &__images {
    overflow: hidden;
  }

  .slideshow {
    --fv-slideshow-image-aspect-ratio: unset;
    --fv-slideshow-list-container-height: 75%;
    --fv-slideshow-arrow-background-left: none;
    --fv-slideshow-arrow-background-right: none;
    --fv-slideshow-image-width: auto;
    --fv-slideshow-image-max-width: 100%;
    --fv-slideshow-counter-top: #{calc(75% + 0.25rem)};
    --fv-slideshow-thumbnails-height: 25%;
    --fv-slideshow-thumbnails-position: relative;
    --fv-slideshow-thumbnails-position-bottom: unset;
    --fv-slideshow-thumbnails-padding: #{rem-calc(32 16)};
    --fv-slideshow-thumbnail-indicator-size: 80px;
    --fv-slideshow-thumbnail-indicator-video-icon-size: 24px;
    --fv-slideshow-thumbnail-indicator-video-icon-font-size: #{rem-calc(14)};
    --fv-slideshow-thumbnail-indicator-video-icon-background: #{rgba($white, 0.8)};
    --fv-slideshow-thumbnail-indicator-video-icon-color: #{$color-palette-neutral-800};

    img {
      @include skeleton($color-palette-neutral-100, $color-palette-neutral-200);
    }

    fv-video {
      .video,
      .poster {
        position: absolute;
        top: 50%;
        left: 50%;

        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;

        transform: translate(-50%, -50%);
      }

      .video--visible + .poster {
        opacity: 0;
      }

      .video--clickable {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .slideshow-thumbnails {
      @include custom-scrollbar($gray-400);

      .thumbnail__video-icon .fa-play {
        transform: translateX(12%);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-viewer {
    .slideshow {
      --fv-slideshow-arrow-width: 25%;

      .slideshow__arrows {
        display: flex;

        &__arrow {
          justify-content: flex-start;
          padding: 0;

          &:last-child {
            justify-content: flex-end;

            fv-fa-icon {
              border-radius: 4px 0 0 4px;
            }
          }

          fv-fa-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem-calc(58);
            height: rem-calc(58);

            background-color: rgba($white, 0.15);
            border-radius: 0 4px 4px 0;

            svg {
              font-size: 1.75rem;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-viewer {
    .slideshow {
      --fv-slideshow-list-container-height: 70%;
      --fv-slideshow-thumbnails-height: 30%;
      --fv-slideshow-thumbnail-indicator-size: 140px;
      --fv-slideshow-counter-top: #{calc(70% - 2rem)};
      --fv-slideshow-thumbnail-indicator-video-icon-size: 42px;
      --fv-slideshow-thumbnail-indicator-video-icon-font-size: #{rem-calc(16)};
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-viewer {
    gap: rem-calc(24);

    &__close {
      text-align: right;

      button {
        padding-top: rem-calc(24);
        padding-inline-end: rem-calc(24);
      }
    }

    &__tabs {
      gap: rem-calc(16);
    }
  }
}
