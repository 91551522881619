.order-item {
  display: flex;
  flex-flow: column;
  max-width: $orders-item-dimensions-max-width;

  background-color: $orders-item-color-background;

  border: $orders-item-border;

  border-radius: $orders-item-radius;
  box-shadow: $orders-item-effect-box-shadow;

  padding-inline: rem-calc(24);

  &__info {
    width: 100%;
  }

  &__info-container {
    display: flex;
    flex-flow: row;
    gap: rem-calc(24);
    padding-block: rem-calc(24);
  }

  &__figure {
    flex: 0 0 rem-calc(72);
    height: rem-calc(72);
    margin: 0;

    overflow: hidden;

    border-radius: $orders-item-image-dimensions-border-radius;
    aspect-ratio: $orders-item-image-dimensions-aspect-ratio-small;

    @include media-breakpoint-up(lg) {
      flex: 0 0 rem-calc(160);
      height: auto;
      aspect-ratio: $orders-item-image-dimensions-aspect-ratio-large;
    }
  }

  &__image {
    width: 100%;

    border-radius: $orders-item-image-dimensions-border-radius;
  }

  &__name {
    margin-bottom: rem-calc(4);

    color: $orders-item-name-text-color;
    font: $order-item-font-small;
    @include media-breakpoint-up(lg) {
      font: $order-item-font-large;
    }
  }

  &__venue {
    display: $orders-item-venue-display;

    color: $orders-item-venue-text-color;
    font: $font-body-small-bold;
    text-transform: $orders-item-venue-text-transform;
    margin-block-end: rem-calc(8);
  }

  &__sub-info {
    display: flex;
    flex-flow: column;

    &--confirmation {
      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
      }
    }

    &-header {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: rem-calc(14);
      padding-block-end: rem-calc(8);

      &-badge {
        margin: 0;
        padding: rem-calc(2 4);

        color: $color-palette-neutral-white;

        font-size: $font-size-caption;

        background-color: $color-background-positive-base;
        border-radius: $border-radius-xs;
      }
    }
  }

  &__list {
    padding: 0;
    margin-block-end: 0;

    .list-item {
      padding: $orders-item-list-item-padding;

      list-style: none;
      border-bottom: $orders-item-list-item-border-bottom;

      &__icon {
        margin-inline-end: rem-calc(4);
      }

      &:last-child {
        border-bottom: 0;
        padding-block-end: 0;
      }
    }
  }

  &__id {
    color: $orders-item-id-text-color;
    text-transform: $orders-item-id-text-transform;
    margin-block-end: 0;
  }

  &__detail-container {
    display: flex;
    justify-content: flex-end;

    padding-block: rem-calc(16);

    border-top: $orders-item-separator;
  }

  &__detail-link-group {
    display: flex;
    gap: rem-calc(8);
    align-items: center;

    color: $order-item-link-color;
  }

  &__link {
    color: $order-item-link-color;
    font-weight: $order-item-link-font-weight;
    text-decoration: $order-item-link-text-decoration;

    cursor: pointer;

    &:hover {
      color: $order-item-link-color-hover;
      text-decoration: $order-item-link-text-decoration-hover;
    }
  }
}
