.kakao-talk {
  &__button {
    color: $color-text-main;

    background-color: $kakao-talk-button-color-background;
    border: rem-calc(1) solid $kakao-talk-button-color-border;
    border-radius: rem-calc(4);
  }

  &__icon {
    width: rem-calc(20);
    height: rem-calc(20);
  }

  &__label {
    flex: 1;
    padding: rem-calc(0 32 0 8);
  }
}

.kakao-talk-send-success {
  display: flex;
  flex-direction: row;
  gap: rem-calc(8);
  align-items: center;
  min-height: $button-dimension-height-large;
  padding: rem-calc(8);

  background-color: $alert-notification-color-background;
  margin-block: rem-calc(16);

  &__text {
    flex: 1;
    margin: 0;
  }

  &__icon {
    font-size: rem-calc(16);
  }
}
