.not-found-image {
  max-width: rem-calc(88);
  margin-bottom: rem-calc(16);
  object-fit: contain;

  @include media-breakpoint-up(md) {
    max-width: rem-calc(128);
    margin: 0 rem-calc(16);
  }
}
