$font-stack: var(--font-stack, ('Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif)) !default;
$font-stack-secondary: var(--font-stack-secondary, $font-stack);
$font-stack-tertiary: var(--font-stack-tertiary, $font-stack);
$font-weight-regular: var(--font-weight-regular, 400);
$font-weight-bold: var(--font-weight-bold, 600);
$font-weight-semi-bold: var(--font-weight-semi-bold, $font-weight-bold);

$font-size-display: var(--font-size-display, rem-calc(48));
$font-size-display-medium: var(--font-size-display-medium, rem-calc(40));
$font-size-display-small: var(--font-size-display-small, rem-calc(32));
$font-size-h1: var(--font-size-h1, rem-calc(28));
$font-size-h2: var(--font-size-h2, rem-calc(24));
$font-size-h3: var(--font-size-h3, rem-calc(20));
$font-size-h4: var(--font-size-h4, rem-calc(18));
$font-size-h5: var(--font-size-h5, rem-calc(16));
$font-size-h6: var(--font-size-h6, rem-calc(14));
$font-size-body-base: var(--font-size-body-base, rem-calc(16));
$font-size-body-small: var(--font-size-body-small, rem-calc(14));
$font-size-caption: var(--font-size-caption, rem-calc(12));
$font-size-tiny: var(--font-size-tiny, rem-calc(10));

$font-line-height-display: var(--font-line-height-display, rem-calc(56));
$font-line-height-display-medium: var(--font-line-height-display-medium, rem-calc(48));
$font-line-height-display-small: var(--font-line-height-display-small, rem-calc(40));
$font-line-height-h1: var(--font-line-height-h1, rem-calc(32));
$font-line-height-h2: var(--font-line-height-h2, rem-calc(28));
$font-line-height-h3: var(--font-line-height-h3, rem-calc(24));
$font-line-height-h4: var(--font-line-height-h4, rem-calc(20));
$font-line-height-h5: var(--font-line-height-h5, rem-calc(20));
$font-line-height-body-base: var(--font-line-height-body-base, rem-calc(24));
$font-line-height-body-small: var(--font-line-height-body-small, rem-calc(20));
$font-line-height-caption: var(--font-line-height-body-caption, rem-calc(16));
$font-line-height-tiny: var(--font-line-height-body-tiny, rem-calc(14));

$font-display: normal normal $font-weight-bold $font-size-display/$font-line-height-display $font-stack-secondary;
$font-display-medium: normal normal $font-weight-bold $font-size-display-medium/$font-line-height-display-medium $font-stack-secondary;
$font-display-small: normal normal $font-weight-bold $font-size-display-small/$font-line-height-display-small $font-stack-secondary;
$font-h1: normal normal $font-weight-bold $font-size-h1/$font-line-height-h1 $font-stack-secondary;
$font-h2: normal normal $font-weight-bold $font-size-h2/$font-line-height-h2 $font-stack-secondary;
$font-h3: normal normal $font-weight-bold $font-size-h3/$font-line-height-h3 $font-stack-secondary;
$font-h4: normal normal $font-weight-bold $font-size-h4/$font-line-height-h4 $font-stack-secondary;
$font-h5: normal normal $font-weight-bold $font-size-h5/$font-line-height-h4 $font-stack-secondary;
$font-h6: normal normal $font-weight-bold $font-size-h6/$font-line-height-h4 $font-stack-secondary;
$font-body-base-regular: normal normal $font-weight-regular $font-size-body-base/$font-line-height-body-base $font-stack !default;
$font-body-base-bold: normal normal $font-weight-bold $font-size-body-base/$font-line-height-body-base $font-stack !default;
$font-body-small-regular: normal normal $font-weight-regular $font-size-body-small/$font-line-height-body-small $font-stack !default;
$font-body-small-bold: normal normal $font-weight-bold $font-size-body-small/$font-line-height-body-small $font-stack !default;
$font-caption-regular: normal normal $font-weight-regular $font-size-caption/$font-line-height-caption $font-stack !default;
$font-caption-bold: normal normal $font-weight-bold $font-size-caption/$font-line-height-caption $font-stack !default;
$font-tiny-bold: normal normal $font-weight-bold $font-size-tiny/$font-line-height-tiny $font-stack !default;
$font-tiny-regular: normal normal $font-weight-regular $font-size-tiny/$font-line-height-tiny $font-stack !default;

:root {
  --font-display: #{$font-display};
  --font-h1: #{$font-h1};
  --font-h2: #{$font-h2};
  --font-h3: #{$font-h3};
  --font-h4: #{$font-h4};
  --font-h5: #{$font-h5};
  --font-h6: #{$font-h6};
  --font-body-base-regular: #{$font-body-base-regular};
  --font-body-base-bold: #{$font-body-base-bold};
  --font-body-small-regular: #{$font-body-small-regular};
  --font-body-small-bold: #{$font-body-small-bold};
  --font-caption-regular: #{$font-caption-regular};
  --font-caption-bold: #{$font-caption-bold};
  --font-tiny-regular: #{$font-tiny-regular};
  --font-tiny-bold: #{$font-tiny-bold};
}
