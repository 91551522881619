:root {
  --fv-section-block__background-color: transparent;
  --fv-section-block__border: #{$border-style};
  --fv-section-block__border-radius: #{$wl-border-radius};

  --fv-section-block__title-margin: 0;
  --fv-section-block__title-font-weight: #{$font-weight-bold};
  --fv-section-block__title-font-size-large: #{$font-size-lg};
  --fv-section-block__title-font-size-small: #{$font-size-sm};

  @include media-breakpoint-up(lg) {
    --fv-section-block__padding: #{$box-wrapper-dimensions--padding-large};
  }
}
