.third-party-terms {
  @include flex-column;
  padding-block: rem-calc(16);

  gap: rem-calc(16);

  .form-checkbox {
    display: flex;
    gap: rem-calc(8);
    align-items: flex-start;

    &__label {
      font-size: rem-calc(14);
      line-height: 1.6;

      a {
        color: $color-action-text-primary;

        &:hover {
          color: $color-action-text-primary-hover;
        }
      }
    }
  }
}
