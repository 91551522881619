.checkout__booking-tax {
  &__content-border {
    border-bottom: rem-calc(1) solid $color-border-main;
  }

  &__sheet {
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-block: rem-calc(16);

      &.sheet-content {
        padding: 0;
      }

      &__description {
        display: flex;
        flex-direction: column;

        &__title {
          font-weight: $font-weight-base;
          font-size: rem-calc(16);
        }

        &__subtitle {
          color: $gray-600;
          font-size: rem-calc(14);
        }
      }

      &__total {
        font-weight: $font-weight-bold;
        font-size: rem-calc(16);
      }
    }
  }
}
