.promotion-banner {
  margin-block-start: rem-calc(16);

  @include media-breakpoint-up(md) {
    margin-block-start: rem-calc(40);
  }

  &__picture {
    img {
      width: 100%;
      aspect-ratio: 2.27; /* aspect ratio for 1024x450 */

      border-radius: rem-calc(8);

      @include media-breakpoint-up(md) {
        aspect-ratio: 9.5; /* aspect ratio for 1900x200 */
      }
    }
  }
}
