:root {
  //COLORS
  --fv-button-primary-text-color: #{$button-color-foreground-primary};
  --fv-button-primary-background: #{$button-color-background-primary};
  --fv-button-primary-background-hover: #{$button-color-background-primary-hover};

  --fv-button-dark-text-color: #{$color-palette-neutral-white};
  --fv-button-dark-background: #{$color-palette-neutral-black};

  --fv-button-light-text-color: #{$color-palette-neutral-black};
  --fv-button-light-background: #{$color-palette-neutral-white};
  --fv-button-light-background-hover: #{$color-palette-neutral-200};

  --fv-button-alert-text-color: #{$color-text-danger-contrast};
  --fv-button-alert-background: #{$color-text-danger};

  --fv-button-warning-text-color: #{$color-text-warning-contrast};
  --fv-button-warning-background: #{$color-text-warning};

  --fv-button-success-text-color: #{$color-text-positive-contrast};
  --fv-button-success-background: #{$color-text-positive};

  --fv-button-accent-text-color: #{$color-text-accent-contrast};
  --fv-button-accent-background: #{$color-text-accent};
  --fv-button-accent-background-hover: #{$color-border-accent-strong};

  --fv-button-tertiary-text-color: #{$button-color-foreground-tertiary};
  --fv-button-tertiary-background: #{$button-color-background-tertiary};
  --fv-button-tertiary-background-hover: #{$button-color-background-tertiary-hover};

  --fv-button-color-action-background-disable: #{$color-action-background-disable};
  --fv-button-color-action-text-disable: #{$color-action-text-disable};
  --fv-button-color-action-border-disable: #{$color-action-border-disable};

  //BORDER
  --fv-button-border: 1px solid transparent;
  --fv-button-border-radius: #{$button-dimension-radius};

  //FONTS
  --fv-button-font-weight: #{$font-weight-bold};

  //ANIMATIONS
  --fv-button-animation-duration: 0.3s;
  --fv-button-animation-timing-function: ease-in-out;

  //ELEVATION
  --fv-button-elevation-box-shadow: none;
  --fv-button-elevation-box-shadow-hover: none;

  //ACTIONS
  --fv-button-hover-transform: #{$button-effect-transform-hover};
  --fv-button-hover-icon-transform: none;
  --fv-button-focus-active-transform: scale(1);
}
