.promotions {
  &__wrapper {
    padding-block-end: rem-calc(16);

    border-bottom: rem-calc(1) solid $color-palette-neutral-200;
  }

  &__banner {
    display: flex;
    gap: rem-calc(16);
    align-items: flex-start;
    padding: rem-calc(12);

    font-size: rem-calc(14);
    line-height: rem-calc(20);

    background-color: $color-palette-positive-100;
    border-radius: rem-calc(8);

    &__message {
      display: flex;
      flex-direction: column;
    }

    &__icon {
      color: $color-palette-positive-500;
    }

    &__link {
      margin-top: rem-calc(4);

      color: $color-palette-positive-500;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.promotions-sheet-button {
  display: flex;
  gap: rem-calc(8);
  align-items: center;
  padding: rem-calc(8);

  color: $button-color-foreground-secondary;
  font-weight: $font-weight-bold;

  &__text {
    letter-spacing: 0;
    text-transform: none;
    text-decoration: underline;
    text-underline-offset: rem-calc(4);
  }
}
