:root {
  --fv-cookies-advice-zindex: #{$z-index-top-app};
  --fv-cookies-advice-font-size: #{$font-size-sm};
  --fv-cookies-advice-color: #{$color-text-main};
  --fv-cookies-advice-background-color: #{$color-palette-neutral-75};
  --fv-cookies-advice-border-radius: #{$border-radius-xs};
  --fv-cookies-advice-box-shadow: #{$box-shadow-small};
  --fv-cookies-advice-link-color: #{$color-text-main};
  --fv-cookies-advice-link-hover-color: #{$color-action-background-primary-hover};
  --fv-cookies-advice-title-font-weight: #{$font-weight-bold};
  --fv-cookies-advice-close-border: #{rem-calc(1) dashed $color-border-main};
  --fv-cookies-advice-close-zindex: #{$z-index-top-app};
  --fv-cookies-advice-close-hover-color: #{$color-action-background-primary-hover};
  --fv-cookies-managment-sheet-title-font-size: #{$font-size-body-base};
  --fv-cookies-managment-sheet-accept-cookies-bg: #{$disabled};
  --fv-cookies-managment-sheet-accept-cookies-accepted-bg: #{$confirmation};
  --fv-cookies-managment-sheet-accept-cookies-blocked-bg: #{$disabled};
  --fv-cookies-managment-sheet-accept-box-shadow: #{$box-shadow-small};
}

.fv-cookie-advice {
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;

  &__message {
    font-size: inherit !important;
  }

  &__button-wrapper {
    display: flex;
    gap: rem-calc(16);
    justify-content: flex-end;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: inherit;
    }

    button {
      width: 50%;
    }
  }

  a {
    font-weight: $font-weight-bold;
    text-decoration: underline !important;
  }
}
