// Do not edit directly
// Generated on Mon, 24 Jul 2023 11:25:02 GMT

:root {
  --color-palette-neutral-50: #fafbfb;
  --color-palette-neutral-75: #fafbfb;
  --color-palette-neutral-100: #f2f3f3;
  --color-palette-neutral-200: #ccd2d8;
  --color-palette-neutral-300: #a7b2ba;
  --color-palette-neutral-400: #7d8e98;
  --color-palette-neutral-500: #536b75;
  --color-palette-neutral-600: #2c4751;
  --color-palette-neutral-700: #06232c;
  --color-palette-neutral-800: #031419;
  --color-palette-neutral-900: #000405;
  --color-palette-neutral-white: #fff;
  --color-palette-neutral-black: #000;
  --color-palette-positive-100: #e8f8f0;
  --color-palette-positive-200: #b5e6cd;
  --color-palette-positive-300: #83d3aa;
  --color-palette-positive-400: #53be88;
  --color-palette-positive-500: #24a865;
  --color-palette-positive-600: #1e9558;
  --color-palette-positive-700: #18824c;
  --color-palette-positive-800: #126d3f;
  --color-palette-positive-900: #0d5932;
  --color-palette-warning-100: #fff4e6;
  --color-palette-warning-200: #ffdaac;
  --color-palette-warning-300: #ffc073;
  --color-palette-warning-400: #ffa639;
  --color-palette-warning-500: #ff8c00;
  --color-palette-warning-600: #df7b00;
  --color-palette-warning-700: #bf6a00;
  --color-palette-warning-800: #9f5800;
  --color-palette-warning-900: #804700;
  --color-palette-danger-100: #fff0f4;
  --color-palette-danger-200: #feb4ca;
  --color-palette-danger-300: #fb78a1;
  --color-palette-danger-400: #f43d7a;
  --color-palette-danger-500: #eb0052;
  --color-palette-danger-600: #d10047;
  --color-palette-danger-700: #b6003c;
  --color-palette-danger-800: #9b0031;
  --color-palette-danger-900: #7e0027;
}

$color-palette-neutral-50: var(--color-palette-neutral-50, #fafbfb) !default;
$color-palette-neutral-75: var(--color-palette-neutral-75, #f6f7f7) !default;
$color-palette-neutral-100: var(--color-palette-neutral-100, #f2f3f3) !default;
$color-palette-neutral-200: var(--color-palette-neutral-200, #ccd2d8) !default;
$color-palette-neutral-300: var(--color-palette-neutral-300, #a7b2ba) !default;
$color-palette-neutral-400: var(--color-palette-neutral-400, #7d8e98) !default;
$color-palette-neutral-500: var(--color-palette-neutral-500, #536b75) !default;
$color-palette-neutral-600: var(--color-palette-neutral-600, #2c4751) !default;
$color-palette-neutral-700: var(--color-palette-neutral-700, #06232c) !default;
$color-palette-neutral-800: var(--color-palette-neutral-800, #031419) !default;
$color-palette-neutral-900: var(--color-palette-neutral-900, #000405) !default;
$color-palette-neutral-white: var(--color-palette-neutral-white, #fff) !default;
$color-palette-neutral-black: var(--color-palette-neutral-black, #000) !default;
$color-palette-primary-100: var(--color-palette-primary-100, #f5e6ec) !default;
$color-palette-primary-200: var(--color-palette-primary-200, #dcafc1) !default;
$color-palette-primary-300: var(--color-palette-primary-300, #c27a97) !default;
$color-palette-primary-400: var(--color-palette-primary-400, #a7466d) !default;
$color-palette-primary-500: var(--color-palette-primary-500, #8a1343) !default;
$color-palette-primary-600: var(--color-palette-primary-600, #7b0f3a) !default;
$color-palette-primary-700: var(--color-palette-primary-700, #6b0b32) !default;
$color-palette-primary-800: var(--color-palette-primary-800, #5a0829) !default;
$color-palette-primary-900: var(--color-palette-primary-900, #490521) !default;
$color-palette-accent-100: var(--color-palette-accent-100, #f0ebfd) !default;
$color-palette-accent-200: var(--color-palette-accent-200, #cfbef6) !default;
$color-palette-accent-300: var(--color-palette-accent-300, #ae92ed) !default;
$color-palette-accent-400: var(--color-palette-accent-400, #8e69e3) !default;
$color-palette-accent-500: var(--color-palette-accent-500, #6f41d7) !default;
$color-palette-accent-600: var(--color-palette-accent-600, #6037be) !default;
$color-palette-accent-700: var(--color-palette-accent-700, #522da5) !default;
$color-palette-accent-800: var(--color-palette-accent-800, #44248b) !default;
$color-palette-accent-900: var(--color-palette-accent-900, #361b71) !default;
$color-palette-positive-100: var(--color-palette-positive-100, #e8f8f0) !default;
$color-palette-positive-200: var(--color-palette-positive-200, #b5e6cd) !default;
$color-palette-positive-300: var(--color-palette-positive-300, #83d3aa) !default;
$color-palette-positive-400: var(--color-palette-positive-400, #53be88) !default;
$color-palette-positive-500: var(--color-palette-positive-500, #24a865) !default;
$color-palette-positive-600: var(--color-palette-positive-600, #1e9558) !default;
$color-palette-positive-700: var(--color-palette-positive-700, #18824c) !default;
$color-palette-positive-800: var(--color-palette-positive-800, #126d3f) !default;
$color-palette-positive-900: var(--color-palette-positive-900, #0d5932) !default;
$color-palette-warning-100: var(--color-palette-warning-100, #fff4e6) !default;
$color-palette-warning-200: var(--color-palette-warning-200, #ffdaac) !default;
$color-palette-warning-300: var(--color-palette-warning-300, #ffc073) !default;
$color-palette-warning-400: var(--color-palette-warning-400, #ffa639) !default;
$color-palette-warning-500: var(--color-palette-warning-500, #ff8c00) !default;
$color-palette-warning-600: var(--color-palette-warning-600, #df7b00) !default;
$color-palette-warning-700: var(--color-palette-warning-700, #bf6a00) !default;
$color-palette-warning-800: var(--color-palette-warning-800, #9f5800) !default;
$color-palette-warning-900: var(--color-palette-warning-900, #804700) !default;
$color-palette-danger-100: var(--color-palette-danger-100, #fff0f4) !default;
$color-palette-danger-200: var(--color-palette-danger-200, #feb4ca) !default;
$color-palette-danger-300: var(--color-palette-danger-300, #fb78a1) !default;
$color-palette-danger-400: var(--color-palette-danger-400, #f43d7a) !default;
$color-palette-danger-500: var(--color-palette-danger-500, #eb0052) !default;
$color-palette-danger-600: var(--color-palette-danger-600, #d10047) !default;
$color-palette-danger-700: var(--color-palette-danger-700, #b6003c) !default;
$color-palette-danger-800: var(--color-palette-danger-800, #9b0031) !default;
$color-palette-danger-900: var(--color-palette-danger-900, #7e0027) !default;
