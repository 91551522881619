.modal {
  &__close {
    color: $color-text-subtle;
    text-align: center;

    cursor: pointer;
  }
}

.modal-close-button {
  position: absolute;
  top: rem-calc(8);
  right: rem-calc(8);
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(44);
  height: rem-calc(44);

  color: $modal-close-button-foreground-color-primary;
  font-size: $font-size-h4;

  cursor: pointer;

  &:hover {
    .modal-close-button__icon {
      background-color: $modal-close-button-effect-transform-hover;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(32);
    height: rem-calc(32);

    border-radius: rem-calc(100);
  }
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 20px);
  overflow: auto;

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - 60px);
  }
}

.modal-content {
  display: flex;
  flex-flow: column;
  gap: rem-calc(16);
  width: 100%;
  max-width: rem-calc(480);
  margin: 0 auto;

  padding: $box-wrapper-dimensions-padding-small;

  background: $color-background-main;
  border: rem-calc(1) solid $color-border-main;
  border: $box-wrapper-border;
  border-radius: $box-wrapper-dimensions-border-radius;
  box-shadow: $modal-content-box-shadow;

  @include media-breakpoint-up(lg) {
    padding: $box-wrapper-dimensions--padding-large;
  }
}
