.profile-hero {
  margin-block-start: $hero-margin-block-start-sm;

  position: relative;

  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    background: $hero-color-background;

    content: '';
  }

  &__image {
    width: 100%;
    height: $hero-height-small;
    object-fit: cover;

    filter: $hero-effect-filter;

    @include media-breakpoint-up(md) {
      height: $hero-height-large;
    }
  }

  @include media-breakpoint-up(md) {
    margin-block-start: $hero-margin-block-start-lg;
  }
}

.profile-content {
  margin-block-start: calc(($profile-user-avatar-dimensions-height * -1) / 2);
  padding-block-end: rem-calc(48);

  position: relative;
}
