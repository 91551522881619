.selectable-item {
  border: $payment-methods-section-item-border;
  border-bottom: rem-calc(1) dashed $color-border-main;
  accent-color: $payment-methods-section-item-radio-button-color;
  margin-block-end: $payment-methods-section-item-dimensions-margin-bottom-sm;

  @include media-breakpoint-up(lg) {
    margin-block-end: $payment-methods-section-item-dimensions-margin-bottom-lg;
  }

  &__header {
    display: flex;
    gap: rem-calc(8);
    align-items: center;
    justify-content: flex-start;
    padding: rem-calc(16 0);

    cursor: pointer;

    @include media-breakpoint-up(lg) {
      padding: rem-calc(24 0);
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: rem-calc(16);
    align-items: center;
  }

  &__media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(48);
    height: rem-calc(32);
    padding: rem-calc(2);

    background-color: $color-palette-neutral-white;
    border-radius: rem-calc(4);

    fv-fa-icon {
      color: $color-palette-neutral-500;
    }

    img {
      width: 100%;
    }
  }

  &__text-container {
    flex: 1 1 100%;
  }

  &__radio-button-container {
    display: flex;
    gap: rem-calc(16);
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    margin: 0;

    color: $color-text-main;
    font-size: rem-calc(16);
    line-height: rem-calc(20);

    span {
      display: block;

      color: $color-text-subtle;
      font-size: rem-calc(14);

      @include media-breakpoint-up(lg) {
        display: inline;

        color: inherit;
        font-size: inherit;
      }
    }
  }

  &__content {
    &--hidden {
      display: none;
    }
  }

  &__icon {
    font-size: rem-calc(24);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(20);
    }
  }
}

.skeleton {
  &__block {
    @include skeleton($color-palette-neutral-100, $color-palette-neutral-200);

    height: rem-calc(16);

    border-radius: rem-calc(4);
  }

  &__radio {
    width: rem-calc(14);
  }

  &__img {
    width: 90%;
  }

  &__text {
    width: 80%;
    max-width: rem-calc(200);
  }
}
