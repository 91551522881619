.calendar__header {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: rem-calc(8);
  padding-bottom: rem-calc(16);

  border-bottom: rem-calc(1) solid $color-border-main;

  fv-default-scroll-controls {
    top: rem-calc(-7);
  }
}

.list-chip {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;

  list-style: none;
  scroll-snap-type: x mandatory;
  scroll-padding: rem-calc(0 24);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &__item-wrapper {
    flex: 0 0 auto;
  }

  &__item {
    min-width: $chip-dimensions-width;
    margin-right: rem-calc(16);
    padding: rem-calc(8) rem-calc(16);

    color: $ticket-selector-color-foreground;
    font: $font-caption-bold;
    white-space: nowrap;
    text-align: center;
    text-transform: $chip-text-transform;

    border: $chip-border;
    border-radius: $chip-dimensions-border-radius;
    cursor: pointer;

    user-select: none;
    scroll-snap-align: start;

    &--selected {
      color: $chip-color-foreground-selected;

      background-color: $chip-color-background-selected;
      border-color: $chip-color-border-selected;
    }

    &--user-interacted {
      scroll-snap-align: center;
    }

    .list-chip__item-wrapper:last-child & {
      margin-right: 0;
    }
  }
}
