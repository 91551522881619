html {
  overscroll-behavior: none;
}

body {
  /* stylelint-disable unit-no-unknown */
  min-height: 100dvh;
  /* stylelint-enable unit-no-unknown */
}

.page:has(.free-seating-selector) {
  display: flex;
  flex-direction: column;
  /* stylelint-disable unit-no-unknown */
  min-height: 100dvh !important;
  /* stylelint-enable unit-no-unknown */

  ng-component,
  wl-free-seating-selector-sidebar {
    display: contents;
  }
}

.free-seating-selector {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__container {
    position: relative;

    display: flex;
    flex: 1;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: rem-calc(24);
      padding-block-start: rem-calc(24);
    }
  }

  &__map {
    position: relative;

    flex: 1;
    margin-block-end: rem-calc(140);

    @include media-breakpoint-up(lg) {
      margin-block-end: 0;
    }
  }
}

.free-seating-selector-header {
  color: $free-seating-selector-header-fg-color;

  background-color: $free-seating-selector-header-bg-color;
  padding-block: rem-calc(16);

  &__container {
    display: flex;
    gap: rem-calc(16);
    align-items: center;
  }

  &__img {
    width: rem-calc(56);
    aspect-ratio: 1;

    border-radius: $box-wrapper-dimensions-border-radius;
  }

  &__venue {
    font: $font-body-small-regular;
    margin-block-end: 0;
  }

  &__title {
    @include large-title;
    color: $free-seating-selector-header-fg-color;
    font-family: $font-stack-secondary;
    margin-block-end: 0;
  }
}
