:root {
  --fv-alert-color-danger: #{$alert-notification-text-color-danger};
  --fv-alert-color-success: #{$alert-notification-text-color-success};
  --fv-alert-color-warning: #{$color-palette-warning-800};
  --fv-alert-color-info: #{$color-palette-neutral-800};
  --fv-alert-color-accent: #{$color-palette-accent-800};

  --fv-alert-background-color-danger: #{$alert-notification-color-background-danger};
  --fv-alert-background-color-success: #{$alert-notification-color-background-success};
  --fv-alert-background-color-warning: #{$color-palette-warning-100};
  --fv-alert-background-color-info: #{$color-palette-neutral-100};
  --fv-alert-background-color-accent: #{$color-palette-accent-100};

  // In case we need a "contrast" version for alerts, the following vars need to be defined.
  //--fv-alert-border-color-danger-contrast: #{};
  //--fv-alert-border-color-success-contrast: #{};
  //--fv-alert-border-color-warning-contrast: #{};
  //--fv-alert-border-color-info-contrast: #{};
  //--fv-alert-border-color-accent-contrast: #{};
}
