.plan-hero {
  position: relative;

  width: 100%;
  height: 100%;

  &__curve {
    display: none;
  }

  &__content {
    width: 100%;
    max-width: $page-dimensions-max-width;
    padding-inline: 1rem;
    margin-inline: auto;

    .plan-header {
      position: relative;

      margin: 0 -1rem;

      &__media {
        .slideshow {
          --fv-slideshow-border-radius: 0;
          --fv-slideshow-image-aspect-ratio: #{$plan-header-media-aspect-ratio};
          --fv-slideshow-dot-indicator-position-bottom: #{calc($plan-header-info-rounded-top + 1.35rem)};
          --fv-slideshow-bars-position-bottom: #{calc($plan-header-info-rounded-top + #{rem-calc(12)})};

          max-width: rem-calc(565);
          margin: 0 auto;

          @include media-breakpoint-up(sm) {
            --fv-slideshow-image-aspect-ratio: #{$plan-header-media-aspect-ratio-desktop};
          }
        }

        .mobile-gallery,
        .desktop-gallery {
          position: relative;
        }

        .desktop-gallery {
          display: none;

          grid-template-rows: var(--grid-rows, calc(50% - #{rem-calc(4)}) calc(50% - #{rem-calc(4)}));
          grid-template-columns: var(--grid-columns);

          width: 100%;
          height: 100%;

          overflow: hidden;

          column-gap: rem-calc(8);

          border-radius: $box-wrapper-dimensions-border-radius;
          row-gap: rem-calc(8);

          .fa-play {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-38%, -47%);
          }

          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .mobile-gallery {
          &--with-videos {
            .open-images-viewer {
              bottom: calc(#{$plan-header-info-rounded-top} + #{rem-calc(28)});
            }
          }
        }

        .open-images-viewer {
          position: absolute;
          right: rem-calc(12);
          bottom: calc(#{$plan-header-info-rounded-top} + #{rem-calc(12)});

          .button {
            position: relative;

            z-index: 2;

            min-width: rem-calc(92);

            height: rem-calc(32);

            color: $color-palette-neutral-black;

            font-size: $font-size-caption;

            background-color: $color-palette-neutral-white;
            border: 0;
            border-radius: $border-radius;

            transition: background-color 0.3s ease-in-out;

            &:hover {
              background-color: $color-palette-neutral-100;
            }
          }
        }
      }

      &__info {
        position: relative;

        display: flex;
        flex-direction: column;
        padding: rem-calc(16) rem-calc(16) rem-calc(20) rem-calc(16);

        &::before {
          position: absolute;
          top: calc(calc($plan-header-info-rounded-top - 2px) * -1);
          left: 0;
          z-index: 2;

          width: 100vw;
          height: $plan-header-info-rounded-top;

          background-color: $page-background-color;
          border-radius: $plan-header-info-rounded-top $plan-header-info-rounded-top 0 0;

          content: '';
        }

        .venue {
          margin: 0;

          color: $color-text-subtle;

          font: $font-size-h6;
        }

        .labels {
          display: flex;
          flex-flow: row wrap;
          gap: rem-calc(8);
          align-items: center;
          margin-bottom: rem-calc(8);

          &:empty {
            display: none;
          }
        }

        .title {
          margin: 0;

          font-weight: $font-weight-bold;

          font-size: $plan-header-info-title-font-size-sm;
          font-family: $plan-header-info-title-font-family;
          line-height: $plan-header-info-title-font-line-height-sm;
          text-transform: $plan-header-info-title-text-transform;

          &__site-name {
            display: flex;
            flex-direction: column;

            span {
              color: $color-palette-neutral-600;
              font-weight: normal;
              font-size: rem-calc(16);
            }
          }
        }

        .title + wl-plan-rating {
          margin-top: rem-calc(12);
        }
      }
    }
  }

  &__affiliate {
    width: 100%;
    max-width: 100%;
    margin-inline: auto;
  }
}

@include media-breakpoint-up(sm) {
  .plan-hero {
    &__content {
      padding-block-start: rem-calc(24);

      .plan-header {
        margin: 0;

        &__media {
          --fv-video-playback-button-position-bottom: #{rem-calc(16)};
          --fv-video-playback-button-position-left: #{rem-calc(16)};
          --fv-video-playback-button-size: #{rem-calc(40)};

          .slideshow {
            display: none;
          }

          .desktop-gallery {
            display: grid;
          }

          .open-images-viewer {
            right: rem-calc(16);
            bottom: rem-calc(16);
          }
        }

        &__info {
          padding: rem-calc(16) 0 rem-calc(20) 0;

          &::before {
            display: none;
          }
        }
      }
    }

    &--one-photo {
      .plan-hero__curve {
        position: absolute;

        top: calc(#{$page-dimensions-padding-block-start-lg} * -1);

        display: block;

        width: 100%;

        height: calc(100% + #{$page-dimensions-padding-block-start-lg});

        overflow: hidden;

        &::before {
          position: absolute;

          top: 0;
          right: -16%;
          bottom: 0;
          left: -16%;

          display: block;

          overflow: hidden;

          background-color: $plan-header-hero-curve-background;

          border-radius: 0 0 100% 100%/0 0 32% 32%;

          content: '';
        }
      }

      .plan-hero__content {
        .plan-header {
          position: relative;

          display: flex;
          gap: rem-calc(16);
          align-items: center;
          max-width: 100%;
          margin: 0 0 rem-calc(20) 0;

          .plan-header__media {
            width: 45%;
            aspect-ratio: $plan-header-media-aspect-ratio;
            @include media-breakpoint-up(sm) {
              aspect-ratio: $plan-header-media-aspect-ratio-desktop;
            }

            .desktop-gallery {
              display: block;

              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;

                object-fit: cover;

                border-radius: $box-wrapper-dimensions-border-radius;
              }
            }
          }

          .plan-header__info {
            width: 55%;
            padding: 0;

            .venue,
            .title,
            .plan-rating {
              color: $plan-header-hero-curve-color;
            }

            .title {
              font: $font-h1;

              &__site-name {
                span {
                  color: $plan-header-hero-curve-color;
                }
              }
            }
          }
        }
      }
    }

    &:not(.plan-hero--one-photo) {
      .plan-hero__content {
        .plan-header {
          &__media {
            height: rem-calc(270);
            margin-bottom: rem-calc(16);

            transition: height 0.25s ease-in-out;
          }
        }
      }
    }

    &--two-photos {
      .desktop-gallery {
        --grid-columns: 1fr 1fr;
        --grid-rows: 100%;

        > :nth-child(1) {
          grid-column: 1 / span 1;
        }

        > :nth-child(2) {
          grid-column: 2 / span 1;
        }
      }
    }

    &--three-photos {
      .desktop-gallery {
        --grid-columns: 65% 1fr;

        > :nth-child(1) {
          grid-row: 1 / span 2;
          grid-column: 1 / span 1;
        }

        > :nth-child(2) {
          grid-row: 1 / span 1;
          grid-column: 2 / span 1;
        }

        > :nth-child(3) {
          grid-row: 2 / span 1;
          grid-column: 2 / span 1;
        }
      }
    }

    &--five-photos {
      .desktop-gallery {
        --grid-columns: 50% 1fr 1fr;

        > :nth-child(1) {
          grid-row: 1 / span 2;
          grid-column: 1 / span 1;
        }

        > :nth-child(2) {
          grid-row: 1 / span 1;
          grid-column: 2 / span 1;
        }

        > :nth-child(3) {
          grid-row: 2 / span 1;
          grid-column: 2 / span 1;
        }

        > :nth-child(4) {
          grid-row: 1 / span 1;
          grid-column: 3 / span 1;
        }

        > :nth-child(5) {
          grid-row: 2 / span 1;
          grid-column: 3 / span 1;
        }
      }
    }

    &__affiliate {
      position: relative;

      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

@include media-breakpoint-up(md) {
  .plan-hero {
    &:not(.plan-hero--one-photo) {
      .plan-hero__content {
        .plan-header {
          &__media {
            height: rem-calc(350);
            margin-bottom: rem-calc(24);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .plan-hero {
    &__content {
      padding-block-start: rem-calc(32);

      .plan-header {
        &__info {
          padding: 0 0 rem-calc(16) 0;

          .title {
            font-size: $plan-header-info-title-font-size-lg;
            line-height: $plan-header-info-title-font-line-height-lg;
          }
        }
      }
    }

    &--one-photo {
      .plan-hero__content {
        .plan-header {
          max-width: calc(67% - #{$page-wrapper-dimensions-gap-lg});
        }
      }
    }

    &:not(.plan-hero--one-photo) {
      .plan-hero__content {
        .plan-header {
          &__media {
            --fv-video-playback-button-position-bottom: #{rem-calc(20)};
            --fv-video-playback-button-position-left: #{rem-calc(20)};
            --fv-video-playback-button-size: #{rem-calc(48)};

            height: rem-calc(400);

            .open-images-viewer {
              right: rem-calc(20);
              bottom: rem-calc(20);

              .button {
                font-size: $font-size-body-small;
              }
            }
          }

          &__info {
            max-width: calc(67% - #{$page-wrapper-dimensions-gap-lg});
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .plan-hero {
    &--one-photo {
      .plan-hero__content {
        .plan-header {
          &__info {
            .title {
              font: $font-h1;
            }
          }
        }
      }
    }

    &:not(.plan-hero--one-photo) {
      .plan-hero__content {
        .plan-header {
          &__media {
            height: rem-calc(470);
            margin-bottom: rem-calc(32);
          }
        }
      }
    }
  }
}

.plan-affiliate-banner {
  @include media-breakpoint-down(md) {
    position: absolute;
  }
  z-index: calc($z-index-top + 1);

  display: block;
  width: 100%;

  &__content {
    padding: rem-calc(4) 0;

    color: $color-action-background-primary;

    background-color: $color-palette-primary-100;
  }

  &__center {
    display: flex;
    gap: rem-calc(16);
    align-items: center;
    width: 100%;
    max-width: $page-dimensions-max-width;
    min-height: 40px;
    padding: rem-calc(4) rem-calc(20);
    margin-inline: auto;

    @include media-breakpoint-down(lg) {
      max-width: $page-dimensions-max-width;
      margin-inline: auto;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  &__image,
  &__icon {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 40px;
    height: 40px;

    line-height: 1;

    border-color: $color-action-background-primary;

    border-style: solid;
    border-width: medium;
    border-radius: $border-radius-sm;
    box-shadow: $box-shadow-medium;

    @include media-breakpoint-up(lg) {
      width: 80px;
      height: 80px;
    }
  }

  &__icon {
    align-items: center;
    justify-content: center;

    background-color: $color-palette-neutral-white;
  }

  &__text {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: column;
      gap: rem-calc(8);
      align-items: baseline;
    }
  }

  &__name {
    font: $font-h5;
    @include media-breakpoint-up(lg) {
      font: $font-h2;
    }
  }

  &__description {
    font: $font-tiny-regular;
    font-size: $font-size-caption;
    @include media-breakpoint-up(lg) {
      font: $font-caption-regular;
      font-size: $font-size-body-base;
    }
  }
}
