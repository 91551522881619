.plan-rating {
  display: flex;
  align-items: baseline;

  font: $font-body-small-regular;

  fv-rating {
    color: $rating;
    margin-inline: rem-calc(6);

    fv-fa-icon {
      margin-right: rem-calc(2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .average {
    font-weight: $font-weight-bold;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-body-base-regular;
  }
}
