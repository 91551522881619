.payment-box {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-bottom: $button-dimension-height-large; // Make room for the payment button in desktop
  }

  &__message {
    padding: rem-calc(16);
  }

  &__message-badge {
    display: flex;
    gap: rem-calc(6);
    width: fit-content;
    min-height: auto;
    padding: rem-calc(8 12 8 8);

    color: $color-palette-neutral-800;

    font-size: $font-size-body-small;

    background-color: $color-palette-neutral-100;
    border-radius: $border-radius-sm;
  }

  &__message-icon {
    width: rem-calc(18);
  }

  &__email-notification {
    display: flex;
    gap: rem-calc(8);
    align-items: flex-start;
    margin-top: rem-calc(16);

    font-size: rem-calc(14);

    &__icon {
      color: $color-action-text-primary;
    }
  }
}

.payment-methods-section {
  padding: 0;

  background-color: $payment-methods-section-color-background;
  border: $border-style;

  border-radius: $wl-border-radius;

  margin-block-end: rem-calc(16);

  @include media-breakpoint-up(lg) {
    padding: $payment-methods-section-dimensions-padding-lg;
  }

  .selectable-item {
    padding: 0 rem-calc(16);

    border-radius: 0;
    margin-block-end: 0;
    border-block-end: $border-style;
  }

  &--vouchers {
    @include media-breakpoint-up(md) {
      padding: 0 rem-calc(24);
    }
  }

  &__buy {
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__new-card-container {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
    margin-bottom: rem-calc(16);

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding-block-end: rem-calc(16);
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;
      }

      button {
        width: 100%;
      }
    }
  }

  &__credit-cards {
    text-align: center;

    &--images {
      display: flex;

      img {
        max-width: rem-calc(50);
        height: rem-calc(24);
        object-fit: contain;
        padding-inline-end: rem-calc(4);
      }
    }
  }

  &__add-card {
    color: var(--primary-color);
    font-size: rem-calc(14);

    background: transparent;
    border: 0;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;

      outline: none;
    }

    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(16);
    }
  }

  &__save {
    @include button;

    margin: rem-calc(24 0);

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  &__other-methods-title {
    margin: rem-calc(32) 0;

    color: $gray-600;
    text-align: center;
  }

  &__request-container {
    &--hidden {
      display: none;
    }
  }

  &__request {
    @include flex-center;
    align-items: center;
    align-self: center;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: rem-calc(400);
    }

    @include media-breakpoint-up(lg) {
      margin: inherit;
    }
  }
}

.klarna-selectable-item {
  .selectable-item__media-container {
    background-color: $payment-methods-button-klarna-background;

    padding-inline: 0.25rem;
  }
}

.klarna-pay-button {
  background-color: $payment-methods-button-klarna-background;
  border: $payment-methods-button-klarna-border;

  &:hover {
    background-color: $payment-methods-button-klarna-background-hover;
    border: $payment-methods-button-klarna-border-hover;
  }
}

ngx-stripe-payment-request {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  text-align: center;

  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-button;

    width: 100%;

    padding: rem-calc(16) rem-calc(16) max(#{rem-calc(16)}, env(safe-area-inset-bottom));
  }
}

ngx-stripe-card-input {
  width: 100%;
}

.ngx-stripe-card-input {
  box-sizing: border-box;
  padding: rem-calc(16);

  font-size: rem-calc(14);

  background-color: $white;
  border: rem-calc(1) solid $payment-methods-new-card-border-color;
  border-radius: rem-calc(6);
}

.ngx-stripe-payment-methods {
  display: flex;
  flex-direction: column;
}

.ngx-stripe-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin: rem-calc(0 0 16);
  padding: rem-calc(8);

  background-color: $white;
  border: rem-calc(1) solid var(--gray-200);
  border-radius: $border-radius-xs;

  cursor: pointer;

  @include media-breakpoint-up(md) {
    padding: rem-calc(16);
  }

  &--selected {
    border: rem-calc(1) solid var(--primary-color);
    outline: none;

    .ngx-stripe-card__check {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border: rem-calc(1) solid var(--primary-color);
    outline: none;
  }

  &__icon {
    width: rem-calc(48);
    height: rem-calc(25);

    background-image: url('../../../assets/images/payments/default.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &--visa {
      background-image: url('../../../assets/images/payments/visa.svg');
    }

    &--mastercard {
      background-image: url('../../../assets/images/payments/mastercard.svg');
    }

    &--amex {
      background-image: url('../../../assets/images/payments/amex.svg');
    }

    @include media-breakpoint-up(lg) {
      width: rem-calc(30);
      height: rem-calc(16);
    }

    @include media-breakpoint-up(xl) {
      width: rem-calc(48);
      height: rem-calc(25);
    }
  }

  &__brand {
    text-transform: capitalize;
  }
}

.payment-block + .payment-block {
  border-top: $border-style;
}
