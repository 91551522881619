.subscription {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__navigation {
    max-width: rem-calc(480);
    padding: rem-calc(16);
  }

  &__link {
    width: 100%;
    padding: rem-calc(8);

    text-align: center;

    border: 0;
  }
}
