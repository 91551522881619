.profile-menu {
  display: flex;
  flex-direction: column;
  gap: rem-calc(8);
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    display: flex;
    flex-wrap: nowrap;
    gap: rem-calc(4);
    justify-content: center;

    color: $color-text-main;

    &:hover {
      color: $color-action-text-primary-hover;
    }
  }

  &__link {
    display: inline;
    margin: 0;
    padding: 0;

    color: $color-text-main;
    font-weight: $profile-menu-link-font-weight;
    font-family: $profile-menu-link-font-family;

    background: none;
    border: 0;
    cursor: pointer;

    &:hover {
      color: $color-action-text-primary-hover;
    }
  }
}
