.email-update {
  position: relative;

  width: $email-update-card-width;

  color: $email-update-card-color-text;

  background-color: $email-update-card-color-background;
  border: rem-calc(1) solid $color-border-main;
  border-radius: $email-update-card-border-radius;

  &__header {
    position: relative;

    padding: $email-update-card-header-padding;

    text-align: center;
    border-block-end: rem-calc(1) dashed $color-border-main;
  }

  &__title {
    color: $email-update-card-title-text-color;
    font: $font-h4;
    line-height: rem-calc(22);
    margin-block-end: 0;
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    margin: rem-calc(24 0);
  }

  &__content {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      max-width: rem-calc(530);
    }
  }
}

.email-update-form {
  &__title {
    padding: rem-calc(24 0);
  }
}

.email-update-success {
  margin: rem-calc(24 0);

  &__action-button-only {
    float: right;
    margin: rem-calc(24 0);
  }
}
