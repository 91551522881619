.bundle-header {
  display: grid;
  grid-template-columns: rem-calc(20) 1fr rem-calc(20);
  align-items: center;
  justify-items: center;
  padding: rem-calc(16);

  color: $color-text-main-contrast;

  background-color: $bundle-header-background-color;

  &__title {
    margin: 0;

    font: $font-h5;
    text-align: center;
  }

  &__navigation-icon {
    position: relative;

    color: inherit;

    background-color: inherit;
    border: 0;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;

      width: rem-calc(48);
      height: rem-calc(48);

      transform: translateX(-50%) translateY(-50%);

      content: '';
    }
  }
}
