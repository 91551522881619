.plan-venue {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  font-size: rem-calc(16);
  line-height: rem-calc(24);

  column-gap: rem-calc(32);

  &__info {
    flex: 1;
    padding: rem-calc(16 0);

    .title {
      margin-bottom: rem-calc(10);

      font-weight: $font-weight-bold;
      font-size: rem-calc(16);
    }

    .description {
      p {
        display: inline;
        margin-right: rem-calc(8);
      }

      button {
        @include regular-link;

        display: inline;

        padding: 0;

        white-space: nowrap;

        background: transparent;

        border: 0;
      }
    }
  }

  &__gallery {
    width: 100%;

    .slideshow {
      --fv-slideshow-image-border-radius: #{$box-wrapper-dimensions-border-radius};
      --fv-slideshow-image-aspect-ratio: #{$plan-venue-images-aspect-ratio};
      --fv-slideshow-dot-indicator-position: relative;
      --fv-slideshow-dot-indicator-position-bottom: 0;
      --fv-slideshow-dot-indicator-background-color: #{$color-text-subtle};
      --fv-slideshow-dot-indicator-active-background-color: #{$color-text-main};
    }
  }

  @include media-breakpoint-up(md) {
    &__info {
      @include auto;
      padding: 0;
    }

    &__gallery {
      flex: 0 0 auto;
      order: 2;
      max-width: 64%;
    }
  }
}
