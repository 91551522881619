.page-header {
  width: 100%;
  margin-inline: auto;

  &--spaced {
    margin-block: $page-title-dimensions-margin-block-sm;

    @include media-breakpoint-up(lg) {
      margin-block: $page-title-dimensions-margin-block-lg;
    }
  }

  &--center {
    text-align: center;
  }

  &__icon {
    color: $color-text-positive;
    font-size: $font-size-h1;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-display;
    }
  }

  &__title {
    font: $font-h2;

    @include media-breakpoint-up(lg) {
      font: $font-h1;
    }

    &--alt {
      font: $font-h2;
    }

    &--icon {
      display: block;
    }
  }

  &__description {
    color: $color-text-subtle;
    font: $font-body-base-regular;
  }

  &__row {
    display: flex;

    text-align: start;

    &--inline {
      gap: rem-calc(8);
      align-items: center;
    }
  }
}
