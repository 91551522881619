.current-voucher {
  display: inline-flex;
  gap: rem-calc(8);
  align-items: center;

  padding: rem-calc(4 12);

  color: $plan-banner-text-color;

  background-color: $color-palette-positive-100;
  border-radius: rem-calc(8);

  &:hover {
    cursor: pointer;
  }

  &__code {
    margin-bottom: 0;

    font-size: rem-calc(14);
    line-height: rem-calc(20);
    word-wrap: break-word;
  }

  &__icon {
    color: $color-palette-positive-700;
  }
}

.voucher-sheet-button {
  display: flex;
  gap: rem-calc(8);
  align-items: center;
  padding: rem-calc(8) 0;

  color: $voucher-color-text;
  font-weight: $font-weight-bold;

  font-family: $voucher-font-family;
  text-transform: $voucher-text-transform;

  background-color: transparent;
  border: unset;
  margin-block-end: rem-calc(16);
}
