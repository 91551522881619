.plan-general-conditions {
  margin-top: rem-calc(16);

  &__link {
    padding: 0;

    color: $color-action-text-primary;
    text-decoration: none;

    background-color: transparent;
    border: 0;
  }
}
