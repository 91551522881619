.navbar {
  display: flex;

  gap: $navbar-dimensions-gap;
  align-items: center;
  justify-content: flex-end;

  /* stylelint-disable */
  .navbar__content--collapse.navbar__content__right {
    right: 0;
    left: auto;
  }

  .navbar__content--collapse.navbar__content__left {
    right: auto;
    left: 0;
  }

  /* stylelint-enable */

  &__content {
    &__left {
      left: calc($navbar-dimensions-width * -1);
    }

    &__right {
      right: calc($navbar-dimensions-width * -1);
    }

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 0;
      right: calc($navbar-dimensions-width * -1);

      width: $navbar-dimensions-width;
      height: 100%;
      padding: rem-calc(90) rem-calc(16);

      background: $navbar-color-background-main;

      transition: all 0.3s ease 0s;

      &--collapse {
        box-shadow: $navbar-effect-box-shadow;
      }

      &___left {
        overflow-x: scroll;
        overflow-y: auto;
      }

      &__right {
        overflow-x: auto;
        overflow-y: scroll;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column-reverse;
    gap: rem-calc(16);
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;

    text-align: center;

    list-style: none;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: $navbar-dimensions-gap;
    }
  }

  &__item {
    &--desktop {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__link {
    @include main-link($navbar-action-color-text-primary, $navbar-action-color-text-primary-hover);
    font-weight: $navbar-font-weight;
    font-size: $navbar-font-size;
    font-family: $navbar-font-family;
    white-space: nowrap;
  }

  &__button,
  &__link {
    label {
      cursor: pointer;
    }

    fv-fa-icon {
      font-size: rem-calc(20);
    }
  }

  &__login {
    width: $navbar-login-dimensions-width;
    @include media-breakpoint-up(md) {
      width: auto;
    }

    &__button {
      /* stylelint-disable */
      @extend .button;
      @extend .button--primary;
      @extend .button--large;
      /* stylelint-enable */
      @include media-breakpoint-down(md) {
        border: $navbar-login-border;
      }
    }

    &__button--icon {
      fv-fa-icon {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: center;

        width: rem-calc(32);
        min-width: unset;
        height: rem-calc(32);
        margin: 0;
        padding: 0;

        background-color: $navbar-login-button-color-background;

        border: rem-calc(1) solid $navbar-login-button-color-border;
        border-radius: 50%;

        span {
          display: none;
        }

        fv-fa-icon {
          display: block;

          color: $navbar-login-icon-color;
          font-size: rem-calc(16);
        }

        &:hover {
          background-color: $navbar-login-button-color-background;

          border: rem-calc(1) solid $navbar-login-button-color-border;

          transform: none;
        }
      }
    }
  }

  &__user {
    color: $color-text-main;
    text-decoration: none;

    &:active,
    &:hover {
      color: $color-text-main;
    }
  }

  &__user-picture {
    display: block;
    width: $profile-user-avatar-dimensions-width;
    height: $profile-user-avatar-dimensions-height;
    margin-right: auto;
    margin-left: auto;
    object-fit: cover;
    overflow: hidden;

    border: $profile-user-avatar-border;
    border-radius: $profile-user-avatar-dimensions-radius;
    margin-block: 0 rem-calc(16);

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;

      width: rem-calc(32);
      height: rem-calc(32);
      margin: 0;

      border-width: rem-calc(1);
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.18) 0 0 0.375rem;
      cursor: pointer;
    }
  }

  &__user-name {
    margin-bottom: 0;

    color: $navbar-user-name-color;

    font: $profile-user-name-font;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__user-email {
    display: inline-block;

    color: $navbar-user-email-color;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__logout {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__subHeader {
    width: 100%;

    padding-top: rem-calc(16);

    border-top: rem-calc(1) solid $color-border-primary-medium;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__separator {
    margin-block-end: rem-calc(24);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__referrer {
    position: absolute;
    right: rem-calc(16);
    bottom: rem-calc(32);
    left: rem-calc(16);

    text-align: center;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__referrer-link {
    @include main-link;
  }

  &__icon-group {
    z-index: 1;

    display: flex;
    flex-flow: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__extra_sections {
    display: flex;
    gap: rem-calc(16);
    align-items: center;

    list-style: none;

    &:empty {
      display: none;
    }
  }

  &__top-banner {
    margin-bottom: rem-calc(8);

    font-size: rem-calc(14);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.nav-icon {
  @include no-style-button;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: rem-calc(4);
  align-items: center;
  justify-content: center;
  width: rem-calc(44);
  height: rem-calc(44);

  &__label {
    font-weight: bold;
    font-size: $font-size-caption;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.nav-icon::after,
.nav-icon::before,
.nav-icon div {
  display: block;
  width: rem-calc(24);
  height: rem-calc(3);

  background-color: $navbar-icon-color-foreground;
  border-radius: rem-calc(4);

  transition: all 0.2s ease-in-out;

  content: '';
}

.nav-icon--collapsed::before {
  transform: translateY(7px) rotate(135deg);
}

.nav-icon--collapsed::after {
  transform: translateY(-7px) rotate(-135deg);
}

.nav-icon--collapsed div {
  transform: scale(0);
}
