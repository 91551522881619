.fv-modal {
  &__close-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem-calc(16);
  }

  &__close {
    padding: 0;

    background-color: transparent;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__header-title {
    margin-top: rem-calc(16);
  }

  &__footer {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-top: rem-calc(32);

    @include media-breakpoint-up(md) {
      flex-flow: row;
      justify-content: flex-end;
    }
  }

  .points-number {
    font-weight: bold;
  }
}

.component-host-scrollable {
  overflow: visible !important;
}
