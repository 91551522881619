.links-list {
  display: grid;
  grid-gap: rem-calc(16);
  grid-template-columns: repeat(1, 1fr);
  padding: 0;

  list-style: none;

  @include media-breakpoint-up(lg) {
    grid-gap: $plan-list-grid-dimensions-gap-medium;
    grid-template-columns: repeat(3, 1fr);
  }

  &__link {
    position: relative;

    display: flex;
    padding: rem-calc(16 52 16 16);

    color: $color-text-main;
    font-weight: $font-weight-semi-bold;

    border: rem-calc(1) solid $color-border-main;
    border-radius: $plan-list-item-dimensions-border-radius;
    cursor: pointer;

    &::after {
      position: absolute;
      right: rem-calc(12);
      bottom: rem-calc(12);

      width: rem-calc(32);
      height: rem-calc(32);
    }

    &:hover {
      color: $color-text-main;
    }
  }
}
