.page--groups-form {
  padding-block-end: 0;
}

.groups-wrapper {
  padding: rem-calc(40) rem-calc(16);

  background-color: $groups-color-background;
}

.groups-form {
  width: 100%;
  max-width: rem-calc(720);
  margin: 0 auto;

  &__cell {
    &__prefix {
      padding-bottom: 0.5rem;

      &--error {
        margin-bottom: 1rem;
      }
    }
  }
}
