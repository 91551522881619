.page-footer {
  color: $footer-color-foreground;

  background-color: $footer-color-background;
  padding-block: $footer-dimensions-padding-block;
  border-block-start: $footer-border-top;

  @include media-breakpoint-down(lg) {
    padding-block: $footer-dimensions-padding-block-small;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: rem-calc(32);
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__link {
    color: $footer-link-color;
    font-size: $footer-link-font-size;

    &:hover {
      color: $footer-link-hover-color;
      text-decoration: underline;
    }
  }

  &--nav-sections {
    @include media-breakpoint-up(md) {
      .footer-info {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        order: 2;
      }

      .footer-copyright {
        justify-content: flex-end;
        order: 3;
      }
    }
  }

  &__banner {
    @include flex-center;
    margin-block-end: rem-calc(48);

    flex-flow: column;
    gap: rem-calc(24);
    @include media-breakpoint-up(lg) {
      flex-flow: row;
    }
  }

  &__banner-img {
    display: flex;
    max-width: 100%;
  }

  &__partners {
    margin-block-end: rem-calc(64);
  }
}

.footer-partners {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  row-gap: rem-calc(48);
  align-items: center;

  &__partner {
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 50%);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 33.3%);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 25%);
  }
}

.footer-info {
  flex-direction: column;
  gap: rem-calc(32);
  align-items: center;

  &__logo {
    max-width: $footer-logo-dimensions-max-width;
  }
}

.footer-rrss {
  &__title {
    font: $font-body-small-regular;
  }

  &__list {
    display: flex;
    gap: $footer-rrss-list-gap;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(44);
    height: rem-calc(44);

    color: $color-action-text-main;
    font-size: $font-size-h3;

    background-color: $color-action-background-main-contrast;
    border-radius: 100%;

    &:hover {
      color: $color-action-text-main-hover;

      background-color: $color-action-background-main-hover-contrast;
    }
  }
}

.footer-links-list {
  display: flex;
  gap: rem-calc(8);
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    text-align: center;
  }
}

.footer-copyright {
  justify-content: center;

  font: $font-body-small-regular;
}

.footer-nav-sections {
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-gap: var(--footer-nav-grid-columns-gap, rem-calc(48));
    grid-template-columns: repeat(var(--footer-nav-grid-columns, 5), var(--footer-nav-columns-width, 1fr));
    order: 1;
  }
}

.footer-nav-details {
  summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem-calc(8) 0;

    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      padding: rem-calc(8) 0 $footer-nav-details-summary-padding-bottom;

      pointer-events: none;
      touch-action: none;
      padding-inline: 0;

      .footer-nav-details__title--icon {
        content: none;
      }
    }
  }

  &__title {
    flex: 1;
    margin: 0;
    padding-top: var(--footer-nav-title-padding-top, inherit);
    padding-bottom: var(--footer-nav-title-padding-bottom, inherit);

    color: $footer-nav-section-color;
    font-weight: var(--footer-nav-title-font-weight, $font-weight-regular);
    font-size: var(--footer-nav-title-font-size, $font-size-body-base/$font-line-height-body-base);

    font-family: var(--footer-nav-title-font-family, $font-stack);
    text-transform: var(--footer-nav-title-text-transform, uppercase);

    border-bottom: var(--footer-nav-title-border-bottom, none);

    @include media-breakpoint-up(md) {
      display: inline-block;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__title-icon {
    color: $footer-navigation-summary-color;
    font-size: $footer-navigation-summary-font-size;

    &--opened {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &[open] {
    @include media-breakpoint-down(lg) {
      .footer-nav-details__title-icon--opened {
        display: inherit;
      }

      .footer-nav-details__title-icon--closed {
        display: none;
      }
    }
  }

  &__list {
    padding: 0;

    font: $font-body-small-regular;

    list-style: none;
  }

  &__item {
    margin-bottom: var(--footer-nav-list-item-margin-bottom, inherit);
  }

  @include media-breakpoint-up(md) {
    &[open],
    &[close] {
      display: block;
    }
  }
}
