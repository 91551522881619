.bundle-plan-selector-header {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: rem-calc(16);
  align-items: self-start;
  padding: rem-calc(12) rem-calc(16);
  overflow: hidden;

  font: $font-body-base-regular;

  background: $color-background-subtle-weak;
  opacity: 0.8;

  &__numeration {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(24);
    height: rem-calc(24);

    font: $font-body-small-bold;

    border: rem-calc(1) solid $color-border-main-contrast;
    border-radius: 50%;

    &--checked {
      color: $color-text-main-contrast;

      background-color: $color-background-main-contrast;
      border: $color-background-main-contrast;
    }

    &--connecting-line-up::before {
      position: absolute;
      top: 0;

      display: block;
      height: rem-calc(12);

      border-right: 1px dashed $color-border-main-contrast;

      content: '';
    }

    &--connecting-line-down::after {
      position: absolute;
      top: rem-calc(36);

      display: block;
      height: calc(100% - #{rem-calc(36)});

      border-right: 1px dashed $color-border-main-contrast;

      content: '';
    }
  }

  &__selection-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: rem-calc(8);

    &__plan-name {
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-weight: $font-weight-bold;
    }

    &__selected-items {
      display: flex;
      flex-direction: column;
      gap: rem-calc(4);

      &__details-summary {
        list-style: none;
        cursor: pointer;

        &__icon {
          display: inline-block;
        }

        &__icon--rotated {
          display: inline-block;

          transform: rotate(180deg);
        }
      }

      &__details-items {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        margin-top: rem-calc(4);

        font: $font-body-small-regular;
        column-gap: rem-calc(12);

        &__count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem-calc(24);
          height: rem-calc(24);

          text-align: center;

          background-color: $color-border-main;
          border-radius: rem-calc(4);
        }

        &__label {
          padding: rem-calc(8) 0;
        }
      }
    }
  }

  &__edit-button {
    font: $font-body-small-bold;
    text-decoration: underline;

    background: transparent;
    border: transparent;
  }
}
