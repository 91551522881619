.copyright-and-social {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  padding-top: rem-calc(32);

  border-top: $footer-copyright-and-social-border-top;

  &__copyright {
    display: flex;
    flex-direction: column;

    color: $footer-copyright-color;
    font-size: rem-calc(12);

    @include media-breakpoint-up(lg) {
      max-width: rem-calc(500);
    }
  }

  &__social {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin: initial;
    }
  }

  &__legals {
    margin-block-start: rem-calc(8);

    &__link {
      margin-inline-end: rem-calc(8);

      color: $footer-copyright-color;

      &:hover {
        color: $footer-copyright-color;
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
