.reschedule-confirmation-sheet {
  .confirmation-sheet__content {
    &__text {
      text-align: center;
    }
  }

  .confirmation-sheet__footer {
    flex-direction: column;

    //INVERT STYLES
    .button {
      &--secondary {
        color: $button-color-foreground-primary;

        background-color: $button-color-background-primary;
        border: $button-border-primary;

        &:hover {
          color: $button-color-foreground-primary-hover;

          background-color: $button-color-background-primary-hover;
          border: $button-border-primary-hover;
        }
      }

      &--primary {
        color: $button-color-foreground-secondary;

        background-color: $button-color-background-secondary;
        border: $button-border-secondary;

        &:hover {
          color: $button-color-foreground-secondary-hover;

          background-color: $button-color-background-secondary-hover;
          border: $button-border-secondary-hover;
        }
      }
    }
  }
}
