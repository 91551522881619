.profile-user-info {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  align-items: center;

  &__info {
    display: flex;
    flex-direction: column;

    text-align: center;
  }

  &__name {
    font: $profile-user-name-font;
  }

  &__email,
  &__phoneNumber {
    color: $color-text-subtle;
  }

  &__avatar {
    display: block;
    width: $profile-user-avatar-dimensions-width;
    height: $profile-user-avatar-dimensions-height;
    margin: 0;
    object-fit: cover;
    overflow: hidden;

    border: $profile-user-avatar-border;
    border-radius: $profile-user-avatar-dimensions-radius;
  }
}
