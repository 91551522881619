.spinner {
  @include flex-center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-top-app;

  width: 100%;
  height: 100%;

  background-color: rgba($gray-500, 20%);
}

.loader {
  display: block;
  width: rem-calc(80);
  height: rem-calc(80);

  border: rem-calc(3) solid transparent;
  border-top-color: var(--primary-color);
  border-radius: 50%;

  animation: spin 1.4s linear infinite;

  &::before {
    position: absolute;
    top: rem-calc(5);
    right: rem-calc(5);
    bottom: rem-calc(5);
    left: rem-calc(5);

    border: rem-calc(3) solid transparent;
    border-top-color: $white;
    border-radius: 50%;

    animation: spin 3s linear infinite;

    content: '';
  }

  &::after {
    position: absolute;
    top: rem-calc(15);
    right: rem-calc(15);
    bottom: rem-calc(15);
    left: rem-calc(15);

    border: rem-calc(3) solid transparent;
    border-top-color: $black;
    border-radius: 50%;

    animation: spin 1.5s linear infinite;

    content: '';
  }
}

.btn {
  &--only-text {
    margin: 0;
    padding: 0;

    color: var(--primary-color);
    font-weight: bold;

    background: none;

    border: 0;

    &:focus {
      outline: 0;
    }
  }
}

.info-banner-section {
  @include flex-center;
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;
  flex-flow: column;
  padding-inline: rem-calc(16);
  margin-block-end: rem-calc(48);

  background: $info-banner-section-color-background;
}

.info-banner {
  display: flex;
  flex-flow: column;
  gap: rem-calc(8);
  max-width: rem-calc(760);
  margin: auto;
  padding: rem-calc(24);

  color: $info-banner-color-foreground;

  background-color: $info-banner-color-background;
  border-radius: $info-banner-dimensions-border-radius;
  @include media-breakpoint-up(md) {
    flex-flow: row;
    gap: rem-calc(24);
  }

  &__info {
    text-align: start;
  }

  &__header {
    font-size: $font-size-h3;
    line-height: $font-line-height-h3;
    @include media-breakpoint-up(md) {
      font-size: $font-size-h2;
      line-height: $font-line-height-h2;
    }
  }

  &__cta {
    @include media-breakpoint-up(md) {
      width: fit-content;
    }
  }

  &__next-title {
    font: $font-h4;
    margin-block: rem-calc(24);
  }
}

.page {
  @include page-dimensions-padding-block-start;
  @include page-dimensions-padding-block-end;

  background-color: $page-background-color;

  &__webview {
    padding-block-start: 0;
  }

  &__noHeader {
    padding-block-start: 0;
  }

  &__subHeader {
    @include media-breakpoint-up(lg) {
      padding-block-start: $header-height-with-subheader;
    }
  }

  &__container {
    width: 100%;
    max-width: $page-dimensions-max-width;
    padding-inline: 1rem;
    margin-inline: auto;
  }

  &__container-no-padding {
    width: 100%;
    max-width: $page-dimensions-max-width;
    margin-inline: auto;
    @include media-breakpoint-up(lg) {
      display: block;
      padding-inline: 1rem;
    }
  }

  &__container-padding {
    display: block;
    width: 100%;
    padding-inline: 1rem;
    max-width: $page-dimensions-max-width;
    margin-inline: auto;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $page-wrapper-dimensions-gap-sm;
    width: 100%;

    &--reverse-lg {
      @include media-breakpoint-up(lg) {
        flex-flow: row-reverse;
      }
    }

    @include media-breakpoint-up(lg) {
      gap: $page-wrapper-dimensions-gap-lg;
    }
  }

  &__aside {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 33%;
    }
  }

  &__main {
    flex: 1 1 0;
    width: 100%;
  }
}

.box-wrapper {
  position: relative;

  width: 100%;
  padding: $box-wrapper-dimensions-padding-small;

  background: $color-background-main;
  border: $box-wrapper-border;
  border-radius: $box-wrapper-dimensions-border-radius;

  @include media-breakpoint-up(lg) {
    padding: $box-wrapper-dimensions--padding-large;
  }
}

.fixed-cta {
  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-button;

    padding: rem-calc(16) rem-calc(16) max(#{rem-calc(16)}, env(safe-area-inset-bottom));

    text-align: center;

    background: $fixed-cta-gradient-background;

    .button {
      width: 100%;
    }

    &__kiosko {
      display: flex;
      gap: rem-calc(32);
      align-items: flex-start;
      justify-content: center;

      background-color: $white;
      box-shadow: $box-shadow-medium;

      .purchase-button {
        &__back {
          width: 30%;
        }

        &__buy {
          width: 70%;
        }
      }
    }
  }

  .paypal-button,
  .venmo-button {
    display: flex;

    height: $button-dimension-height-large;
    padding: 0;
    overflow: hidden;

    background-color: unset;
    border: 0;
    border-radius: $button-dimension-radius;

    appearance: none;

    &:hover {
      background-color: unset;
    }

    div {
      width: 100%;
      padding: 0;
    }
  }

  .paypal-button,
  .paypal-button:hover {
    background-color: #0070ba;
  }

  .fixed-cta .paypal-button:hover {
    filter: brightness(0.95);
  }
}

.link {
  @include regular-link;

  &--standalone {
    display: inline-flex;
    gap: rem-calc(4);

    text-decoration: underline;
  }

  &--sharp {
    color: $color-action-text-main;

    &:hover {
      color: $color-action-text-main-hover;
    }
  }
}
