.bundle-total {
  margin-bottom: rem-calc(16);
  padding: rem-calc(12) rem-calc(16);

  background-color: $color-background-subtle-weak;

  &__border {
    border-top: rem-calc(1) solid $color-border-main;

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: rem-calc(16) rem-calc(16) 0;

      color: $color-text-main;
      font: $font-body-base-bold;
    }
  }
}
