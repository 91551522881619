.countdown {
  display: flex;
  gap: rem-calc(8);
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: rem-calc(10 12);

  color: $color-palette-warning-800;
  font: $font-body-small-regular;

  background-color: $color-palette-warning-100;

  &--alert {
    color: $color-palette-danger-700;

    background: $color-palette-danger-100;
  }

  @include media-breakpoint-up(lg) {
    padding: rem-calc(12 16);

    font: $font-body-base-regular;
  }
}
