@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-flow: column;
}

@mixin auto {
  flex: 1 1 0;
  width: auto;
  min-width: 0;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-col-ready-custom($gutter: $grid-gutter-width) {
  @include make-col-ready();
  position: relative;
}

@mixin button($width: 100%, $max-width: rem-calc(400), $height: rem-calc(48)) {
  @include flex-center;
  align-items: center;
  align-self: center;
  width: $width;
  height: $height;
  margin: 0 auto;
  padding: 0 rem-calc(16);

  color: $button-color-foreground-primary;
  font-weight: 600;
  font-size: rem-calc(16);

  background-color: $button-color-background-primary;
  border: 0;
  border-radius: rem-calc(45);
  outline: none;
  cursor: pointer;

  transition: all 0.3s ease 0s;

  @include media-breakpoint-up(md) {
    max-width: $max-width;
  }

  &:hover {
    color: $white;

    background-color: var(--primary-color-dark);
    box-shadow: 0 rem-calc(15) rem-calc(20) rgba(var(--primary-color-dark), 0.3);
    transform: translateY(rem-calc(-1));
  }

  &:disabled,
  &:disabled &:hover {
    color: $gray-600;

    background-color: $disabled;
    box-shadow: none;
    transform: none;
    cursor: initial;
  }
}

@mixin button--banner {
  margin: 0;

  color: $secondary-color !important;

  background-color: $white;

  &:hover {
    background-color: $secondary-color--dark;
    box-shadow: 0 rem-calc(15) rem-calc(20) rgba(darken($secondary-color, 20%), 0.3);
  }
}

@mixin cta-container {
  @include flex-center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  padding: rem-calc(16);

  background: linear-gradient(to top, rgba($white, 0.6), 90%, rgba($white, 0));
  @include media-breakpoint-up(lg) {
    position: relative;

    padding: 0;
  }
}

@mixin main-link($color: $color-action-text-primary, $active: $color-action-text-primary-hover) {
  position: relative;

  color: $color;
  text-decoration: $main-link-decoration;

  cursor: pointer;

  &:hover,
  &.active {
    color: $active;

    &::before {
      transform: scaleX(1);
      visibility: visible;
    }
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: rem-calc(1);

    background-color: $color;
    transform: scaleX(0);
    visibility: hidden;

    transition: all ease-in-out 0.3s;

    content: '';
  }
}

@mixin regular-link($color: $color-action-text-primary, $color-active: $color-action-text-primary-hover, $font-weight: $plan-detail-link-font-weight) {
  color: $color;
  font-weight: $font-weight;
  text-decoration: underline;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $color-active;
    text-decoration: underline;
  }
}

@mixin highlight {
  padding: rem-calc(4 8);

  font-weight: bold;

  background-color: $highlight;
  border-radius: rem-calc(4);
}

@mixin primary-bold {
  color: $color-action-text-primary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin page-title {
  padding-bottom: rem-calc(16);

  color: $gray-900;
  font-weight: bold;
  font-size: rem-calc(24);
  line-height: 1.3;

  @include media-breakpoint-up(lg) {
    font-size: rem-calc(32);
    letter-spacing: rem-calc(-1);
  }
}

@mixin large-title {
  color: $gray-900;
  font-weight: bold;
  font-size: rem-calc(20);
  line-height: 1.3;

  @include media-breakpoint-up(lg) {
    font-size: rem-calc(24);
    line-height: 1.2;
  }
}

@mixin caption {
  font-size: rem-calc(12);
  line-height: rem-calc(18);
}

@mixin label {
  font-size: rem-calc(12);
  line-height: rem-calc(12);
  letter-spacing: rem-calc(1);
  text-transform: uppercase;
}

@mixin shrink {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}

@mixin custom-scrollbar($color: $gray-500) {
  ::-webkit-scrollbar {
    height: rem-calc(8);

    @include media-breakpoint-down(md) {
      height: rem-calc(6);
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color;
    border: rem-calc(2) solid transparent;
    border-radius: rem-calc(10);
  }
}

@mixin dotted-line-container {
  position: absolute;
  top: rem-calc(16);
  left: 0;

  width: 100%;

  @include media-breakpoint-up(lg) {
    top: 0;
    left: rem-calc(18);

    height: 100%;
  }
}

@mixin dotted-line {
  position: relative;

  height: 100%;

  border-top: rem-calc(4) dotted $gray-300;

  @include media-breakpoint-up(lg) {
    border-top: 0;
    border-left: rem-calc(4) dotted $gray-300;
  }
}

// Typography mixins
@mixin expressive-heading {
  color: var(--dark-gray);
  font-weight: 700;
}

@mixin expressive-heading-04 {
  @include expressive-heading;
  font-size: rem-calc(20);
  line-height: rem-calc(24);
  @include media-breakpoint-up(md) {
    font-size: rem-calc(32);
    line-height: rem-calc(36);
  }
}

@mixin expressive-heading-06 {
  @include expressive-heading;
  font-size: rem-calc(14);
  line-height: rem-calc(16);
  @include media-breakpoint-up(md) {
    font-size: rem-calc(18);
    line-height: rem-calc(24);
  }
}

@mixin expressive-heading-05 {
  @include expressive-heading;
  font-size: rem-calc(24);
  line-height: rem-calc(36);
}

@mixin ui-label-01 {
  font-weight: 500;
  font-size: rem-calc(12);
  font-family: $font-stack;
  line-height: rem-calc(16);
  letter-spacing: rem-calc(1);
  text-transform: uppercase;
}

@mixin no-style-button {
  padding: 0;

  background-color: transparent;
  border: 0;
}

@mixin rtl($component-encapsulation-disabled: false) {
  @if $component-encapsulation-disabled {
    @at-root [dir='rtl'] #{&} {
      @content;
    }
  } @else {
    @at-root :host-context([dir='rtl']) #{&} {
      @content;
    }
  }
}

@mixin page-dimensions-padding-block-start {
  padding-block-start: $page-dimensions-padding-block-start-sm;
  @include media-breakpoint-up(md) {
    padding-block-start: $page-dimensions-padding-block-start-lg;
  }
}

@mixin page-dimensions-padding-block-end {
  padding-block-end: $page-dimensions-padding-block-end-sm;
  @include media-breakpoint-up(md) {
    padding-block-end: $page-dimensions-padding-block-end-lg;
  }
}

@mixin page-section-dimensions-padding-block-start {
  padding-block-start: $page-section-dimensions-padding-block-start-sm;
  @include media-breakpoint-up(md) {
    padding-block-start: $page-section-dimensions-padding-block-start-lg;
  }
}

@mixin page-section-dimensions-padding-block-end {
  padding-block-end: $page-section-dimensions-padding-block-end-sm;
  @include media-breakpoint-up(md) {
    padding-block-end: $page-section-dimensions-padding-block-end-lg;
  }
}

@mixin skeleton($first_gradient_color, $second_gradient_color) {
  background: linear-gradient(90deg, $first_gradient_color 25%, $second_gradient_color 50%, $first_gradient_color 75%);
  background-size: 400% 100%;

  animation: skeleton-loading 4s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200%;
  }

  100% {
    background-position: -200%;
  }
}

@mixin order-detail-help {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);

  width: 100%;

  margin: 0;
  padding: $oder-card-details-help-dimensions-padding;

  font: $font-body-small-regular;

  list-style: none;
  background-color: $oder-card-details-help-color-background;
  border-radius: $border-radius-sm;

  &__item {
    display: flex;
    flex-wrap: nowrap;
    gap: rem-calc(4);
    align-items: flex-start;
  }

  a {
    @include regular-link;
  }
}

@keyframes opacity-transition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
