.section-login {
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;
  display: flex;

  &__container {
    display: flex;
    flex-flow: column;
    gap: rem-calc(24);
    width: 100%;
    max-width: $login-dimensions-max-width;
    margin: 0 auto;
    padding: $login-dimensions-padding-sm;

    background: $login-background-color;
    border: $login-border;
    border-radius: $login-border-radius;
    box-shadow: $login-box-shadow;
    @include media-breakpoint-up(sm) {
      padding: $login-dimensions-padding-lg;
    }
  }

  &__page-header {
    @include media-breakpoint-up(sm) {
      text-align: center;
    }

    display: $login-page-header-display;

    .page-header__title {
      margin-block-end: rem-calc(4);
    }

    .page-header__description {
      margin-block-end: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    min-height: 0;
  }

  &__notice {
    margin: 0;

    color: $color-text-subtle;
    font: $font-body-small-regular;
  }

  &--embedded {
    padding-block: 0;

    .page__container {
      padding: 0;
    }

    .section-login__container {
      margin: 0;
      padding: 0;

      border: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .button-otp-login {
      margin-top: rem-calc(16);
    }
  }

  .text-field {
    fv-text-field-validation {
      display: block;
    }

    &__helper {
      display: block;
      width: 100%;
      margin-top: rem-calc(8);

      font: $font-caption-regular;
      text-align: left;
    }
  }
}

.login-methods-list {
  display: flex;
  flex-flow: column;
  gap: rem-calc(16);

  @include media-breakpoint-up(md) {
    padding: $login-methods-list-padding;

    border: $border-style;
    border-radius: $wl-border-radius;
  }
}

.login-methods-divider {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  font: $font-body-base-regular;
  text-align: center;

  &::before {
    flex: 1;
    margin-right: rem-calc(8);

    border-bottom: rem-calc(1) solid $color-border-main;

    content: '';
  }

  &::after {
    flex: 1;
    margin-left: rem-calc(8);

    border-bottom: rem-calc(1) solid $color-border-main;

    content: '';
  }
}
