.wl-ticket-item {
  display: flex;
  flex-direction: column;
  gap: rem-calc(4);
  width: 100%;

  &__main-info {
    display: flex;
    flex-direction: row;
    gap: rem-calc(25);
    align-content: center;
    justify-content: space-between;

    &--concept {
      &.row-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &.column-layout {
        display: flex;
        flex-direction: column;
      }

      &-label {
        &-icon {
          flex: 0 0 auto;
        }
      }

      &-transferred {
        width: fit-content;

        &-badge {
          margin: 0;
          padding: rem-calc(2 4);

          color: $color-palette-neutral-white;

          font-size: $font-size-caption;

          background-color: $color-background-positive-base;
          border-radius: $border-radius-xs;
        }
      }
    }

    &--subtotal {
      &-price {
        display: flex;
        grid-auto-flow: column;
        grid-gap: rem-calc(8);
        grid-template-columns: $order-card-item-price-columns;
        gap: rem-calc(8);
        justify-content: flex-start;

        &-strikethrough-price {
          color: $order-card-item-price-strikethrough-color;
          font-size: $order-card-item-price-strikethrough-font-size;
          text-decoration: line-through;
        }

        strong {
          color: $order-card-item-price-final-color;
          font-weight: $order-card-item-price-final-font-weight;
          font-size: $order-card-item-price-final-font-size;
        }
      }
    }
  }

  &__instructions {
    display: flex;
    flex-direction: row;

    color: $color-text-subtle;
    font: $font-body-small-regular;
    padding-inline-start: rem-calc(24);

    &-content {
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      /* stylelint-enable */

      overflow: hidden;

      white-space: normal;

      &.collapsed {
        transition: all 1s ease-in-out;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        /* this hides non first element to fix iOS issue on specific version.
        Similar issue on this public PR: https://github.com/calcom/cal.com/pull/11385 */
        > span > *:not(:first-child) {
          display: none;
        }
      }

      &.expanded {
        overflow: visible;
        -webkit-line-clamp: unset;
        line-clamp: unset;
      }

      .label {
        margin-right: 0.5rem;

        font-weight: bold;
      }
    }

    &-expand-link {
      display: inline-block;
      width: auto;
      margin-left: auto;
      padding: 0;

      color: $color-text-subtle;
      font-weight: bold;
      white-space: nowrap;
      text-decoration: underline;

      cursor: pointer;

      &:hover {
        color: $color-text-main;
      }
    }
  }
}
