:root {
  --fv-text-field-invalid: #{$color-text-danger};

  --fv-text-field-background-color: #{$white};
  --fv-text-field-label-color: #{$color-text-subtle};
  --fv-text-field-border-color: #{$color-border-main};
  --fv-text-field-border-radius: #{$input-border-radius};
  --fv-text-field-placeholder-color: #{$color-text-subtle};

  --fv-text-field-focus-box-shadow: none;
  --fv-text-field-focus-border: 2px solid #{$input-focus-visible-border-color};
}

.text-field {
  &__input {
    input:focus-visible {
      outline: none;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill {
      /* stylelint-disable */
      background-clip: content-box !important;

      -webkit-text-fill-color: inherit !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;

      transition: background-color 5000s ease-in-out 0s !important;
      /* stylelint-enable */
    }
  }
}
