.plan-detail {
  a {
    @include regular-link;
  }

  &__section-title {
    width: 100%;

    font: $font-h3;
    margin-block-end: rem-calc(16);
    @include media-breakpoint-up(lg) {
      font: $font-h2;
    }

    &--hide {
      display: none;

      visibility: hidden;

      @include media-breakpoint-up(lg) {
        display: block;

        visibility: inherit;
      }
    }

    .icon {
      margin-inline-end: rem-calc(4);
    }
  }

  &__cta-container {
    @include cta-container;
  }
}

.plan-place {
  width: 100%;

  &--desktop {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &--mobile {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.plan-location {
  width: 100%;

  &__title-icon {
    margin-inline-end: rem-calc(4);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);

    .plan-location {
      &__map {
        display: flex;
        flex-direction: column;

        .gmap-content-map {
          min-width: 64%;
          min-height: rem-calc(256);

          border-radius: $box-wrapper-dimensions-border-radius;
        }

        img {
          width: 100%;

          border-radius: $box-wrapper-dimensions-border-radius;
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: rem-calc(32);

      .plan-location {
        &__info {
          flex: 1 1 0;
        }

        &__map {
          flex: 0 0 64%;
        }
      }
    }
  }

  &__venue {
    font: $font-body-base-bold;
  }

  &__address {
    color: $plan-detail-address-text-color;
    font: $font-body-base-regular;
    margin-block-end: rem-calc(4);
  }

  a.plan-location__address {
    display: block;

    color: $plan-detail-address-text-color;
    text-decoration: underline;
  }

  &__city {
    color: $plan-detail-address-text-color;
    font: $font-body-base-regular;
  }

  &__transport-list {
    margin: 0;
    padding-left: 0;

    list-style: none;
  }

  &__item {
    margin-bottom: rem-calc(16);

    span {
      display: inline-block;
      max-width: rem-calc(24);
      margin-right: rem-calc(4);
    }

    img {
      max-width: 100%;

      vertical-align: middle;

      border-style: none;
    }
  }
}

.ticket-selector {
  top: $ticket-selector-top-sm;

  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding: 0 rem-calc(16);
  overflow: hidden;

  &-wrapper {
    background-color: $ticket-selector-color-background;
    border-radius: $box-wrapper-dimensions-border-radius;
  }

  @include media-breakpoint-up(lg) {
    top: $ticket-selector-top-lg;

    width: auto;
    margin: 0;
    padding: 0;
    overflow: visible;

    &-wrapper {
      border: $ticket-selector-border-lg;
      box-shadow: $ticket-selector-box-shadow;
    }

    &--sticky {
      position: sticky;
    }
  }

  &__title {
    padding: rem-calc(16 16 16) 0;

    color: $ticket-selector-header-color-foreground;

    background-color: $ticket-selector-header-color-background;
    border-top-left-radius: $box-wrapper-dimensions-border-radius;
    border-top-right-radius: $box-wrapper-dimensions-border-radius;
    margin-block-end: 0;
    border-block-end: $ticket-selector-header-border-bottom;

    @include media-breakpoint-up(lg) {
      padding: rem-calc(16);

      font-size: $ticket-selector-header-font-size-lg;
      line-height: $ticket-selector-header-line-height-lg;
    }

    .icon {
      display: $ticket-selector-header-icon-display;
    }
  }

  &__content {
    padding: 0;

    @include media-breakpoint-only(md) {
      margin: rem-calc(32) auto;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 rem-calc(16 16);

      .fixed-cta {
        margin-top: rem-calc(16);
      }
    }
  }
}

.ticket-selector-scroll-hint {
  position: sticky;
  top: $ticket-selector-top-lg;

  padding: rem-calc(16);

  color: $ticket-selector-color-foreground;

  font-weight: $font-weight-semi-bold;
  text-align: center;

  background: $ticket-selector-color-background;

  border: $ticket-selector-border-lg;
  border-radius: $box-wrapper-dimensions-border-radius;
  box-shadow: $ticket-selector-box-shadow;

  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &--visible {
    opacity: 1;

    transition: opacity 0.4s ease-in-out;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.ended-image {
  max-width: rem-calc(88);
  margin-bottom: rem-calc(16);
  object-fit: contain;

  @include media-breakpoint-up(md) {
    max-width: rem-calc(128);
    margin: 0 rem-calc(16);
  }
}

// From external libs
.plan {
  &__ticket-selector-fetching {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: rem-calc(128);

    color: var(--primary-color);
  }
}
