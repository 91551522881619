.section-status {
  @include page-section-dimensions-padding-block-start;
  @include page-section-dimensions-padding-block-end;
  padding-inline: 0;

  text-align: center;

  fv-fa-icon {
    color: $color-text-positive;
    font-size: rem-calc(120px);
  }
}
