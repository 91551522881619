.order-qr {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      justify-content: space-around;
    }

    &--single {
      justify-content: center;
    }
  }

  &__indicators {
    padding: rem-calc(12);

    .indicators {
      &__list {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;

        text-decoration: none;
      }

      &__item {
        width: rem-calc(8);
        height: rem-calc(8);
        margin: 0 rem-calc(4);

        font-size: 0;

        background-color: $color-palette-neutral-200;
        border-radius: rem-calc(20);

        transition: width 100ms ease-in-out;

        &--active {
          width: rem-calc(16);

          background-color: $button-color-foreground-tertiary;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: rem-calc(2);
    align-items: center;
    padding-bottom: rem-calc(16);

    .button {
      min-width: rem-calc(220);
      padding: rem-calc(16);

      fv-fa-icon {
        margin-right: rem-calc(6);
      }
    }
  }
}

.order-qr-arrow {
  display: flex;
  justify-content: center;
  width: rem-calc(32);

  font-size: rem-calc(32);
  text-align: center;

  background: none;
  border: 0;

  &--disabled {
    color: #8e8e8e;

    pointer-events: none;
  }
}

.order-qr-list {
  width: calc($ticket-qr-card-width + 16px);
  margin: 0;
  padding: 0;
  overflow: hidden;

  list-style: none;

  &__container {
    display: flex;
    flex-direction: row;

    transition: transform 0.2s ease;
  }

  &__item {
    flex: 100%;
    margin-inline: 0.5rem;

    max-width: calc(100% - 1rem);
  }

  &__item-label {
    text-align: center;
    padding-block-end: rem-calc(12);
  }
}

.order-qr-card {
  position: relative;

  width: $ticket-qr-card-width;
  max-width: 100%;

  color: $ticket-qr-card-color-text;

  background-color: $ticket-qr-card-color-background;
  border: rem-calc(1) solid $color-border-main;
  border-radius: $ticket-qr-card-border-radius;

  &__header {
    position: relative;

    padding: $ticket-qr-card-header-padding;

    text-align: center;
    border-block-end: rem-calc(1) dashed $color-border-main;

    &::before,
    &::after {
      position: absolute;
      bottom: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(0.5)});

      width: calc(#{$ticket-qr-card-insets-radius} * 2);
      height: calc(#{$ticket-qr-card-insets-radius} * 2);

      background-color: $sheet-color-background;
      border: rem-calc(1) solid $color-border-main;
      border-bottom-color: $ticket-qr-card-color-background;
      border-left-color: $ticket-qr-card-color-background;
      border-radius: rem-calc(64);

      content: '';
    }

    &::before {
      left: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(1)});

      transform: rotate(45deg);
    }

    &::after {
      right: calc(-1 * #{$ticket-qr-card-insets-radius} - #{rem-calc(1)});

      transform: rotate(-135deg);
    }
  }

  &__title {
    color: $ticket-qr-card-title-text-color;
    font: $font-h4;
    line-height: rem-calc(22);
    margin-block-end: 0;
  }

  &__figure {
    margin: $ticket-qr-card-figure-margin;

    text-align: center;
  }

  &__img {
    width: 100%;
  }

  &__figcaption {
    font: $font-body-small-regular;
  }

  &__print-button {
    position: absolute;
    top: rem-calc(4);
    right: rem-calc(4);

    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(44);
    height: rem-calc(44);

    color: $color-action-text-primary;

    cursor: pointer;

    fv-fa-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(32);
      height: rem-calc(32);

      border: rem-calc(1) solid $color-action-border-primary;
      border-radius: rem-calc(64);
    }

    &:hover {
      color: $color-action-text-primary-hover;

      fv-fa-icon {
        border-color: $color-action-border-primary-hover;
      }
    }
  }
}
