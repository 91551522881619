.highlighted-plans {
  z-index: $z-index-med;

  width: 100%;

  &__image {
    img {
      width: 100%;
      @include media-breakpoint-up(md) {
        border-radius: rem-calc(8);
      }
    }
  }

  .carousel-indicators {
    display: none;
    margin-bottom: rem-calc(2);
    @include media-breakpoint-up(md) {
      display: flex;

      border-radius: rem-calc(8);
    }
  }
}
