:root {
  //sheet-header
  --fv-sheet-header-color: #{$sheet-color-header-title};
  --fv-sheet-header-padding: #{rem-calc(12) rem-calc(16)};
  --fv-sheet-header-border-bottom: 1px solid #{$sheet-header-border-color};
  --fv-sheet-header-text-align: center;
  --fv-sheet-header-font-weight: #{$font-weight-bold};
  --fv-sheet-header-button-font-size: 1rem;
  --fv-sheet-header-button-hover-color: #{$color-text-subtle};

  //sheet-footer
  --fv-sheet-footer-border-top: solid 1px #{$sheet-header-border-color};

  //sheet-content
  --fv-sheet-content-padding: #{rem-calc(16)};

  //sheet
  --fv-sheet-z-index: #{$z-index-top-app};
  --fv-sheet-container-background-color: #{$sheet-color-background};
  --fv-sheet-container-max-height: #{calc(100vh - $header-height-small)};
  --fv-sheet-container-max-height-md-up: #{calc(100vh - $header-height-large)};
  --fv-sheet-max-width-md-up: #{rem-calc(530)};
  --fv-sheet-container-border-radius: #{$sheet-container-border-radius};
  --fv-sheet-container-border-radius-md-up: #{$sheet-container-border-radius};
  --fv-sheet-container-box-shadow: #{$modal-content-box-shadow};
  --fv-sheet-container-padding: 0;
  --fv-sheet-backdrop-background-color: #{$sheet-backdrop-color};
  --fv-sheet-bar-background-color: #{$sheet-color-bar};
}

.sheet-open {
  overflow: hidden;

  color: $color-palette-neutral-black;
}
